import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {
  profileFetchPersonSummary,
  fetchExpiring,
} from '@actions/profile.actions';
import {getPassedCompetencesGroupedByType} from '@selectors/profile.selectors';

import {routerMyEducationMainViewDidMount} from '@actions/router.actions';

import '../styles/my-education-container.scss';
import {messagesGetMessages} from '@actions/messages.actions';

import {ProfileBaseInformation, Trail} from '@routes/my-education';
import NewsList from '@components/news-list/news-list';
import {getConfigObject} from '@selectors/config.selectors';

class MyEducationContainer extends Component {
  componentDidMount() {
    const {
      routerMyEducationMainViewDidMount,
      profileFetchPersonSummary,
      fetchExpiring,
      messagesGetMessages,
    } = this.props;


    routerMyEducationMainViewDidMount();
    profileFetchPersonSummary();
    fetchExpiring();
    messagesGetMessages();

  }

  render() {
    const {config} = this.props;
    const newsConfig = config.getProperty('routes.my-education.widgets.news');
    return (
      <div className="my-education">
        <div className="my-education__section">
          {newsConfig && newsConfig['default-cms'] && (
            <NewsList list={newsConfig['default-cms']}/>
          )}
          <ProfileBaseInformation />
        </div>
      </div>
    );
  }
}

MyEducationContainer.propTypes = {
  passedCompetencesGroupedByType: PropTypes.shape({}).isRequired,
  requiredCompetences: PropTypes.shape({}).isRequired,
  routerMyEducationMainViewDidMount: PropTypes.func.isRequired,
  profileFetchPersonSummary: PropTypes.func.isRequired,
  messagesGetMessages: PropTypes.func.isRequired,
  fetchExpiring: PropTypes.func.isRequired,
  config: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  passedCompetencesGroupedByType: getPassedCompetencesGroupedByType(state),
  config: getConfigObject(state),
  }
);

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerMyEducationMainViewDidMount,
      profileFetchPersonSummary,
      fetchExpiring,
      messagesGetMessages,
    },
    dispatch,
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyEducationContainer);
