import React from 'react';
import PropTypes from 'prop-types';

import {highlightOnKeyboardFocusMixin} from '@styles/mixins';
import {css} from '@emotion/react';
import {Content} from './styles';

export const CourseInformationContent = ({children}) => (
  <Content
    css={css`
        ${highlightOnKeyboardFocusMixin};
      `}
  >
    {children}
  </Content>
);

CourseInformationContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};
