import React from 'react';
import PropTypes from 'prop-types';
import {Title} from './styles';

export const CourseInformationTitle = ({title}) => (
  <Title
    className="course-information-title"
    dangerouslySetInnerHTML={{__html: title}}
  />
);

CourseInformationTitle.propTypes = {title: PropTypes.any.isRequired};
