import React from 'react';
import PropTypes from 'prop-types';
import {InputSearchCheckboxes} from '@routes/reports/input-search-checkboxes';
import './form-multiselect.scss';

const FormMultiselect = ({
  className,
  label,
  data,
  defaultValue,
  input: {name, onBlur, onChange, value},
  meta,
  getTypeNameKey,
  getTypeIdKey,
  maxDropdownHeight,
  placeholder,
  messages,
  ...props
}) => (
  <div className="form-multiselect">
    {label && (
      <label
        className="form-multiselect__label"
        htmlFor={name}
      >
        {label}
      </label>
    )}
    <InputSearchCheckboxes
      alwaysOpen
      small
      value={value}
      onChange={onChange}
      items={data}
      getTypeNameKey={getTypeNameKey}
      getTypeIdKey={getTypeIdKey}
      nameKey="title"
      maxDropdownHeight={maxDropdownHeight}
      placeholder={placeholder}
      messages={messages}
    />
    {meta.touched && meta.error 
        && <div className="form-multiselect__error">{meta.error}</div>}
  </div>
);

FormMultiselect.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormMultiselect.defaultProps = {
  className: '',
  label: '',
};

export default FormMultiselect;
