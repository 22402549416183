import {put, select, takeLatest} from 'redux-saga/effects';

import {PROFILE_FETCH_PERSON_SUCCESS} from '@actions/profile.actions';
import {setTheme} from '@actions/theme.actions';
import {getBrandId} from '@selectors/profile.selectors';
import {appendThemeStyles, getTheme} from '@utils/themes.utils';

function* changeTheme() {
  const brandId = yield select(getBrandId);

  localStorage.setItem('brandId', brandId);
  const theme = getTheme(brandId);

  yield put(setTheme({theme}));
  appendThemeStyles(theme);
}

const brandId = localStorage.getItem('brandId');
const theme = getTheme(brandId);

appendThemeStyles(theme);

export default [takeLatest(PROFILE_FETCH_PERSON_SUCCESS, changeTheme)];
