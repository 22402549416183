import React, {useState} from 'react';
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {linearGradient, rgba, darken} from 'polished';
import {getSelectedCompetencegroupId} from '@selectors/courses.selectors';
import {useSelector} from 'react-redux';
import {ImageWithOverlay} from '../ImageWithOverlay';

const Container = styled('div')(
  {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    height: '100%',
    overflow: 'auto' /* To show the border radius */,
    borderRadius: '4px',
  },
  props => ({[`@media (max-width: ${props.mobileBreakpoint})`]: {borderRadius: '7px'}}),
  props => ({
    flexDirection: props.fullwidth ? 'row' : 'column',
    width: props.fullwidth ? '100%' : 'unset',
  }),
);

const Button = styled('button')(
  {
    borderRadius: 28,
    cursor: 'pointer',
    color: '#FFF',
    fontSize: 16,
    padding: '12px 16px',
    textDecoration: 'none',
    marginTop: 4,
    '@media (max-width: 400px)': {fontSize: '0.8em'},
    transition: 'background 0.2s ease-out, color 0.2s ease-out',
    background: 'rgba(255, 255, 255, 0.5)',
  },
  ({hover}) =>
    hover && {
      background: 'rgba(255, 255, 255, 0.9)',
      color: '#000',
    },
  ({dontShow}) => dontShow && {display: 'none'},
);

const text = css({
  color: 'white',
  fontWeight: 'bold',
  fontSize: 20,
  padding: 0,
  margin: 0,
});

const contentAlign = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '100%',
});

export const Bucket = ({
  active,
  shouldDisplayPicture,
  textConfig: {background, secondaryText, primaryText, hiddenByDefault},
  imageConfig: {
    src: imageSrc,
    position: imagePosition,
    overlay: imageOverlayColor,
  },
  fullwidth,
  mobileBreakpoint,
  ...props
}) => {
  const color = background ? background : '#001951';
  const item = shouldDisplayPicture
    ? css({flex: `100% 0 0; background: linear-gradient(${color} 0%, ${color} 100%)`})
    : css({flex: `50% 1 1 ; background: linear-gradient(${color} 0%, ${color} 100%)`});

  const displayNone = shouldDisplayPicture ? css({display: 'none'}) : null;

  const [hover, setHover] = useState(false);

  return (
    <Container
      className="bucket-wrapper"
      mobileBreakpoint={mobileBreakpoint}
      fullwidth={fullwidth}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      <div
        className="image-part"
        css={css(item, {backgroundColor: 'teal'}, displayNone, {
          borderTopLeftRadius: 'inherit',
          borderTopRightRadius: fullwidth ? 0 : 'inherit',
        })}
      >
        <ImageWithOverlay
          customCssImageParts={{
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: fullwidth ? 0 : 'inherit',
          }}
          customCssWrapper={{
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: fullwidth ? 0 : 'inherit',
          }}
          src={imageSrc}
          color={imageOverlayColor}
          colorAlpha={hover || active ? 0 : 0.5}
          imagePosition={imagePosition}
        />
      </div>
      <div
        css={[item, {background}]}
        className="text-part"
      >
        <div css={contentAlign}>
          <span
            css={{
              padding: 12,
              paddingBottom: 8,
            }}
          >
            <h3
              className="main-title"
              css={text}
            >
              {primaryText}
            </h3>
            {secondaryText && (
              <Button
                className="action-button"
                css={css`
                  margin-bottom: 0.4em;
                `}
                hover={hover || active}
                tabIndex={-1}
                dontShow={hiddenByDefault.includes('secondary')}
              >
                {secondaryText}
              </Button>
            )}
          </span>
        </div>
      </div>
    </Container>
  );
};
