import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {IconButtonStyled} from './buttonStyles';


export const IconButton = ({icon, theme, variant, ...props}) => (
  <IconButtonStyled
    theme={theme || 'gray-transparent'}
    variant={variant || 'primary'}
    {...props}
  >
    <FontAwesomeIcon
      icon={icon}
    />
  </IconButtonStyled>
);