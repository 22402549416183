export const stringifyUrlParams = (param, breadcrumbs, ignoreNull = false) => {
  if (param === undefined || ignoreNull && param === null) {
    return '';
  }

  if (
    typeof param === 'string'
    || typeof param === 'number'
    || typeof param === 'boolean'
    || param === null
  ) {
    return `${breadcrumbs}=${param}`;
  }

  if (Array.isArray(param)) {
    return param
      .map(elem => stringifyUrlParams(elem, `${breadcrumbs}`))
      .join('&');
  }

  return Object.entries(param)
    .map(([key, value]) =>
      stringifyUrlParams(
        value,
        breadcrumbs ? `${breadcrumbs}[${key}]` : key,
        ignoreNull,
      ))
    .filter(param => param !== '')
    .join('&');
};
