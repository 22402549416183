import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from '@emotion/styled';
import {emptyDate} from '@utils/misc.utils';
import {ActionButton} from '@components/buttons/actionButton';
import {css, jsx} from '@emotion/react';

import {Seperator} from '../../cv-styles';

import {Wrapper, Header, FormHelp,LeftPart, RightPart, ActionButtonsWrapper,CancelButton, DeleteButton, ManageExperienceWrapper, ManageExperienceWrapperHeader, Title, Description, AddNewIcon, AddNewBtn, AddNewBtnContent, StyledForm} from './edit-experience-type-styles';
import {reduxForm} from 'redux-form';

const [viewMode, editMode, addMode] = ['viewMode', 'editMode', 'addMode'];


/* Adding and changing of cv-entities of a specific type(eg education) */
const CVEditExperienceType = ({
  title,
  description,
  experienceListComponent,
  manageExperienceFormElementsComponent,
  addNewBtnTxt,
  formSchema,
  onAlteredData,
  experienceKind,
  initialExperienceData,
}) => {
  const [manageMode, setManageMode] = useState(viewMode);
  const [experienceData, setExperienceData] = useState(initialExperienceData);
  const [experienceUIDtoEdit, setExperienceUIDtoEdit] = useState(null);
  const enterAddMode = () => {
    setManageMode(addMode);
  };

  const enterEditMode = experienceUID => {
    setManageMode(editMode);
    setExperienceUIDtoEdit(experienceUID);
  };

  const getExperienceByUID = UID => experienceData.find(elem => elem.UID === UID);

  const getIndexOfExperienceByUID = UID =>
    experienceData.findIndex(elem => elem.UID === UID);

  const handleDate = date => {
    const isYearOnly = !/\d{4}/.test(date);

    if (isYearOnly) {
      const d = emptyDate();

      d.setFullYear(date);

      return d;
    }

    // is full date
    return new Date(date);
  };

  const onManageExperienceSubmit = formData => {
    let copyOfExperienceData;

    if (manageMode === editMode) {
      const experienceToEdit = getExperienceByUID(experienceUIDtoEdit);

      if (experienceToEdit) {
        const editedExperience = {
          ...experienceToEdit,
          ...formData,
        };

        if (formData.startdate) {
          editedExperience.startdate = handleDate(formData.startdate);
        } else if (formData.enddate) {
          editedExperience.enddate = handleDate(formData.enddate);
        }

        copyOfExperienceData = experienceData;
        copyOfExperienceData[
          getIndexOfExperienceByUID(experienceUIDtoEdit)
        ] = editedExperience;
      }
    } else if (manageMode === addMode) {
      const defaultData = {
        contact_person: '',
        experience_id: '',
        experience_type: experienceKind,
      };

      copyOfExperienceData = [...experienceData];
      const newEntity = {
        ...defaultData,
        ...formData,
      };

      if (formData.startdate) {
        newEntity.startdate = handleDate(formData.startdate);
      } else if (formData.enddate) {
        newEntity.enddate = handleDate(formData.enddate);
      }

      copyOfExperienceData.push(newEntity);
    }
    setExperienceData(copyOfExperienceData);
    onAlteredData(experienceKind, copyOfExperienceData);
    setManageMode(viewMode);
    setExperienceUIDtoEdit(null);
  };

  const onManageExperienceDelete = () => {
    const indexToDelete = experienceData.findIndex(e => e.UID === experienceUIDtoEdit);

    if (indexToDelete >= 0) {
      const copyOfExperienceData = experienceData;

      copyOfExperienceData.splice(indexToDelete, 1);
      setExperienceData(copyOfExperienceData);
      onAlteredData(experienceKind, copyOfExperienceData);
      setManageMode(viewMode);
      setExperienceUIDtoEdit(null);
    }
  };

  const onManageExperienceCancel = () => {
    setManageMode(viewMode);
    setManageMode(viewMode);
  };


  return (
    <Wrapper>
      <Header>{title}</Header>
      {description && (
        <>
          <Seperator />
          <Description>{description}</Description>
        </>
      )}
      <Seperator />
      {manageMode === viewMode && (
        <AddNewBtn onClick={enterAddMode}>
          <AddNewIcon />
          <AddNewBtnContent>{addNewBtnTxt}</AddNewBtnContent>
        </AddNewBtn>
      )}

      {manageMode === addMode && (
        <ManageExperience
          mode={addMode}
          title={addNewBtnTxt}
          onSubmit={onManageExperienceSubmit}
          formSchema={formSchema}
          formElements={manageExperienceFormElementsComponent}
          onCancel={onManageExperienceCancel}
        />
      )}
      {manageMode === editMode && (
        <ManageExperience
          mode={editMode}
          title={`Endre ${title.toLowerCase()}`}
          onSubmit={onManageExperienceSubmit}
          onDelete={onManageExperienceDelete}
          formSchema={formSchema}
          formElements={manageExperienceFormElementsComponent}
          initialFormData={getExperienceByUID(experienceUIDtoEdit)}
          onCancel={onManageExperienceCancel}
        />
      )}
      {React.createElement(experienceListComponent, {
        displayEditBtn: true,
        data: experienceData.filter(e => e.UID !== experienceUIDtoEdit),
        editMode: true,
        onEditClick: experienceUID => {
          enterEditMode(experienceUID);
        },
      })}
      <Seperator />
    </Wrapper>
  );
};


const ManageExperienceForm = reduxForm({form:'cv-edit'})(({children, ...props}) => (
  <StyledForm {...props}>
    {children}
  </StyledForm>
));


const ManageExperience = ({
  title,
  mode,
  formElements,
  onCancel,
  onSubmit,
  onDelete,
  initialFormData,
  formSchema,
}) => (
  <ManageExperienceWrapper>
    <ManageExperienceWrapperHeader>{title}</ManageExperienceWrapperHeader>
    <FormHelp>* må fylles ut</FormHelp>
    <ManageExperienceForm
      initialData={initialFormData}
      autofill="off"
      autoComplete="off"
      schema={formSchema}
      onSubmit={(data, {resetForm}) => {
        onSubmit(data);
        resetForm();

        return true;
      }}
    >
      {React.createElement(formElements, {})}
      <ActionButtonsWrapper>
        
        <LeftPart>

          <CancelButton
            type="button"
            onClick={onCancel}
            css={css`margin-right: 1em;`}
          >
            Avbryt
          </CancelButton>

          <ActionButton
            type="submit"
            css={css`margin-top: 0;`}
          >
            {mode === editMode ? 'Endre' : 'Legg til'}
          </ActionButton>
        </LeftPart>
        {mode === editMode && (
          <RightPart>
            <DeleteButton
              type="button"
              action
              small
              onClick={onDelete}
            >
              Fjern erfaring
            </DeleteButton>
          </RightPart>
        )}
      </ActionButtonsWrapper>
    </ManageExperienceForm>
  </ManageExperienceWrapper>
);

ManageExperience.propTypes = {
  title: PropTypes.string.isRequired,
  mode: PropTypes.oneOf([editMode, addMode]).isRequired,
  formElements: PropTypes.element.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  formSchema: PropTypes.shape({}),
  initialFormData: PropTypes.shape({}),
};

ManageExperience.defaultProps = {
  formSchema: null,
  initialFormData: {},
};

export default CVEditExperienceType;

CVEditExperienceType.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  addNewBtnTxt: PropTypes.string.isRequired,
  experienceListComponent: PropTypes.element.isRequired,
  initialExperienceData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  manageExperienceFormElementsComponent: PropTypes.element.isRequired,
  onAlteredData: PropTypes.func.isRequired,
  formSchema: PropTypes.shape({}),
  experienceKind: PropTypes.string.isRequired,
};

CVEditExperienceType.defaultProps = {formSchema: null};
