import './course-card.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import AspectRatio from '@components/common/aspect-ratio';
import {css, jsx} from '@emotion/react';
import {
  highlightOnKeyboardFocusMixin,
  screenreaderOnlyMinxin,
} from '@styles/mixins';

import {getImageUrl} from '@utils/misc.utils';

import {i18n} from '@src/i18n';
import {
  Wrapper,
  MainImage,
  MainText,
  SecondaryText,
  TextAndButtonsWrapper,
  ImageContentWrapper,
  MetaInformationLine,
  MetaInformationWrapper,
  MetaIcon,
  MetaText,
  SecondaryImage,
  ReadMore,
  SecondaryTextWrapper,
  IconsWrapper,
  IconWrapper,
  ActionButton,
  TextWrapper,
  Icon2,
  wrapperStyle as courseCardWrapperStyle,
} from './styles';

import {AutoStartCourseWrapper} from './helper-components';

const CourseCard = ({
  href,
  shouldAutostartOnClick,
  onAutostartCourse,
  mainText,
  secondaryText,
  image,
  metaInformation,
  showActionButton,
  id,
  competence_type_id,
  competenceGrpupName,
}) => {
  const WrapperComponent = shouldAutostartOnClick
    ? AutoStartCourseWrapper
    : Link;

  return (
    <WrapperComponent
      href="/"
      tabIndex="0"
      role="button"
      className={`course-card group-${competenceGrpupName}`}
      css={[courseCardWrapperStyle, highlightOnKeyboardFocusMixin]}
      to={href}
      onClick={() => {
        if (shouldAutostartOnClick) {
          onAutostartCourse(id, competence_type_id);
        }
      }}
    >
      <CourseCardContents
        mainText={mainText}
        secondaryText={secondaryText}
        image={image}
        metaInformation={metaInformation}
        showActionButton={showActionButton}
      />
    </WrapperComponent>
  );
};

CourseCard.propTypes = {cover: PropTypes.shape({url: PropTypes.string})};

CourseCard.defaultProps = {cover: null};

const ScreenreaderImgText = ({text}) => (
  <span
    role="img"
    aria-label={text}
    css={css`
      ${screenreaderOnlyMinxin}
    `}
  />
);

const renderMetaInformation = ({metaInformation, location}) => (
  <MetaInformationWrapper location={location}>
    {metaInformation
      .filter(mi => mi.location === location)
      .map((mi, i) => (
        <MetaInformationLine
          location={location}
          key={i}
          className={mi.name}
        >
          <MetaIcon
            icon={mi.icon}
            color={mi.color}
          />
          <MetaText>{mi.text || mi.element}</MetaText>
        </MetaInformationLine>
      ))}
  </MetaInformationWrapper>
);

const CourseCardContents = ({
  mainText,
  secondaryText,
  metaInformation,
  image,
  showActionButton,
}) => {
  const hasTextOrActionButtons
    = mainText && mainText.content
    || secondaryText && secondaryText.content
    || showActionButton;
  const hasSecondaryImage = image.secondaryImages || image.secondaryImage;
  const hasContentForImageCont
    = image
    || metaInformation.filter(mi => mi.location === 'image-part').length > 0;

  return (
    <Wrapper>
      {hasContentForImageCont && (
        <ImageContentWrapper>
          <AspectRatio
            className="image-cover-wrapper"
            ratio={image.aspectRatio || 2}
            style={{
              borderTopLeftRadius: 'inherit',
              borderTopRightRadius: 'inherit',
            }}
          >
            <MainImage src={getImageUrl(image.src)} />
            <ScreenreaderImgText text={image.imageAltText} />
          </AspectRatio>

          <IconsWrapper>
            <IconWrapper
              className="icon-top-left-wrapper"
              placement="top-left"
              displayAsCircle
            >
              <Icon2 className="icon" />
            </IconWrapper>

            <IconWrapper
              className="icon-top-right-wrapper"
              placement="top-right"
              displayAsCircle
            >
              <Icon2 className="icon" />
            </IconWrapper>

            <IconWrapper
              className="icon-bottom-left-wrapper"
              placement="bottom-left"
              displayAsCircle
            >
              <Icon2 className="icon" />
            </IconWrapper>

            <IconWrapper
              className="icon-bottom-right-wrapper"
              placement="bottom-right"
              displayAsCircle
            >
              <Icon2 className="icon" />
            </IconWrapper>
          </IconsWrapper>

          {hasSecondaryImage
            && (image.secondaryImages || [image.secondaryImage]).map((image, i) => (
              <>
                <SecondaryImage
                  key={`image${i}`}
                  placement={image.placement}
                  src={getImageUrl(image.src)}
                  color={image.color}
                />
                <ScreenreaderImgText text={image.imageAltText} />
              </>
            ))}

          {metaInformation
            && metaInformation.find(mi => mi.location === 'image-part')
            && renderMetaInformation({
              metaInformation,
              location: 'image-part',
            })}
        </ImageContentWrapper>
      )}

      {hasTextOrActionButtons && (
        <TextAndButtonsWrapper>
          <TextWrapper className="title-wrapper">
            {mainText && mainText.content && (
              <MainText
                className="title"
                css={mainText.css}
              >
                {mainText.content}
              </MainText>
            )}

            {secondaryText && secondaryText.content && (
              <SecondaryTextWrapper className="secondary-text-wraooer">
                <SecondaryText
                  className="secondary-text"
                  css={secondaryText.css}
                  dangerouslySetInnerHTML={{__html: secondaryText.content}}
                />
                {secondaryText.showReadMore && (
                  <ReadMore className="read-more">
                    {i18n('globals.read-more')}
                  </ReadMore>
                )}
              </SecondaryTextWrapper>
            )}
            {metaInformation
              && metaInformation.find(mi => mi.location === 'text-part')
              && renderMetaInformation({
                metaInformation,
                location: 'text-part',
              })}
          </TextWrapper>

          {showActionButton && (
            <ActionButton
              className="action-button"
              onClick={showActionButton.onClick}
              dontShow={showActionButton.dontShow}
            >
              {showActionButton.text}
            </ActionButton>
          )}
        </TextAndButtonsWrapper>
      )}
    </Wrapper>
  );
};

const iconShape = PropTypes.shape({
  // font-awesome icons, first elem in array is kind second is icon ['fal','player']
  name: PropTypes.arrayOf(PropTypes.string),

  placement: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]),

  color: PropTypes.string,
  fontSize: PropTypes.string,

  surroundByCircle: PropTypes.shape({
    background: PropTypes.string,
    padding: PropTypes.string,
  }),
});

CourseCardContents.propTypes = {
  mainText: PropTypes.shape({
    content: PropTypes.string,
    css: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }),
  secondaryText: PropTypes.shape({
    content: PropTypes.string,
    css: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    showReadMore: PropTypes.bool,
  }),
  image: PropTypes.shape({
    src: PropTypes.string,
    icons: PropTypes.arrayOf(iconShape),
    icon: iconShape,
    secondaryImage: PropTypes.shape({
      src: PropTypes.string,
      placement: PropTypes.oneOf([
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right',
      ]),
    }),
  }),
  showActionButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  metaInformation: PropTypes.arrayOf(PropTypes.shape({
    location: PropTypes.oneOf(['image-part', 'text-part']),
    icon: PropTypes.arrayOf(PropTypes.string), // se iconShape -> name
    element: PropTypes.node,
  })),
};

CourseCardContents.defaultProps = {
  image: null,
  mainText: null,
  secondaryText: null,
  showActionButton: null,
  metaInformation: null,
};

export default CourseCard;
