import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {
  withRouter,
  matchPath,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';

import {getRoutesBreadcrumbsArray} from '@utils/routes.utils';

import MyEducationContainer from './containers/my-education-container';
import MyEducationRoleContainer from './containers/my-education-role-container';
import MyEducationCourseContainer from './containers/my-education-course-container';
import MyEducationMessagesContainer from './containers/my-education-messages';
import MyEducationCompetenceContainer from './containers/my-education-competences';
import ProfileContainer from './containers/profile-container';
import { CvContainer} from './containers/my-education-cv/cv-container';

const MyEducationSwitch = ({location, breadcrumbs}) => (
  <div
    className={`my-education-switch ${
      location.pathname.includes('/my-education/role') ? 'narrow' : '',
      location.pathname.includes('/edit-cv') ? 'nopadding' : ''
    }`}
  >
    <div className="my-education-switch__section">
      <Switch>
        <Route
          path={myEducationRoutes.main.path}
          exact
          breadcrumbs
          component={MyEducationContainer}
        />

        <Route
          path={myEducationRoutes.cvEdit.path}
          breadcrumbs
          component={CvContainer}
        />
        <Route
          path={myEducationRoutes.profileView.path}
          breadcrumbs
          component={ProfileContainer}
        />
        <Route
          path={myEducationRoutes.cvView.path}
          breadcrumbs
          component={MyEducationCompetenceContainer}
        />
        <Route
          path={myEducationRoutes.messagesView.path}
          breadcrumbs
          component={MyEducationMessagesContainer}
        />
        <Route
          path={myEducationRoutes.courseView.path}
          breadcrumbs
          component={MyEducationCourseContainer}
        />
        <Route
          path={myEducationRoutes.roleCourseView.path}
          breadcrumbs
          component={MyEducationCourseContainer}
        />
        <Route
          path={myEducationRoutes.roleView.path}
          breadcrumbs
          component={MyEducationRoleContainer}
        />
        <Redirect to={myEducationRoutes.main.path} />
      </Switch>
    </div>
  </div>
);

MyEducationSwitch.propTypes = {
  location: PropTypes.shape({pathname: PropTypes.string.isRequired}).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default compose(
  withRouter,
  withBreadcrumbs(getRoutesBreadcrumbsArray(myEducationRoutes), {disableDefaults: true}),
)(MyEducationSwitch);
