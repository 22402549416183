import {call, delay} from 'redux-saga/effects';

export function* retrySaga(callback, attempts = 1, wait = 2000, ...args) {
  for (let i = 0; i <= attempts; i += 1) {
    try {
      const response = yield call(callback, ...args);

      return response;
    } catch (error) {
      const status
        = error && (error.status || error.request && error.request.status);

      if (status === 401) {
        throw error;
      }
      if (i < attempts) {
        yield delay(wait);
      } else {
        throw error;
      }
    }
  }

  return callback;
}

export default function(callback, attempts, wait) {
  return call(retrySaga, callback, attempts, wait);
}
