import {takeLatest, put, select} from 'redux-saga/effects';
import axios from 'axios';

import {backendUrl, newsWidgetStart} from '@config';

import {
  NEWS_GET_ALL,
  newsGetAllRequest,
  newsGetAllSuccess,
  newsGetAllFailure,
  NEWS_GET,
  newsGetRequest,
  newsGetSuccess,
  newsGetFailure,
  NEWS_SET_ACTIVE,
} from '@actions/news.actions';

import retry from '@utils/sagas.utils';

import {getFetchedNews} from '@selectors/news.selectors';
import {authUnauthorized} from '@actions/auth.actions';

function* getAllNews(action) {
  yield put(newsGetAllRequest());
  const newsWidgetStart = action.payload.id;
  try {
    const {data} = yield retry(() =>
      axios.request({
        method: 'GET',
        params: {
          sort: 'id',
          sort_order: 'desc',
          fields: 'body,title,image,redirect,id',
        },
        url: `${backendUrl}/api/cms/${newsWidgetStart}/pages`,
        withCredentials: true,
      }));

    const {pages: news} = data;

    yield put(newsGetAllSuccess({news}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(newsGetAllFailure({error}));
  }
}

function* getNews({payload: {id}}) {
  const fetchedNews = yield select(getFetchedNews);
  const wasFetched = fetchedNews.find(item => item.id === id);

  if (wasFetched) return;

  yield put(newsGetRequest());
  try {
    const {data} = yield retry(() =>
      axios.request({
        method: 'GET',
        url: `${backendUrl}/api/cms/${id}`,
        params: {fields: 'body,redirect'},
        withCredentials: true,
      }));

    const {pages: news} = data;

    yield put(newsGetSuccess({news: news[0]}));
  } catch (error) {
    console.error(error);
    if (error.status === 401) {
      yield put(authUnauthorized({error}));
    }
    yield put(newsGetFailure({error}));
  }
}

export default [
  takeLatest(NEWS_GET_ALL, getAllNews),
  takeLatest(NEWS_GET, getNews),
  takeLatest(NEWS_SET_ACTIVE, getNews),
];
