import styled from '@emotion/styled';
import {css} from '@emotion/react';
import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import SearchBox from './search-box';
import {backendUrl} from '../../config';
import Calendar from 'react-widgets/Calendar';
import 'react-widgets/styles.css';

const StyledDateTileSelected = styled.div`
  background-color: #618da7;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
`;

const StyledDateTile = styled.div`
  cursor: default;
  padding: 1px;
  margin: 0.1em 0;
  color: ${({selectable, allowed, highlight}) =>
  selectable && allowed || highlight ? '#000' : '#ddd'};

  ${({selectable, allowed, highlight}) =>
    highlight
      ? css`
          border-radius: 0 !important;
          background-color: #e0e8ee !important;
        `
      : selectable
        && allowed
        && css`
          :hover {
            border-radius: 50%;
            background-color: #e0e8ee !important;
            cursor: pointer;
          }
        `}
`;

const StyledCalendar = styled(Calendar)`
  .rw-calendar-month .rw-cell {
    padding: 0;
    line-height: 2.5;
    height: 2.5em;
    border: none;
    border-radius: 0;
    overflow: hidden;
    background-color: transparent !important;
    cursor: default;

    &.rw-state-focus ${StyledDateTile} {
      border-radius: 50%;
      border: 1px solid #e0e8ee;
      padding: 0;
    }

    .rw-state-disabled ${StyledDateTile} {
      background-color: transparent !important;
    }

    :last-child ${StyledDateTile} {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
  }

  .rw-calendar-header .rw-calendar-btn-view {
    text-transform: capitalize;
    color: #333;
    opacity: 1;
  }
`;

const ReportDatepicker = ({value: valueProp, onChange, ...props}) => {
  const [allowedWeeks, setAllowedWeeks] = useState(null);

  useEffect(() => {
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/apiv2/dashboard/weeks`,
        withCredentials: true,
      })
      .then(({data}) => setAllowedWeeks(data));
  }, []);
  const [value, setValue] = useState(valueProp || null);
  const [changed, setChanged] = useState(false);
  const [input, setInput] = useState(value && value.format('DD.MM.YYYY [(uke ]W[)]') || '');
  
  const endOfWeek = date => {
    if (date.day() !== 0) {
      date.add(1, 'week').day(0);
    }

    return date;
  };

  const onDateSelect = useCallback(
    value => {
      const date = moment(value);
      const year = date.year();
      const week = date.isoWeek();
      const allowed
        = !allowedWeeks
        || allowedWeeks.years[year] && allowedWeeks.years[year].weeks[week];

      if (allowed) {
        const endOfWeekDate = endOfWeek(date);

        setValue(endOfWeekDate);
        setInput(endOfWeekDate.format('DD.MM.YYYY [(uke ]W[)]'));
        setChanged(true);
      }
    },
    [setValue, setInput, allowedWeeks],
  );

  const isAllowed = useCallback(date => {
    const year = date.year();
    const week = date.isoWeek();

    return (
      !allowedWeeks
      || allowedWeeks.years[year] && allowedWeeks.years[year].weeks[week]
    );
  }, [allowedWeeks]);

  const onInput = useCallback(
    input => {
      setInput(input);
      const date = moment(input, 'DD.MM.YYYY');

      if (date.isValid() && isAllowed(date)) {
        setValue(date);
      }
    },
    [isAllowed],
  );

  useEffect(() => {
    if (valueProp) {
      setValue(valueProp);
      setChanged(false);
    }
  }, [valueProp]);

  useEffect(() => {
    if (changed && onChange) {
      onChange(value);
    }
    setChanged(false);
  }, [changed, onChange, value]);

  const onBlur = useCallback(() => {
    setInput(value && value.format('DD.MM.YYYY [(uke ]W[)]') || '');
  }, [value]);

  return (
    <>
      <DatepickerRow>
        <SearchBox
          {...props}
          iconClassName="far fa-calendar"
          placeholder="dd.mm.yyyy"
          onSearch={onInput}
          selectedItem={value}
          value={input}
          items={[true]}
          onBlur={onBlur}
          renderDropdown={() => (
            <StyledCalendar
              culture="no"
              views={['month']}
              renderDay={({date: dateDate, label}) => {
                const date = moment(dateDate);
                const day = date.day();
                const allowed = isAllowed(date);

                return (
                  <StyledDateTile
                    selectable={day === 0}
                    onClick={() => false}
                    allowed={allowed}
                    highlight={value && value.isoWeek() === date.isoWeek()}
                  >
                    {value && date.isSame(value, 'day') 
                        && <StyledDateTileSelected>{label}</StyledDateTileSelected>
                       ||                        label}
                  </StyledDateTile>
                );
              }}
              value={value && value.toDate()}
              onChange={onDateSelect}
            />
          )}
        />
      </DatepickerRow>
      {allowedWeeks && (
        <ButtonRow>
          {isAllowed(moment().subtract(1, 'year')) && (
            <LinkButton
              role="button"
              onClick={() => onDateSelect(moment().subtract(1, 'year'))}
            >
              Siste året
            </LinkButton>
          )}
          {isAllowed(moment().subtract(6, 'month')) && (
            <LinkButton
              role="button"
              onClick={() => onDateSelect(moment().subtract(6, 'month'))}
            >
              6 mnd
            </LinkButton>
          )}
          {isAllowed(moment().subtract(1, 'month')) && (
            <LinkButton
              role="button"
              onClick={() => onDateSelect(moment().subtract(1, 'month'))}
            >
              1 mnd
            </LinkButton>
          )}
        </ButtonRow>
      )}
    </>
  );
};

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
`;

const LinkButton = styled.div`
  flex: 0 1 auto;
  text-decoration: none;
  color: #005cae;
  cursor: pointer;
  margin: 0 8px;
  line-height: 1.6;
  border-bottom: 1px solid #005cae;
`;

const DatepickerRow = styled.div`
  max-width: 280px;
`;

ReportDatepicker.propTypes = {onChange: PropTypes.func.isRequired};

export default ReportDatepicker;
