export const backendUrl = process.env.NODE_ENV !== 'development' && process.env.REACT_APP_BACKEND_URL || '/grape';
export const backendUrlV2 = process.env.REACT_APP_BACKEND_URL_V2;
export const nanoLearningUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_NANO_LEARNING_URL : process.env.REACT_APP_NANO_LEARNING_URL || '';

export const courseCatalogExcludeCategory
  = process.env.REACT_APP_COURSE_CATALOG_EXCLUDE || '';
export const newsWidgetStart = process.env.REACT_APP_NEWS_START || '';
export const version = process.env.REACT_APP_VERSION || ''; // is set on build to match package.json version
export const buildHash = process.env.REACT_APP_GIT_SHA || ''; // this is set to match sha1 of git HEAD
export const publicUrl = process.env.REACT_APP_PUBLIC_URL || '';
export const nanoLearningLoginUrl
  = process.env.REACT_APP_NANO_LEARNING_LOGIN_URL || '';
export const configFileUrl = `${backendUrl}${process.env.REACT_APP_CONFIG_FILE_URL}`;
export const appTitle = process.env.REACT_APP_TITLE || '';
