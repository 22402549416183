import React from 'react';
import PropTypes from 'prop-types';
import ThemedLogo from '@components/themed-logo/themed-logo';
import classNames from 'classnames';

import {NavLink} from 'react-router-dom';

import './desktop-menu.scss';
import {i18n} from '@src/i18n';
import {Menu as SnapperMenu} from '@snapper/core';

const DesktopMenu = ({menuLinks, onLogoutClick, data}) => (
  <div className="desktop-menu">
    <SnapperMenu data={data} />

    <div className="desktop-menu__bottom-section">
      {menuLinks.map(({name, path, active, exact = false}, index) => (
        <NavLink
          className={classNames('desktop-menu__link', {'desktop-menu__link--active': active})}
          activeClassName="desktop-menu__link--active"
          exact={exact}
          to={path}
          strict={false}
          key={index}
        >
          {name}
        </NavLink>
      ))}
    </div>
  </div>
);

DesktopMenu.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default DesktopMenu;
