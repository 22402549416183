/* eslint-disable react/prop-types */
import {jsx} from '@emotion/react';

import {
  Container,
  Path as StyledPath,
  Empty,
  Circle,
} from '@routes/atlas/components/Path/styles';
import {Item} from '@routes/atlas/components/Hexagon/Hexagon';

const CreatePath = ({index, completedCourses, coursesLength, hidePath}) => (
  <StyledPath>
    {!hidePath 
      ? (index === 0 || index === coursesLength - 1) 
      && <Circle completed={completedCourses >= index || index === 0} />
    
      || index % 2 === 0 
        && (
          <Circle
            variant="left"
            completed={completedCourses >= index}
          />
        )
       || (
         <Circle
           variant="right"
           completed={completedCourses >= index}
         />
       )
      : null}
  </StyledPath>
);

const Path = ({
  index,
  completedCourses,
  coursesLength,
  course,
  activeCourseID,
  clickMode,
  animateCompleted,
  disableInteraction,
  hidePath,
}) => (
  <Container key={`Container-${index}`}>
    {index % 2 === 0 && (
      <Item
        index={index}
        course={course}
        activeCourseID={activeCourseID}
        clickMode={clickMode}
        completedCourses={completedCourses}
        animateCompleted={animateCompleted}
        disableInteraction={disableInteraction}
      />
    ) || <Empty />}
    <CreatePath
      index={index}
      completedCourses={completedCourses}
      coursesLength={coursesLength}
      hidePath={hidePath}
    />
    {index % 2 === 1 && (
      <Item
        index={index}
        course={course}
        activeCourseID={activeCourseID}
        clickMode={clickMode}
        completedCourses={completedCourses}
        animateCompleted={animateCompleted}
        disableInteraction={disableInteraction}
      />
    ) || <Empty />}
  </Container>
);

export default Path;
