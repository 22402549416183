/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {jsx} from '@emotion/react';
import PropTypes from 'prop-types';
import {CircleSVG, BoundingSVG, SVGContent, circle0, circle1} from './styles';

export const CircularProgressBar = React.forwardRef((props, ref) => {
  // Size of the enclosing square
  const {sqSize} = props;
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (props.sqSize - props.strokeWidth) / 2;
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2;
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - dashArray * props.percentage / 100;

  return (
    <div
      ref={ref}
      css={{
        position: 'relative',
        width: `${sqSize}px`,
        height: `${sqSize}px`,
      }}
    >
      <BoundingSVG>
        <CircleSVG
          width={props.sqSize}
          height={props.sqSize}
          viewBox={viewBox}
        >
          <circle
            css={circle0}
            cx={props.sqSize / 2}
            cy={props.sqSize / 2}
            r={radius}
            strokeWidth={`${props.strokeWidth}px`}
          />
          <circle
            css={[circle1, props.facepaint.circle1]}
            cx={props.sqSize / 2}
            cy={props.sqSize / 2}
            r={radius}
            strokeWidth={`${props.strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${props.sqSize / 2} ${props.sqSize / 2})`}
            transition="transform 2s"
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
            }}
          />
        </CircleSVG>

        <SVGContent>
          {props.children || (
            <span>
              {props.percentage}
              %
            </span>
          )}
        </SVGContent>
      </BoundingSVG>
    </div>
  );
});

CircularProgressBar.propTypes = {
  sqSize: PropTypes.number,
  percentage: PropTypes.number,
  strokeWidth: PropTypes.number,
  facepaint: PropTypes.object,
};

CircularProgressBar.defaultProps = {
  sqSize: 200,
  percentage: 25,
  strokeWidth: 10,
  facepaint: {circle1: {stroke: '#BADA55'}},
};
