import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';

export const DotMatrixTable = styled.table`
    td, th{
        padding: 0.5em;
    }
`;

const colors = {
  red:'#F03E3E',
  green:'#008243',
  header:'#3B4D63',
  gray:'#E0E0E0',
};

export const Dot = styled.div`
  border-radius: 50%;
  display: inline-block;
  height: 1em;
  width: 1em;
  ${({fill}) =>
  ({
    green: css`
        background-color: ${colors.green};
      `,
    red: css`
        background-color: ${colors.red};
      `,
    gray: css`
        background-color: ${colors.gray};
      `,
  }[fill])};
`;

const cellStyle = css`
    white-space: nowrap;
`;

export const Cell = styled.td`
    ${cellStyle};
`;

export const Header = styled.th`
    ${cellStyle};
    color: ${colors.header};
    font-weight: 500;
    text-align: left;
    font-size: 0.7em;
`;



export const RotatingHeader = styled(Header)`
    ${cellStyle};
    transform: rotate(-55deg);
    height: 315px;
    transform-origin: 155px 222px;
`;

export const RotatingHeaderContent = styled.div`
    width: 3.5em;
`;
