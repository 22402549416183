const prefix = 'competences';

export const COMPETENCES_TOGGLE = `${prefix}/TOGGLE`;
export const COMPETENCES_TOGGLE_REQUEST = `${prefix}/TOGGLE/REQUEST`;
export const COMPETENCES_TOGGLE_SUCCESS = `${prefix}/TOGGLE/SUCCESS`;
export const COMPETENCES_TOGGLE_FAILURE = `${prefix}/TOGGLE/FAILURE`;


export const competencesToggle = payload => ({
  type: COMPETENCES_TOGGLE,
  payload,
});

export const competencesToggleRequest = () => ({type: COMPETENCES_TOGGLE_REQUEST});


export const competencesToggleSuccess = payload => ({
  type: COMPETENCES_TOGGLE_SUCCESS,
  payload,
});

export const competencesToggleFailure = payload => ({
  type: COMPETENCES_TOGGLE_FAILURE,
  payload,
});

