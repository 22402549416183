import {Wrapper, InfoPart, Name, Info, Right, Left, ActionButtonPart, DayInMonth} from './course-event-card-styles';
import React from 'react';
import {css, jsx} from '@emotion/react';
import {i18n, i18nFormatTimeRange} from '@src/i18n';
import CourseSignButtonAndModal from '@components/course-sign-button-and-modal/course-sign-button-and-modal';

export const CourseEventCard = ({courseEvent, onClickSignMeUp, usersCourseEvent, employees, opnOpenEmployeeSelect, props}) => {
  const startDateObj = new Date(courseEvent.startdate);
  const endDateObj = new Date(courseEvent.enddate);

  return (
    <Wrapper {...props}>
      <InfoPart>
        <Left>
          <DayInMonth>{startDateObj.getDate()}</DayInMonth>
        </Left>
        <Right>
          <Name>{courseEvent.title}</Name>
          <Info>
            {i18nFormatTimeRange({
              from: {
                hours: startDateObj.getHours(),
                minutes: startDateObj.getMinutes(),
              },
              to: {
                hours: endDateObj.getHours(),
                minutes: startDateObj.getMinutes(),
              },
            })}
            {courseEvent.location && courseEvent.location.title
            && `, ${courseEvent.location.title}`}
          </Info>
        </Right>
      </InfoPart>
      <ActionButtonPart>

        <CourseSignButtonAndModal
          courseEvent={courseEvent}
          selfCourseEvent={usersCourseEvent}
        />

      </ActionButtonPart>
    </Wrapper>
  );
};
