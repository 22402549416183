import styled from '@emotion/styled';
import {css, keyframes} from '@emotion/react';

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  height: 8px;
  overflow: visible;
`;

const Bounce = keyframes`
  from {
    transform: translateY(0px);
  }
  30% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(0px);
  }
  to {
    transform: translateY(0px);
  }
`;

const Dot = styled.div`
  background: ${({color}) => color};
  flex: 0 0 auto;
  display: block;
  width: 0.5em;
  height: 0.5em;
  overflow: visible;
  border-radius: 50%;
  position: relative;
  margin: auto 0.25em;

  ${({duration, delay}) => css`
    animation: ${Bounce} ${duration} ease-out infinite;
    animation-delay: ${delay};
  `}
`;

const LoadingIndicator = ({color = '#ef591b', ...props}) => (
  <DotContainer
    {...props}
  >
    <Dot
      color={color}
      duration="1200ms"
      delay="300ms"
    />
    <Dot
      color={color}
      duration="1200ms"
      delay="500ms"
    />
    <Dot
      color={color}
      duration="1200ms"
      delay="700ms"
    />
  </DotContainer>
);

export default LoadingIndicator;