/* eslint-disable no-debugger */
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {withRouter} from 'react-router-dom';

import {css, jsx} from '@emotion/react';

import {bindActionCreators} from 'redux';

import {getIsMobile} from '@selectors/global.selectors';

import {cmsGetContent} from '@actions/cms.actions';
import {getContent} from '@selectors/cms.selectors';
import ContentViewer from '@routes/content/components/contentViewer/contentViewer';
import {getConfigObject} from '@selectors/config.selectors';

/*
Fetches neseccarry data and displays CMS-content
*/
const ContentViewerContainer = ({
  cmsGetContent,
  content,
  match,
  isMobile,
  configObject,
}) => {
  const cmsIdForContent = match.params.contentId;

  useEffect(() => {
    if (
      !content.isFetching
      && !content.error
      && (!content.data || !content.data[cmsIdForContent])
    ) {
      cmsGetContent({contentId: cmsIdForContent});
    }
  });

  const loaded
    = !content.isFetching
    && !content.error
    && content.data
    && content.data[cmsIdForContent];

  const currContent = loaded && content.data[cmsIdForContent];

  return (
    <ContentViewer
      isLoading={!loaded}
      content={currContent}
      isMobile={isMobile}
    />
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {cmsGetContent},
    dispatch,
  );

const mapStateToProps = (state, props) => ({
  content: getContent(state, props),
  isMobile: getIsMobile(state, props),
  configObject: getConfigObject(state, props),
});

ContentViewerContainer.propTypes = {
  cmsGetContent: PropTypes.func.isRequired,
  content: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

ContentViewerContainer.defaultProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContentViewerContainer));
