import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';

const MissingContentCont = styled.div`
  color: #494949;
`;

const EditButtonCont = styled.span`
  padding: 4px;
  float: right;
`;

const Title = styled.h2`
  display: inline-block;
  margin-top: 0;
  font-weight: 500;
  ${props => (props.centerTitle ? 'left: 50%; position:relative;' : '')}
`;



const Container = styled.div`
  width: 100%;
`;
const Seperator = styled.div`
  flex: 0 0 auto;
  background: #eaebed;
  width: 100%;
  height: 1px;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
`;


/**
 * A container for displaying its child elements with a title and a border beneath the title.
 *
 * A action-button can be provided and will be placed at the top right of the container
 */
export const InfoCollection = ({
  title,
  actionButton = null,
  children,
  style,
  titleStyle,
  centerTitle,
  missingContentComponent,
}) => (
  <Container style={style}>
    {title && (
      <Title
        centerTitle={centerTitle}
        style={titleStyle}
      >
        {title}
      </Title>
    )}
    {actionButton && <EditButtonCont>{actionButton}</EditButtonCont>}
    {title && <Seperator />}
    {children
          || missingContentComponent 
            && <MissingContentCont>{missingContentComponent}</MissingContentCont>}
  </Container>
);

const PointSeperatorStyle = styled.span`
  &::after {
    content: '•';
  }
  margin-left: 6px;
  margin-right: 6px;
  font-size: 0.7em;
`;

const PointSeparator = () => <PointSeperatorStyle aria-hidden="true" />;

export default PointSeparator;

  