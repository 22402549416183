export default {
  totalt: {
    name: 'All kompetanse',
    cti: '',
  },
  aldersgrense: {
    name: 'Salg av produkter med aldersgrense',
    cti: '866',
  },
  tryggmat: {
    name: 'Trygg vare rapport',
    cti: '550,750',
  },
};
