import React from 'react';
import PropTypes from 'prop-types';
import {Box, p} from './styles';

export const NoCoursesAvailable = ({children}) => 
  <Box css={p}>{children}</Box>
;

NoCoursesAvailable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};
