import React, {useContext} from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {createPortal} from 'react-dom';

const UnsavedChangesDisplay = ({onSave}) => {
  const Wrapper = styled.div`
    background-color: #959595;
    color: white;
    font-size: 1.5em;
    position: fixed;
    top: 0;
    display: flex;
    left: 0px;
    width: 100%;
    ${({isMobile}) =>
    isMobile
      ? css`
            margin-top: 52px;
          `
      : ''}
  `;

  const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
  `;

  const StyledButton = styled.button`
    height: 55px;
    font-size: 0.8em;
    /* padding: 10px 5px; */
    padding: 9px;
    margin-left: 10px;
    align-self: center;

    ${({cancel}) =>
    cancel
      ? css`
            && {
              background-color: transparent;
              color: white;
              margin-left: 10px;
              border: none;
              font-size: 0.7em;

              :hover {
                background-color: #e83535;
              }
            }
          `
      : ''}
  `;

  const UpdatedTxt = styled.p``;

  const {isMobile} = false;

  return createPortal(
    <Wrapper isMobile={isMobile}>
      <ContentWrapper>
        <UpdatedTxt>Du har endringer som ikke er lagret</UpdatedTxt>
        <StyledButton
          action
          onClick={onSave}
        >
          Lagre
        </StyledButton>
        {/* 
        
        <Button secondary onClick={onCancel}>
          Avbryt
        </Button>
        */}
      </ContentWrapper>
    </Wrapper>,
    document.querySelector('#unsavedChangesBanner'),
  );
};

export default UnsavedChangesDisplay;
