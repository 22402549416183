import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';

const courseCatalogRightPadding = '0.5em';

export const tileGridWrapperstyle = css`
  position: relative;
  padding-bottom: 3em;
  padding-top: 3em;
  border-bottom: 1px solid #bfbfbf52;
  padding-right: ${courseCatalogRightPadding};
`;

export const tileGridWrapperstyleMobile = css`
  padding-top: 2em;
`;

export const courseCatalogPageElement = css`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 15px;
`;

export const CourseCatalogContentWrapper = styled.div`
  background: white;
  z-index: 1;
  height: 100%;

  ${props =>
  props.isMobile
    ? css``
    : css`
          padding-right: ${courseCatalogRightPadding};
        `}
`;
