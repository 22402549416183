import React from 'react';
import PropTypes from 'prop-types';

import '../styles/recommended-competences.scss';

const RecommendedCompetences = ({competences}) => (
  <div className="recommended-competences">
    <div className="recommended-competences__title">
      Recommended Competences
    </div>
    {Array.isArray(competences.data) && (
      <div className="recommended-competences__cards">
        {competences.data.map(({id, cover, title, description: subtitle}, index) => (
          <div
            className="recommended-competences__card-wrapper"
            key={`${id} ${index}`}
          >
            <div className="recommended-competences__card">
              <div
                className="recommended-competences__card-cover"
                style={{backgroundImage: cover ? `url(${cover.url})` : ''}}
              >
                {!(cover && cover.url) 
                    && <i className="fas fa-graduation-cap" />}
              </div>
              <div className="recommended-competences__card-description">
                <div className="recommended-competences__card-title">
                  {title}
                </div>
                <div className="recommended-competences__card-subtitle">
                  {subtitle}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="recommended-competences__card-wrapper" />
      </div>
    )
      || competences.isFetching 
        && <div className="recommended-competences__status">loading...</div>
       ||      competences.error && (
         <div className="recommended-competences__status">
           failed to load recommended competences
         </div>
       )}
  </div>
);

RecommendedCompetences.propTypes = {
  competences: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.shape({}),
  }).isRequired,
};

export default RecommendedCompetences;
