import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
  getProfile,
  getPassedCompetencesGroupedByType,
} from '@selectors/profile.selectors';

import {routerMyEducationMainViewDidMount} from '@actions/router.actions';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';

import '../styles/my-education-container.scss';

import PersonInfo from '../components/person-info';
import CompetencesList from '../components/competences-list';

class MyEducationCompetenceContainer extends Component {
  constructor(props) {
    super(props);

    this.redirectToProfileView = this.redirectToProfileView.bind(this);
  }

  componentDidMount() {
    const {routerMyEducationMainViewDidMount} = this.props;

    routerMyEducationMainViewDidMount();
  }

  redirectToProfileView() {
    const {history} = this.props;

    history.push(myEducationRoutes.profileView.path);
  }

  render() {
    const {profile, passedCompetencesGroupedByType} = this.props;

    return (
      <div className="my-education">
        <div className="my-education__section">
          {passedCompetencesGroupedByType.data && (
            <div className="competences-list-wrapper">
              <h2>Min gjennomførte opplæring</h2>
              <CompetencesList
                competencesGroupedByType={passedCompetencesGroupedByType.data}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

MyEducationCompetenceContainer.propTypes = {
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  passedCompetencesGroupedByType: PropTypes.shape({}).isRequired,
  requiredCompetences: PropTypes.shape({}).isRequired,
  routerMyEducationMainViewDidMount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  passedCompetencesGroupedByType: getPassedCompetencesGroupedByType(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {routerMyEducationMainViewDidMount},
    dispatch,
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MyEducationCompetenceContainer);
