/* eslint-disable no-debugger */
import React, {useState, useEffect} from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {css, jsx} from '@emotion/react';

const formatPoints = points => {
  const str = `${points}`;

  if (str.length < 4) {
    return str;
  }

  if (str.length === 4) {
    return `${str.slice(0, 1)},${str.slice(1)}`;
  }

  if (str.length === 5) {
    return `${str.slice(0, 2)},${str.slice(2)}`;
  }
  if (str.length === 6 || str.length > 6) {
    return `${str.slice(0, 3)},${str.slice(3)}`;
  }

  return str;
};

const QuizPointsCardLine = ({points}) => (
  <span
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    <span
      css={css`
        font-size: 0.6em;
      `}
    >
      Poengsum
    </span>
    <span
      css={css`
        font-weight: 500;
        margin-left: 1em;
        font-size: 0.9em;
      `}
    >
      {formatPoints(points)}
    </span>
  </span>
);

export default QuizPointsCardLine;
