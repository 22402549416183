import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {mq, or} from '@styles/facepaint';
import {highlightOnKeyboardFocusMixinExtra} from '@styles/mixins';
import {motion} from 'framer-motion';

const hidable = props =>
  props.hide
    ? css`
        visibility: hidden;
      `
    : '';

export const ContentWrapper = styled('div')(
  {
    height: '100%',
    width: '100%',
    minHeight: 200,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  props => ({
    borderBottomLeftRadius: props.variant === 'large' && 'inherit',
    borderBottomRightRadius: props.variant === 'large' && 'inherit',
  }),
);

export const HeaderWrapper = styled.header`
  ${props =>
  props.variant === 'large'
    ? css`
          margin-left: 1.3em;
          margin-right: 1em;
        `
    : ''}
  ${props =>
      props.variant === 'listCard'
        ? css`
          margin-left: 0;
        `
        : ''}
`;

export const AuthorName = styled('p')({
  marginLeft: 16,
  marginRight: 16,
  marginTop: '-0.5em',
  paddingBottom: '1.3em',
  color: '#5c5c5c',
});

export const Header = styled('h2')({
  p: {
    margin: '0',
    padding: '0',
  },
  [mq.small]: {
    fontSize: '1.25em',
    fontWeight: 500,
  },
  [mq.medium]: {
    fontSize: '1.5em',
    fontWeight: 500,
  },
  [mq.large]: {
    fontSize: '1.25em',
    fontWeight: 500,
  },
});

export const Category = styled('p')({
  color: 'red',
  margin: 0,
});

export const Image = styled('div')(
  {
    height: '100%',
    width: '100%',
    minHeight: 100,
    objectFit: 'cover',
    backgroundSize: 'cover',
    position: 'relative',
  },
  props => ({
    backgroundImage: `url(${props.src})`,
    borderTopLeftRadius: props.variant === 'large' && 'inherit',
    borderTopRightRadius: props.variant === 'large' && 'inherit',
  }),
);

export const AuthorAvatar = styled.img`
  position: absolute;
  left: 1em;
  bottom: 1em;
  border-radius: 50%;
  filter: grayscale(100%);

  /*These styles are overwritten by the carousel if not important*/
  width: 2.9em !important;
  height: 2.9em !important;
  border: 2px solid white !important;

  background-color: #8080808a;
  object-fit: cover;
  ${hidable};
`;

export const Wrapper = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'stretch',
  alignContent: 'stretch',
  position: 'relative',
  borderRadius: 4,
});
