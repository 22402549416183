const prefix = 'MESSAGES';

export const MESSAGES_GET_MESSAGES = `${prefix} GET MESSAGES`;
export const MESSAGES_GET_REQUEST = `${prefix} GET REQUEST`;
export const MESSAGES_GET_SUCCESS = `${prefix} GET SUCCESS`;
export const MESSAGES_GET_FAILURE = `${prefix} GET FAILURE`;

export const messagesGetMessages = () => ({type: MESSAGES_GET_MESSAGES});

export const messagesGetRequest = () => ({type: MESSAGES_GET_REQUEST});

export const messagesGetSuccess = payload => ({
  type: MESSAGES_GET_SUCCESS,
  payload,
});

export const messagesGetFailure = payload => ({
  type: MESSAGES_GET_FAILURE,
  payload,
});

export const MESSAGES_SEND_MESSAGE = `${prefix} SEND MESSAGE`;
export const MESSAGES_SEND_MESSAGE_POST_REQUEST = `${prefix} SEND MESSAGE POST REQUEST`;
export const MESSAGES_SEND_MESSAGE_POST_SUCCESS = `${prefix} SEND MESSAGE POST SUCCESS`;
export const MESSAGES_SEND_MESSAGE_POST_FAILURE = `${prefix} SEND MESSAGE POST FAILURE`;

export const messagesSendMessage = payload => ({
  type: MESSAGES_SEND_MESSAGE,
  payload,
});

export const messagesSendMessagePostRequest = () => ({type: MESSAGES_SEND_MESSAGE_POST_REQUEST});

export const messagesSendMessagePostSuccess = payload => ({
  type: MESSAGES_SEND_MESSAGE_POST_SUCCESS,
  payload,
});

export const messagesSendMessagePostFailure = payload => ({
  type: MESSAGES_SEND_MESSAGE_POST_FAILURE,
  payload,
});
