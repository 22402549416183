import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../styles/career-path.scss';

const CareerPath = ({competences}) => (
  <div className="career-path">
    <div className="career-path__time-periods">
      {[
        {
          status: 'passed',
          name: 'For Start',
        },
        {
          status: 'active',
          name: 'Forste Uken',
        },
        {
          status: 'locked',
          name: 'Forste 3 Mnd',
        },
        {
          status: 'locked',
          name: 'Forste 6 Mnd',
        },
      ].map((period, periodIndex) => (
        <div
          key={periodIndex}
          className={classNames({
            'career-path__time-period': true,
            'career-path__time-period--passed': period.status === 'passed',
            'career-path__time-period--active': period.status === 'active',
            'career-path__time-period--locked': period.status === 'locked',
          })}
        >
          <div className="career-path__time-period-number">
            {periodIndex + 1}
          </div>
          {period.name}
        </div>
      ))}
    </div>
    <div className="career-path__competences">
      {competences.map(competence => (
        <div
          key={competence.id}
          className="career-path__competence-wrapper"
        >
          <div
            className={classNames({
              'career-path__competence-status': true,
              'career-path__competence-status--passed':
                competence.status === 'PASSED',
              'career-path__competence-status--close-to-deadline':
                competence.status === 'CLOSE_TO_DEADLINE',
              'career-path__competence-status--after-deadline':
                competence.status === 'AFTER_DEADLINE',
            })}
          >
            {competence.status === 'PASSED' && <i className="fas fa-check" />}
          </div>
          {competence.status === 'PASSED'
            ? (
              <div className="career-path__passed-competence-card">
                {competence.title}
              </div>
            )
            : (
              <div
                key={competence.id}
                className="career-path__competence-card"
              >
                {competence.cover && (
                  <div
                    className="career-path__competence-cover"
                    style={{backgroundImage: `url(${competence.cover.url})`}}
                  />
                )}
                <div className="career-path__competence-content">
                  <div className="career-path__competence-title">
                    {competence.title}
                  </div>
                  <div className="career-path__competence-description">
                    {competence.description}
                  </div>
                  <div className="career-path__competence-actions">
                    <button
                      type="button"
                      className="btn"
                    >
                      Start kurs
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
      ))}
    </div>
  </div>
);

CareerPath.propTypes = {competences: PropTypes.arrayOf(PropTypes.shape({})).isRequired};

export default CareerPath;
