import React, {Component} from 'react';

import {withRouter} from 'react-router';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getActiveCourse} from '@selectors/courses.selectors';
import {getPassedIds} from '@selectors/profile.selectors';

import {Redirect} from 'react-router-dom';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import {getRouteWithParams} from '@utils/routes.utils';
import {bindActionCreators} from 'redux';

import {
  coursesCourseFinished,
  coursesSignCourse,
  coursesStartCourse,
  coursesBeginSignature,
} from '@actions/courses.actions';
import {cheatCompetence} from '@actions/profile.actions';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/pro-light-svg-icons';
import {i18n} from '@src/i18n';
import { ActionButton } from '@components/buttons/actionButton';

class CourseButtons extends Component {
  constructor(props) {
    super(props);
    this.onStartCourseClick = this.onStartCourseClick.bind(this);
    this.onEndCourseClick = this.onEndCourseClick.bind(this);
    this.onCheatClick = this.onCheatClick.bind(this);
  }

  state = {action: undefined};

  onEndCourseClick() {
    const {coursesCourseFinished, activeCourse} = this.props;

    coursesCourseFinished({cid: activeCourse.cid});
  }

  onCheatClick(id) {
    const {cheatCompetence} = this.props;

    cheatCompetence({id});
  }

  onStartCourseClick(cid, type) {
    const {coursesStartCourse} = this.props;

    coursesStartCourse({
      cid,
      type,
    });
  }

  render() {
    const {
      course,
      passedCoursesIds,
      activeCourse,
      coursesBeginSignature,
    } = this.props;
    const {action} = this.state;
    const isDone = passedCoursesIds.includes(Number.parseInt(course.id, 0));


    const ecourseTypeId = 10;
    const nanocourseTypeId = 24;

    console.log({course});

    return (
      <>
        {action && (
          <Redirect
            to={{
              pathname: getRouteWithParams(myEducationRoutes.courseView.path, {courseId: course.id}),
              state: {action},
            }}
          />
        )}
        {course.competence_type.competence_type_id === 19
          && (isDone && (
            <div className="course-preview__actions">
              <div className="btn course-preview__action-button course-preview__action-button--signed">
                <i className="fas fa-check" />
                {' '}
                Du har signert
              </div>
            </div>
          ) || (
            <div className="course-preview__actions">
              <button
                type="button"
                className="btn course-preview__action-button"
                onClick={() => {
                  coursesBeginSignature(course);
                }}
              >
                Signer
              </button>
            </div>
          ))}
        {[ecourseTypeId, nanocourseTypeId].includes(course.competence_type.competence_type_id) && (
          <div className="course-preview__actions">
            {false && (
              <button
                type="button"
                className="btn course-preview__action-button"
                onClick={() => {
                  this.onCheatClick(course.id);
                }}
              >
                CHEAT
              </button>
            )}
            {activeCourse
              && activeCourse.cid === course.competence_id
              && activeCourse.status === 0 && (
                <button
                  type="button"
                  className="btn course-preview__action-button"
                >
                  ....
                </button>
            ) || (
              <ActionButton
                type="button"
                variant="round"
                css={{paddingLeft:'1.3em'}}
                onClick={() => {
                  this.onStartCourseClick(
                    course.id,
                    course.competence_type.competence_type_id,
                  );
                }}
              >
                {isDone && i18n('course.restart') || i18n('course.start')}
                <FontAwesomeIcon icon={faArrowRight} />
              </ActionButton>
            )}
          </div>
        )}
      </>
    );
  }
}

CourseButtons.propTypes = {
  course: PropTypes.shape({}),
  activeCourse: PropTypes.shape({}),
  passedCoursesIds: PropTypes.shape({}).isRequired,
  coursesCourseFinished: PropTypes.func.isRequired,
  cheatCompetence: PropTypes.func.isRequired,
  coursesStartCourse: PropTypes.func.isRequired,
  coursesBeginSignature: PropTypes.func.isRequired,
};

CourseButtons.defaultProps = {
  course: null,
  activeCourse: null,
};

const mapStateToProps = state => ({
  passedCoursesIds: getPassedIds(state),
  activeCourse: getActiveCourse(state),
  signedCourse: state.courses.courseSignCourse,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      coursesStartCourse,
      coursesSignCourse,
      coursesCourseFinished,
      cheatCompetence,
      coursesBeginSignature,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseButtons));
