import {uniqueId} from 'lodash';

const prefix = '[notifications]';

export const NOTIFICATIONS_ADD = `${prefix} notifications add`;
export const NOTIFICATIONS_SHOW = `${prefix} notifications show`;
export const NOTIFICATIONS_HIDE = `${prefix} notifications hide`;

export const notificationsAdd = payload => ({
  type: NOTIFICATIONS_ADD,
  payload: {
    ...payload,
    notification: {
      ...payload.notification,
      id: payload.notification.id || uniqueId(),
    },
  },
});

export const notificationsShow = payload => ({
  type: NOTIFICATIONS_SHOW,
  payload,
});

export const notificationsHide = payload => ({
  type: NOTIFICATIONS_HIDE,
  payload,
});
