import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {mq, space, textScale} from '@facepaint';

import {MapBounding} from '@routes/atlas/components/MapBounding/MapBounding';

export const textScaling = css({
  fontSize: textScale[0],
  [mq.small]: {fontSize: textScale[1]},
  [mq.medium]: {fontSize: textScale[2]},
  [mq.large]: {fontSize: textScale[3]},
});

export const MapBoundingExtended = styled(MapBounding)();

export const Background = styled('div')(
  {
    fontSize: textScale[0],
    borderRadius: 0,
    border: 'none',
    minWidth: 320,
    height: '100%',
    maxHeight: '100%',
    [mq.small]: {fontSize: textScale[1]},
  },
  ({isMobile}) =>
    !isMobile && {
      fontSize: textScale[2],
      border: '0.5px solid black',
      borderRadius: 16,
      height: '100%',
      width: '100%',
    },
  ({isMobile}) =>
    isMobile && {overflow: 'hidden'},
  ({backgroundImage, backgrondPosition}) =>
    backgroundImage && {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: backgrondPosition,
    },
  {[mq.large]: {fontSize: textScale[3]}},
);

export const wrapper = css({
  paddingTop: '1em',
  paddingBottom: '1em',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '700px',
  margin: '2em',
  marginBottom: '1.2em',
});

export const base = css({
  position: 'relative',

  display: 'flex',
  flexDirection: 'column',
  width: '21em',
  justifyContent: 'center',
});

export const baseMapUI = css({
  height: `${space[1]}em`,
  display: '0 1 auto',
  flexDirection: 'row',
});
