import React, {Component, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, change} from 'redux-form';
import {debounce} from 'throttle-debounce';

import FormSelect from '@components/form-select';
import {getOrganisationId} from '@selectors/profile.selectors';
import SearchBar from '@routes/employees/components/search-bar';

import './person-search-add-form.scss';
import '@routes/employees/styles/employees-list-container.scss';

import axios from 'axios';
import {backendUrl} from '@config';
import PersonAvatar from '@components/person-avatar/person-avatar';
import {i18n} from '@src/i18n';

const PersonSelect = connect(state => ({orgId: getOrganisationId(state)}))(({dispatch, orgId, input, meta, ...props}) => {
  const [employees, setEmployees] = useState({
    isFetching: false,
    list: [],
  });
  const [searchPhrase, setSearchPhrase] = useState('');
  const [selectedPerson, setSelectedPerson] = useState(null);

  useEffect(() => {
    setEmployees({
      isFetching: false,
      list: [],
    });
    setSearchPhrase('');
    setSelectedPerson(null);
    dispatch(change('personsearchadd', input.name, ''));
  }, []);

  const onSearch = query => {
    setSearchPhrase(query);

    if (selectedPerson && query !== selectedPerson.fullname) {
      setSelectedPerson(null);
      setEmployees({
        list: [],
        isFetching: false,
      });
      dispatch(change('personsearchadd', input.name, ''));
    }

    if (query.length <= 3) {
      setEmployees({
        list: [],
        isFetching: false,
      });

      return;
    }

    const handleSearchEmployee = debounce(800, query => {
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/search?items=persons&mode=partial&outside=1&fields=user_name,email,phone,mobile,fullname,firstname,lastname,profile_image`,
          params: {query},
          withCredentials: true,
        })
        .then(({data: {persons}}) =>
          setEmployees({
            list: persons,
            isFetching: false,
          }));
    });

    setEmployees({
      ...employees,
      isFetching: true,
    });
    handleSearchEmployee(query);
  };

  const onSelectEmployee = person => {
    setSelectedPerson(person);
    setSearchPhrase(person.fullname);
    dispatch(change('personsearchadd', input.name, person.id, true));
  };

  return (
    <div className="person-select">
      <SearchBar
        name={`${input.name}__search-bar`}
        value={searchPhrase}
        selectedPerson={selectedPerson}
        loading={employees.isFetching}
        onChange={({target: {value}}) => {
          onSearch(value);
        }}
        autoFocus
      >
        {!selectedPerson
          && (searchPhrase.length > 3
            && !employees.isFetching
            && !employees.list.length && (
              <div className="search-bar__dropdown__wrapper person-search-add-form__dropdown-wrapper">
                <div className="search-bar__dropdown">
                  <div className="search-bar__dropdown__item">
                    Fant ingen resultater
                  </div>
                </div>
              </div>
          )
            || !!searchPhrase.length && searchPhrase.length <= 3 && (
              <div className="search-bar__dropdown__wrapper person-search-add-form__dropdown-wrapper">
                <div className="search-bar__dropdown">
                  <div className="search-bar__dropdown__item">
                    {i18n('employees.min-letters', {functionArgs:{x: 4}})}
                  </div>
                </div>
              </div>
            )
            || employees.list && !!employees.list.length && (
              <div className="search-bar__dropdown__wrapper person-search-add-form__dropdown-wrapper">
                <div className="search-bar__dropdown">
                  {employees.list.map(person =>
                    person && (
                      <div
                        className="search-bar__dropdown__item item-link"
                        onClick={() => onSelectEmployee(person)}
                        onKeyPress={() => onSelectEmployee(person)}
                        role="button"
                        tabIndex="0"
                      >
                        <div className="search-bar__dropdown__avatar">
                          <PersonAvatar
                            size="32px"
                            fontSize="18px"
                            person={person}
                          />
                        </div>
                        {person.fullname}
                      </div>
                    ))}
                </div>
              </div>
            )
            || employees.isFetching && (
              <div className="search-bar__dropdown__wrapper person-search-add-form__dropdown-wrapper">
                <div className="search-bar__dropdown">
                  <div className="search-bar__dropdown__item">Søker ...</div>
                </div>
              </div>
            ))}
      </SearchBar>
      <input
        value={selectedPerson && selectedPerson.id || ''}
        {...props}
        {...input}
        type="hidden"
      />
      {meta.touched && meta.error
        && <div className="form-input__error">{meta.error}</div>}
    </div>
  );
});

class PersonSearchAdd extends Component {
  state = {
    verifications: {
      isFetching: true,
      data: null,
    },
    positions: {
      isFetching: true,
      data: null,
    },
  };

  componentDidMount() {
    const {personId, orgId} = this.props;

    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/roles?limit=1000&organisation_ids=${orgId}`,
        params: {role_meta_types: 'position'},
        withCredentials: true,
      })
      .then(({data}) => {
        this.setState({
          positions: {
            isFetching: false,
            data: data.roles,
          },
        });
      });

    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/persons/verify/${personId}/${orgId}?format=json`,
        params: {role_meta_types: 'position,role'},
        withCredentials: true,
      })
      .then(({data}) => {
        this.setState({
          verifications: {
            isFetching: false,
            data,
          },
        });
      });
  }

  handleRolesOnChange = ({values}) => {
    const {change} = this.props;

    change('roles', values);
  };

  validatePersonSelect = person => !person && 'Du må velge en person' || undefined;

  render() {
    const {handleSubmit, onCancel} = this.props;
    const {
      verifications: {isFetching: isRolesFetching},
      positions,
    } = this.state;

    let user_positions = [];

    if (!positions.isFetching) {
      const select_value = [{
        title: '--- Velg stilling ---',
        id: '',
      }];

      user_positions = select_value.concat(positions.data);
    }

    const dataVerifications = [
      {
        title: 'Legg til',
        id: 1,
      },
      {
        title: 'Flytt',
        id: 2,
      },
    ];

    return (
      <div className="grid-x">
        {!isRolesFetching && (
          <form
            onSubmit={handleSubmit}
            className="person-search-add-form"
          >
            <div className="grid-x">
              <div className="small-12 medium-12 cell">
                <Field
                  component={PersonSelect}
                  name="employee"
                  placeholder="Søk ansatt..."
                  validate={[this.validatePersonSelect]}
                />
              </div>
              <div className="small-12 medium-12 cell">
                <Field
                  component={FormSelect}
                  options={user_positions}
                  className="employee-add__input"
                  name="position"
                  label="Stilling"
                />
              </div>
              <div className="small-12 medium-12 cell">
                <Field
                  component={FormSelect}
                  defaultValue=""
                  options={dataVerifications}
                  className="employee-add__input"
                  name="operation"
                  width="100%"
                  label="Valg"
                  type="select"
                />
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

PersonSearchAdd.propTypes = {
  personId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({orgId: getOrganisationId(state)});

export default connect(mapStateToProps)(reduxForm({form: 'personsearchadd'})(PersonSearchAdd));
