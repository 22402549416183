import PropTypes from 'prop-types';
import * as T from '@types/load.types';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {i18n} from '@src/i18n/index';
import TreeView from '@components/tree-view/tree-view';
import {AnimatePresence} from 'framer-motion';

const OrgansationHeader = ({
  mainOrganisation,
  updateActiveOrg,
}) => {
  const node = useRef();
  const [selectSub, openSub] = useState(false);
  const [showTree, openTree] = useState(false);

  const handleClick = useCallback(e => {
    if (selectSub) {
      if (node.current && node.current.contains(e.target)) {
        // inside click
        return;
      }
      openSub(false);
    }
  }, [selectSub]);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return (
    <>
      <div className="organisation-header-wrapper" >
        {mainOrganisation.status === T.LoadStatuses.LOADED && (
          <div className="organisation-header">
            {mainOrganisation.data.children.length !== 0 && (
              <div className="organisation-drop">
                <h1
                  role="button"
                  className={(selectSub ? 'open' : '')}
                  onClick={() => openSub(!selectSub)}
                  onKeyPress={({key}) => {
                    if(key === 'Enter') {
                      openSub(!selectSub);
                    }
                  }}
                  tabIndex="0"
                >
                  {mainOrganisation.data.title}
                  <div className="open-sub">
                    <FontAwesomeIcon
                      icon="caret-down"
                      style={{
                        fontSize: 14,
                        color: '#666',
                      }}
                    />
                  </div>
                </h1>
              </div>
            ) || (
              <div className="organisation-drop">
                <h1>
                  {mainOrganisation.data.title}
                </h1>
              </div>
            )}
            {mainOrganisation.data.children.length !== 0 && (
              <div
                className="open-tree"
                onClick={() => openTree(!showTree)}
              >
                <FontAwesomeIcon icon="sitemap" /> 
                {' '}
                {i18n('employees.show-tree')}
              </div>
            )}
            {mainOrganisation.data.children.length !== 0 && selectSub && (
              <div
                className="sub-menu"
                ref={node}
              >
                <ul>
                  {mainOrganisation.data.children.map(org => (
                    <li
                      role="button"
                      onClick={() => {
                        openSub(false);
                        updateActiveOrg(org.organisation_id);
                      }}
                      onKeyPress={({key}) => {
                        if (key === 'Enter') {
                          openSub(false);
                          updateActiveOrg(org.organisation_id);
                        }
                      }}
                      tabIndex="0"
                    >
                      {org.title}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ) 
          || <h1>...</h1>}
      </div>
      <AnimatePresence>
        {mainOrganisation.status === T.LoadStatuses.LOADED && showTree && (
          <div className="fullwidth organisation-treeview-wrapper">
            <TreeView
              openNode={id => {
                updateActiveOrg(id);
                openTree(false);
              }}
              orgId={mainOrganisation.data.organisation_id}
            />
          </div>
        )}
      </AnimatePresence>
    </>
  );
};


OrgansationHeader.propTypes = {mainOrganisation: PropTypes.shape({}).isRequired};


export default OrgansationHeader;
