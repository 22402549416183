import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import './action-bar.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {employees as routes} from '@routes/routes.manifest';
import {backendUrl} from '@config';
import {ActionButton} from '@components/buttons/actionButton';

class ActionBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      type: props.type,
    };
  }

  render() {
    const {
      history: {push},
      orgId,
      searchEmployee,
    } = this.props;

    const {open, type} = this.state;

    return (
      <div className="action-bar">
        {type === 'employee-bar' && (
          <ActionButton
            role="button"
            style={{paddingRight: 60}}
            onClick={() => this.setState({open: !open})}
            onKeyPress={() => this.setState({open: !open})}
            onBlur={() => this.setState({open: false})}
          >
            Oppgaver
            {open && (
              <i
                className="fas fa-chevron-up"
                css={{
                  position:'absolute',
                  right:'0.5em',
                  marginRight: 15,
                }}
              />
            )
              || (
                <i
                  className="fas fa-chevron-down"
                  css={{
                    position:'absolute',
                    right:'0.5em',
                    paddingRight: 15,
                  }}
                />
              )}
          </ActionButton>
        )}

        {open && (
          <div className="action-bar-items">
            <div
              className="item"
              role="button"
              tabIndex={0}
              onMouseDown={() => {
                push(routes.addEmployee);
                this.setState({open: false});
              }}
              onKeyPress={() => {
                push(routes.addEmployee);
                this.setState({open: false});
              }}
            >
              <FontAwesomeIcon icon="plus" />
              Legg til ansatt
            </div>
            <div
              className="item"
              role="button"
              tabIndex={0}
              onMouseDown={() => {
                searchEmployee();
                this.setState({open: false});
              }}
              onKeyPress={() => {
                searchEmployee();
                this.setState({open: false});
              }}
            >
              <FontAwesomeIcon icon="plus" />
              Flytt / Hent ansatt
            </div>
            <div
              className="item"
              target="_blank"
              role="button"
              tabIndex="0"
              onKeyPress={() => {
                window.location = `${backendUrl}/organisations/personstoexcel/${orgId}`;
                this.setState({open: false});
              }}
              onMouseDown={() => {
                window.location = `${backendUrl}/organisations/personstoexcel/${orgId}`;
                this.setState({open: false});
              }}
            >
              <FontAwesomeIcon icon="file-excel" />
              Eksporter til Excel
            </div>
          </div>
        )}
      </div>
    );
  }
}

ActionBar.propTypes = {
  history: PropTypes.shape({push: PropTypes.func.isRequired}).isRequired,

  searchEmployee: PropTypes.func,
  type: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
};

ActionBar.defaultProps = {searchEmployee: null};

export default withRouter(ActionBar);
