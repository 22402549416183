import React from 'react';
import PropTypes from 'prop-types';

import {Description} from './styles';

export const CourseInformationDescription = ({children}) => (
  <Description className="course-information-description">
    {children}
  </Description>
);

CourseInformationDescription.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
};
