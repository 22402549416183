import * as configActions from '@actions/config.actions';

const initialState = {
  config: {
    isFetching: false,
    data: null,
    error: null,
  },
};

const config = (state = initialState, action) => {
  switch (action.type) {
  case configActions.CONFIG_GET_CONFIG_REQUEST:
    return {
      ...state,
      config: {
        ...initialState.config,
        isFetching: true,
      },
    };
  case configActions.CONFIG_GET_CONFIG_SUCCESS:
    return {
      ...state,
      config: {
        ...initialState.config,
        data: action.payload.config,
      },
    };
  case configActions.CONFIG_GET_CONFIG_FAILURE:
    return {
      ...state,
      config: {
        ...initialState.config,
        error: action.payload.error,
      },
    };
  case 'RESET_STORE':
    return initialState;
  default:
    return state;
  }
};

export default config;
