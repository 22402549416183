/* eslint-disable react/prop-types */
import styled from '@emotion/styled';

export const Container = styled('div')({
  label: 'Container',
  height: '5em',
  display: 'flex',
  flexDirection: 'row',
  flex: '0 1 auto',
});

export const Path = styled('div')({
  label: 'Path',
  width: '2.5em',
  flex: '0 0 auto',
  height: '100%',
  position: 'relative',
});

export const Stack = styled('div')({
  label: 'Stack',
  display: 'flex',
  flexDirection: 'column',
});

const lineVariants = props => {
  const common = {
    width: '0.35em',
    margin: '0 auto',
  };

  switch (props.variant) {
  case 'top':
    return {
      ...common,
      label: 'Line-top',
      height: '2.25em',
    };
  case 'bottom':
    return {
      ...common,
      label: 'Line-bottom',
      height: '2.75em',
    };
  default:
    return null;
  }
};

export const Line = styled('div')(props => ({
  ...{backgroundColor: props.completed ? '#21CE6C' : '#F2F2F2'},
  ...lineVariants(props),
}));

const circleVariants = props => {
  const common = {
    width: '0.5em',
    height: '1em',
    top: '35%',
    left: '50%',
  };

  switch (props.variant) {
  case 'left':
    return {
      ...common,
      label: 'Circle-left',
      borderBottomLeftRadius: 9999,
      borderTopLeftRadius: 9999,
      marginLeft: '-0.4em',
    };

  case 'right':
    return {
      ...common,
      label: 'Circle-right',
      borderBottomRightRadius: 9999,
      borderTopRightRadius: 9999,
      marginLeft: '0.4em',
    };

  default:
    return {
      label: 'Circle',
      width: '1.25em',
      height: '1.25em',
      borderRadius: 9999,
      top: '40%',
      left: '50%',
    };
  }
};

export const Circle = styled('div')(
  {
    label: 'Circle',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    transition: 'background 0.2s ease-out',
  },
  props => ({
    ...{backgroundColor: props.completed ? '#21CE6C' : '#F2F2F2'},
    ...circleVariants(props),
  }),
);

const emptyVariant = props => {
  const common = {
    width: '0.5em',
    margin: '0 auto',
  };

  switch (props.variant) {
  case 'top':
    return {
      ...common,
      label: 'Empty-top',
      height: '2.25em',
    };
  case 'bottom':
    return {
      ...common,
      label: 'Empty-bottom',
      height: '2.75em',
    };

  default:
    return {flex: '1 1 50%'};
  }
};

export const Empty = styled('div')(props => emptyVariant(props));

export const lineStyle = {
  position: 'absolute',
  top: '-0.25em',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: '#F2F2F2',
  width: '0.35em',
  margin: '0 auto',
};
