import {jsx, css} from '@emotion/react';
import styled from '@emotion/styled';

export const Box = styled('div')({
  backgroundColor: '#fafafa',
  border: '1px solid #EAEAEA',
  borderRadius: 8,
  paddingTop: 24,
  paddingBottom: 24,
  paddingLeft: 16,
  paddingRight: 16,
  marginTop: 32,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const p = css({
  textAlign: 'center',
  color: '#707070',
  'p > span': {fontWeight: 'bold'},
});
