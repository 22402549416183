import {i18n} from '@src/i18n';
import {CheckCircle, MinusCircle, WarningCircle} from 'phosphor-react';
import React, {useState} from 'react';
import {css, jsx} from '@emotion/react';

import {Header, Wrapper, PartipicantsWrapper, approveDotStyle, Action, Partipicant, ActionName, signOffDotStyle, ActionsWrapper, signedUpDotStyle, Name, onWaitlistDotStyle} from './course-event-partipicant-list-styles';
import Modal from '@components/modal/modal';
import {courseSignOff} from '@actions/courses.actions';
import {useDispatch} from 'react-redux';

export const EventPartipicantsList = ({myEmployeesEvents, myEmployeesEventsWaitlist=[],  mode}) => {
  console.log({myEmployeesEvents});

  const [employeeForConfirmSignOff, setEmployeeForConfirmSignOff] = useState(null);

  const allEvents = [...myEmployeesEvents.map(e => ({
    ...e,
    waitlist: false,
  })), ...myEmployeesEventsWaitlist.map(e => ({
    ...e,
    waitlist: true,
  }))].sort((a,b)=> a.person.fullname.localeCompare(b.person.fullname));

  const dispatch = useDispatch();


  const partipicantsToShow = {
    'signed-up': allEvents,
    'waiting-for-confirmation': myEmployeesEvents.filter(e => !e.confirmed),
  }[mode];

  const handleSignOffEvent = () => {
    dispatch(courseSignOff({
      courseEventId: myEmployeesEvents[0].id,
      employees: [employeeForConfirmSignOff],
      onSuccess: setEmployeeForConfirmSignOff(null),
    }));
  };

  const getPartipicantEnrolmentState = person => {
    if(mode === 'waiting-for-confirmation') {
      return null;
    }

    return 'signed-up';
  };
  

  return (
    <Wrapper>
      {employeeForConfirmSignOff
            && (
              <Modal
                variant="warning"
                onClose={() => setEmployeeForConfirmSignOff(null)}
                onCancel={() => setEmployeeForConfirmSignOff(null)}
                onConfirm={handleSignOffEvent}
                title={() => (
                  <Modal.Title>
                    {i18n('course.confirm-sign-off')}
                  </Modal.Title>
                )}
                submitButtonText={i18n('course.sign-off')}
              >
                {i18n('course.sign-off-person-x-question', {functionArgs: {x:employeeForConfirmSignOff.fullname}})}
              </Modal>
            )}
            
      <Header>
        {{
          'signed-up': i18n('course.employees-signed-up'),
          'waiting-for-confirmation': i18n('course.employees-waiting-for-confirmation'),
        }[mode]}
      </Header>

      <PartipicantsWrapper>
        {partipicantsToShow.map(p => (
          <Partipicant>
            {{
              'signed-up': <CheckCircle
                css={signedUpDotStyle}
                weight="fill"
              />,
              'on-waitlist': <WarningCircle
                css={onWaitlistDotStyle}
                weight="fill"
              />,
            }[getPartipicantEnrolmentState(p)]}
            <Name>
              {p.person.fullname}
            </Name>

            <ActionsWrapper>
            
              {['signed-up', 'waiting-for-confirmation'].includes(mode) 
              && (
                <Action
                  role="button"
                  tabIndex="0"
                  onClick={() => setEmployeeForConfirmSignOff(p.person)}
                >
                  <MinusCircle
                    css={signOffDotStyle}
                    weight="fill"
                    aria-hidden="true"
                  />

                  <ActionName>
                    {i18n('course.sign-off')}
                  </ActionName>
                </Action>
              )}

              {['waiting-for-confirmation'].includes(mode) 
              && (
                <Action>
                  <MinusCircle
                    css={approveDotStyle}
                    weight="fill"
                    aria-hidden="true"
                  />

                  <ActionName>
                    {i18n('globals.approve')}
                  </ActionName>
                </Action>
              )}
            </ActionsWrapper>
          </Partipicant>
        ))}
      </PartipicantsWrapper>
    </Wrapper>
  );
};