import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {mq, or} from '@styles/facepaint';
import {motion} from 'framer-motion';



export const DateTd = styled.td(props => ({
  textAlign: 'center',
  fontWeight: 600,
  fontSize: 12,
  width: '11.28%',
  color: '#0c0c0c',
  color: props.disabled && '#7e7e7e',
  backgroundColor: props.today && '#efefef',
  borderWidth: (props.disabled ? 0 : 1),
  padding: props.today ? 4 : 6,
  borderStyle: 'solid',
  borderRadius: props.today ? 70 : 4,
  borderColor: '#e7e7e7',
}));


export const Header = styled.div`
  width: 100%;
  margin-bottom: 4px;
  display: flex;
  flex: 1 1 auto;
  color: #535353;
`;


export const Player = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
