import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';

const ProgressbarChart = ({
  width,
  height,
  data: inputData,
  colors,
  strokeColors,
  total: totalProp,
  valueColor,
  labelColor,
  font,
  onLayoutHeight,
}) => {
  const data = [...inputData].sort(({value: v1}, {value: v2}) => v2 - v1);
  const total
    = totalProp || Math.max(...data.map(({value}) => value) || [0]);

  const barWidth = 20;

  const labelRefs = useRef([]);
  const [labelSizes, setLabelSizes] = useState([]);
  const [labelHeights, setLabelHeights] = useState([]);

  const padding = {
    left:
      5
      + (labelSizes[data.length - 1]
        && labelSizes[data.length - 1][0].width / 2
        || 0),
    right:
      5
      + Math.max(
        ...labelSizes.map(labelSize =>
          labelSize
              && Math.max(
                labelSize[0].width / 2,
                labelSize.maxWidth - labelSize[0].width / 2,
              )
            || 0) || [],
        0,
      ),
    bottom: 1,
    top: 0,
  };

  useEffect(() => {
    setLabelSizes(labelRefs.current.map(textLabels => ({
      ...textLabels.reduce(
        (labels, label, key) => ({
          ...labels,
          [key]: label.getBoundingClientRect(),
        }),
        {},
      ),
      maxWidth: textLabels.reduce((max, textLabel) => {
        if (textLabel) {
          const {width} = textLabel.getBoundingClientRect();

          return Math.max(max, width);
        }

        return max;
      }, 0),
    })));
  }, [width, height]);

  useEffect(() => {
    const labelExtents = labelSizes.map(labelSize => ({
      lower: labelSize[0].x - labelSize[0].width / 2,
      upper:
        labelSize[0].x
        + Math.max(
          labelSize[0].width / 2,
          labelSize.maxWidth - labelSize[0].width / 2,
        ),
      y: 0,
    }));

    if (!labelExtents.length) {
      return;
    }

    const upper = [];

    for (let i = labelExtents.length - 1; i >= 0; i -= 1) {
      for (let j = 0; j < labelExtents.length; j += 1) {
        if (!upper[j] || labelExtents[i].lower > upper[j]) {
          upper[j] = labelExtents[i].upper;
          labelExtents[i].y = j;
          break;
        }
      }
    }

    setLabelHeights(labelExtents.map(x => x.y));
    if (onLayoutHeight) {
      onLayoutHeight(Math.max(...labelExtents.map(x => x.y), 0) * 80 + 130); // TODO: Tweak value to fit graph just right.
    }
  }, [labelSizes, onLayoutHeight]);

  const setLabelRef = (index, labelIndex) => label => {
    labelRefs.current[index] = labelRefs.current[index] || [];
    labelRefs.current[index][labelIndex] = label;
  };

  return (
    <svg
      width={width}
      height={height}
      role="img"
    >
      {data.map(({label, value, color, strokeColor}, index) => {
        const x
          = padding.left
          + (width - padding.left - padding.right) * value / total;
        const y = height - barWidth - padding.bottom;
        const labelY = y - 70;
        const labelOffset = (labelHeights[index] || 0) * 80;

        return (
          <React.Fragment key={index}>
            <path
              d={`M ${x} ${y + 3} v ${-32 - labelOffset}`}
              stroke={valueColor}
            />
            <circle
              cx={x}
              cy={y - 30 - labelOffset}
              r={5}
              fill={valueColor}
            />
            <rect
              x={padding.left}
              y={y}
              rx={3}
              ry={3}
              width={(width - padding.left - padding.right) * value / total}
              height={barWidth}
              fill={color || colors[data.length - index - 1]}
              stroke={strokeColor || strokeColors[data.length - index - 1]}
              strokeWidth={1}
            />
            <text
              ref={setLabelRef(index, 0)}
              x={
                x - (labelSizes[index] && labelSizes[index][0].width || 0) / 2
              }
              y={labelY - 12 - labelOffset}
              fill={valueColor}
              fontWeight="bold"
              dominantBaseline="middle"
              fontSize={32}
              fontFamily={font}
            >
              {value}
            </text>
            <text
              ref={setLabelRef(index, 1)}
              x={
                x - (labelSizes[index] && labelSizes[index][0].width || 0) / 2
              }
              y={labelY + 16 - labelOffset}
              fill={labelColor}
              fontWeight="bold"
              dominantBaseline="middle"
              fontSize={14}
              fontFamily={font}
            >
              {label}
            </text>
          </React.Fragment>
        );
      })}
    </svg>
  );
};

ProgressbarChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  strokeColors: PropTypes.arrayOf(PropTypes.string),
  valueColor: PropTypes.string,
  labelColor: PropTypes.string,
  font: PropTypes.string,
  onLayoutHeight: PropTypes.func,
  total: PropTypes.number,
};

ProgressbarChart.defaultProps = {
  width: undefined,
  height: undefined,
  colors: ['#69c7cf', '#a1d6db', '#ffffff'],
  strokeColors: ['#92c7cc', '#93c8cd', '#d3cfcf'],
  valueColor: '#69c7cf',
  labelColor: '#6a6868',
  font: undefined,
  onLayoutHeight: undefined,
  total: undefined,
};

export default ProgressbarChart;
