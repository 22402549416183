import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';

export const PersonInformationLoadingCont = styled.div`
font-size: 1.5em;
position: relative;
top: 34%;
transform: translate(0, -50%);
text-align: center;
`;

export const NoDataSection = styled.div`
    display: flex;
    height: 500px;
`;

export const ErrorIcon = styled.i`
font-size: 1.5em;
font-size: 2em;
margin-right: 5px;
position: relative;
top: 9px;
`;

export const LoadingWrapper = styled.div`
font-size: 1.5em;
position: relative;
top: 34%;
transform: translate(0, -50%);
text-align: center;
`;

export const MainHeader = styled.h1`
  font-weight: 600;
  font-size: 2rem;
    margin-top: 0;
    margin-bottom: 1.2rem;
`;


export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
  @media screen and (max-width: 650px) {
    padding: 0 10px;
  }

  ${({mode}) => css`
    background: ${mode === 'view' ? '#444444'  : '#F5F5F7'};
  `}
`;


export const ContentWrapper = styled.div`
  display: flex;
  width: 70%;
  background: white;
  margin: auto;
  flex-direction: column;
  border-radius: 16px 16px 0px 0px;
  padding: 2.8rem;
  padding-bottom: 15em;
`;

export const TopBar = styled.div`
  display: flex;
  width: 70%;
  margin: auto;
  margin-bottom: 1.7rem;
  margin-top: 6em;
  align-items: center;
  margin-bottom: 2.3rem;
`;

export const Seperator = styled.div`
  display: flex;
  flex: 0 0 auto;
  background: #eaebed;
  width: 100%;
  height: 1px;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
`;

export const TopDescription = styled.p`
  color: white;
    margin: 0;
    font-weight: 500;
    margin-left: 1.1rem;
`;