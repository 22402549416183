import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {withRouter, matchPath, Switch, Route} from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';

import {courseCatalog as courseCatalogRoutes} from '@routes/routes.manifest';

import {getRoutesBreadcrumbsArray} from '@utils/routes.utils';

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';

import './course-catalog-switch.scss';

import CourseCatalogContainer from './containers/course-catalog/course-catalog-container';
import CoursePreviewContainer from './containers/course-preview/course-preview-container';

const CourseCatalogSwitch = ({location, breadcrumbs}) => (
  <div className="course-catalog-switch">
    <div
      className={`course-catalog-switch__section ${
        location && `route_${location.pathname.slice(1)}`
      }`}
    >
      <Switch>
        <Route
          path={courseCatalogRoutes.main.path}
          exact
          component={CourseCatalogContainer}
        />
        <Route
          path={courseCatalogRoutes.category.path}
          component={CourseCatalogContainer}
        />
        <Route
          path={courseCatalogRoutes.coursePreview.path}
          component={CoursePreviewContainer}
        />
      </Switch>
    </div>
  </div>
);

CourseCatalogSwitch.propTypes = {
  location: PropTypes.shape({pathname: PropTypes.string.isRequired}).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default compose(
  withRouter,
  withBreadcrumbs(getRoutesBreadcrumbsArray(courseCatalogRoutes), {disableDefaults: true}),
)(CourseCatalogSwitch);
