import * as themeActions from '@actions/theme.actions';

import {getDefaultTheme} from '@utils/themes.utils';

const initialState = {...getDefaultTheme()};

const theme = (state = initialState, action) => {
  switch (action.type) {
  case themeActions.SET_THEME:
    return {
      ...state,
      ...action.payload.theme,
    };
  default:
    return state;
  }
};

export default theme;
