import {
  all,
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  delay,
} from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import Cookies from 'js-cookie';
import axios from 'axios';
import qs from 'qs';

import { backendUrl } from '@config';

import {
  authLoginRequest,
  authLoginSuccess,
  authLoginFailure,
  authLogoutRequest,
  authLogoutSuccess,
  authUnauthorized,
  authGotoNext,
  authGotoOn,
  authLoginNewPasswordSuccess,
  authLoginNewPasswordFailure,
  authVerifyUser,
  authVerifyUserSuccess,
  authVerifyUserFailure,
  authCheckIfLoggedIn,
  authGetAuthStatusAndStartLoading,
  authUserIsConfirmedLoggedIn,
  authUserIsConfirmedNotLoggedIn,
  authStartLoginCheckOnInterval,
  authLoginNewPasswordRequest,
} from '@actions/auth.actions';

import { getConfig, loadOrGetConfig } from '@sagas/config.sagas';

import { getAuthStatus, getSecondData } from '../selectors/auth.selectors';
import { getConfigObject } from '../selectors/config.selectors';

function* gotoNext(action) {
  if (action.payload === 'grape') {
    const secondData = yield select(getSecondData);
    const { username, password } = secondData;

    yield call(
      () =>
        axios
          .request({
            method: 'POST',
            url: 'https://coop.snappergrape.no/api/login',
            data: qs.stringify({
              password,
              user_name: username,
              login: 1,
            }),
            withCredentials: true,
          })
          .then(({ data }) => data),
      {
        username,
        password,
      }
    );
    yield put(authGotoOn());
    window.location = 'https://coop.snappergrape.no';
  } else {
    yield put(authGotoOn());
    window.location.reload();
  }
}

function* login(action) {
  const { username, password } = action.payload;
  try {
    const { login, valid } = yield call(
      ({ username: user_name, password }) =>
        axios
          .request({
            method: 'POST',
            url: `${backendUrl}/api/login`,
            data: qs.stringify({
              password,
              user_name,
              login: 1,
            }),
            withCredentials: true,
          })
          .then(({ data }) => data),
      {
        username,
        password,
      }
    );

    if (!valid) {
      Cookies.remove('tg-visit');
      throw new Error('Invalid user');
    } else {
      const { session_id } = login;

      Cookies.set('tg-visit', session_id);
      yield put(authUserIsConfirmedLoggedIn());
    }

    Cookies.set('identity_login_attempted', 0);

    const {
      login: { start_url },
    } = { login };

    localStorage.removeItem('orgId');
    yield put({ type: 'RESET_STORE' });

    // when config is loaded, the rest of the loading will continie
    yield call(getConfig);
    const configObject = yield select(getConfigObject);

    const [positions, roles] = yield all([
      call(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/positions`,
            params: {
              fields: 'id,title',
              user_name: username,
            },
            withCredentials: true,
          })
          .then(({ data: { positions } }) => positions)
      ),
      call(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/roles`,
            params: {
              fields: 'title',
              user_name: username,
            },
            withCredentials: true,
          })
          .then(({ data: { roles } }) => roles)
      ),
    ]);

    /*
     * CHECK IF USE SHOULD BE HERE OR IN GRAPE.
     * */
    const grape_names = new Set([
      'Administrasjon',
      'Administrerende direktør',
      'Daglig leder',
      'Direktør',
      'Disponent',
      'Varehussjef',
      'Distriktssjef',
      'Salgsleder',
      'Driftsleder',
      'Driftssjef',
      'Hovedkontor - leder',
      'Hovedkontor - medarbeider',
      'Leder',
      'Regionssjef',
    ]);

    let hasChoice = false;

    if (Array.isArray(positions)) {
      const pos = positions.some((p) => grape_names.has(p.title));

      hasChoice = pos;
    }

    if (!hasChoice && Array.isArray(roles)) {
      const pos = roles.some((p) => grape_names.has(p.title));
      hasChoice = pos;
    }

    yield put(
      authLoginSuccess({
        authProfile: login,
        start_url:
          start_url ||
          (configObject.getProperty('params.start-route')
            ? configObject.getProperty('params.start-route')
            : '/my-education'),
      })
    );
  } catch (error) {
    console.error(error);
    yield put(stopSubmit('login', { errorMsg: 'Kunne ikke logge inn' }));
    yield put(authLoginFailure({ error }));
  }
}

function* checkLogin(action) {
  try {
    const { username } = action.payload;
    const { data } = yield call(() =>
      axios.request({
        method: 'POST',
        params: {
          json: 1,
          ajax: 1,
          user_name: username,
        },
        url: `${backendUrl}/api/verify_user`,
        withCredentials: true,
      })
    );

    // since this endpoint returns a cookie, we remove it since the cookie is invalid before logon
    Cookies.remove('tg-visit');
    if (data.valid) {
      if (data.auth_url) {
        window.location = data.auth_url;
      }
      yield put(authVerifyUserSuccess({ message: 'valid' }));
    } else {
      yield put(authVerifyUserSuccess({ message: 'no-user' }));
    }
  } catch (error) {
    console.error(error);
    yield put(authVerifyUserFailure({}));
  }
}

function* newPassword(action) {
  try {
    const { username } = action.payload;
    const { data } = yield call(() =>
      axios.request({
        method: 'POST',
        params: {
          json: 1,
          ajax: 1,
          email_or_user_name: username,
        },
        url: `${backendUrl}/sendreminder`,
        withCredentials: true,
      })
    );

    yield data.statuscode === 0
      ? put(authLoginNewPasswordSuccess({ message: data.status }))
      : put(authLoginNewPasswordFailure({ message: data.status }));
  } catch (error) {
    console.error(error);
    yield put(authLoginNewPasswordFailure({}));
  }
}

function* logout() {
  try {
    yield call(() =>
      axios.request({
        method: 'POST',
        url: `${backendUrl}/api/logout`,
        withCredentials: true,
      })
    );
    Cookies.remove('identity_login_attempted');
    Cookies.remove('tg-visit');
    localStorage.removeItem('track');
    localStorage.removeItem('config');
    localStorage.removeItem('learningportalConfig');
    yield put(authLogoutSuccess());
  } catch (error) {
    console.error(error);
    Cookies.remove('identity_login_attempted');
    Cookies.remove('tg-visit');
    yield put(authLogoutSuccess());
  }
}

function* checkIfLoggedIn() {
  const { data } = yield call(() =>
    axios.request({
      method: 'GET',
      url: `${backendUrl}/api/login`,
      withCredentials: false,
    })
  );

  return data.valid;
}

function* getAuthStatusAndStartLoading() {
  const isValid = yield call(checkIfLoggedIn);

  if (isValid) {
    yield put(authUserIsConfirmedLoggedIn());
  } else {
    yield put(authUserIsConfirmedNotLoggedIn());

    return;
  }

  // when config is loaded, the rest of the loading will continie
  yield call(loadOrGetConfig);
}

function* startLoginCheckOnInterval(action) {
  const { intervalMs } = action.payload;

  const authStatus = yield select(getAuthStatus);

  if (authStatus.isLoggedIn) {
    const statusNow = yield call(checkIfLoggedIn);

    if (statusNow === false) {
      yield put(authUserIsConfirmedNotLoggedIn());
    }
  }

  // try to emulate set interval
  yield delay(intervalMs);
  yield call(startLoginCheckOnInterval, { payload: { intervalMs } });
}

export default [
  takeLatest(authLoginRequest().type, login),
  takeLatest(authLogoutRequest().type, logout),
  takeLatest(authVerifyUser().type, checkLogin),
  takeLatest(authLoginNewPasswordRequest().type, newPassword),
  takeLatest(authGotoNext().type, gotoNext),
  takeEvery(authUnauthorized().type, logout),
  takeLatest(authCheckIfLoggedIn().type, checkIfLoggedIn),
  takeLatest(
    authGetAuthStatusAndStartLoading().type,
    getAuthStatusAndStartLoading
  ),
  takeLatest(authStartLoginCheckOnInterval().type, startLoginCheckOnInterval),
];
