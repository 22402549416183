import {number, string, shape, arrayOf, bool, any} from 'prop-types';

export const PersonShape = () =>
  shape({
    email: any,
    extern_person_id: string,
    firstname: string,
    fullname: string,
    id: number,
    lastname: string,
    person_id: number,
    user_name: string,
  });

export const CompetenceCompetenceShape = () =>
  shape({
    code: any,
    competence_external_id: number,
    competence_id: number,
    competence_type: any,
    display_mode: bool,
    duration: any,
    external_competence_id: number,
    grace_period: any,
    id: number,
    real_title: string,
    survey_render_url: any,
    title: string,
    weight: number,
  });

export const CompetenceTypeShape = () =>
  shape({
    competence_type_id: number,
    title: string,
    id: number,
    competence_type: string,
  });

export const ExpirationShape = () =>
  shape({
    valid_until: any,
    expire_warning_date: any,
    warning: bool,
    expired: bool,
    expires_in_days: any,
  });

export const OwnerOrganisationShape = () =>
  shape({
    organisation_id: any,
    extern_organisation_id: any,
    id: any,
    title: any,
  });

export const RequirementShape = () => shape({});

export const CompetenceElementShape = () =>
  shape({
    cancelled: any,
    certificate_url: any,
    children: arrayOf(any),
    comments: any,
    competence_id: number,
    competence_real_title: string,
    competence_title: string,
    competence_type: CompetenceTypeShape(),
    competence: CompetenceCompetenceShape(),
    date: any,
    display_passed: any,
    event_id: number,
    expiration: ExpirationShape(),
    grade: any,
    has_children: any,
    history_personcompetences: arrayOf(any),
    history: bool,
    id: number,
    joined: string,
    manager_check_user_id: any,
    manager_check: any,
    owner_organisation: OwnerOrganisationShape(),
    owner_person: PersonShape(),
    passed: number,
    person_competence_id: number,
    person: PersonShape(),
    requirement: RequirementShape(),
    school: any,
    self_check: any,
    time_spent: any,
    valid_until: any,
  });

export const RoleShape = () =>
  shape({
    grace_period: any,
    organisation_id: any,
    mandatory: any,
    description: string,
    title: string,
    organisation_ids: any,
    organisations: any,
    role_type_id: number,
    rolemetatype: string,
    owner_person: any,
    role_id: number,
    owner_organisation: any,
    id: number,
    roletype_name: string,
    competences: arrayOf(CompetenceElementShape()),
  });

export const PersonWithRolesShape = () => ({
  ...PersonShape(),
  roles: arrayOf(RoleShape()),
});
