import React from 'react';
import PropTypes from 'prop-types';

import './statistics-card.scss';

const isDef = n => n !== null && n !== undefined;

const StatisticsCard = ({
  icon,
  color,
  title,
  count,
  total,
  details,
  columns,
}) => (
  <div
    className="statistics-card__wrapper"
    data-test="component-statisticscard"
  >
    <div className="statistics-card">
      {icon && (
        <div className="statistics-card__icon-wrapper">
          <div
            className="statistics-card__icon"
            style={{backgroundColor: color}}
          >
            {icon}
          </div>
        </div>
      )}
      <div className="statistics-card__content">
        <div className="statistics-card__content__title">{title}</div>
        {Array.isArray(columns) && (
          <div className="statistics-card__content__columns">
            {columns.map(({count, total, details}) =>
              (isDef(count) || isDef(total)) && (
                <div className="statistics-card__content__columns__item">
                  <div
                    className="statistics-card__content__score"
                    style={{color}}
                  >
                    {count}
                    {isDef(total) && isDef(count) ? ` / ${total}` : total}
                  </div>
                  {details && (
                    <div className="statistics-card__content__details">
                      {details}
                    </div>
                  )}
                </div>
              ))}
          </div>
        )
          || (isDef(count) || isDef(total)) && (
            <div
              className="statistics-card__content__score"
              style={{color}}
            >
              {count}
              {isDef(total) && isDef(count) ? ` / ${total}` : total}
            </div>
          )}
        {details 
          && <div className="statistics-card__content__details">{details}</div>}
      </div>
    </div>
  </div>
);

StatisticsCard.propTypes = {
  icon: PropTypes.node,
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  total: PropTypes.number,
  details: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
    total: PropTypes.number,
    details: PropTypes.string,
  })),
};

StatisticsCard.defaultProps = {
  icon: null,
  color: '',
  title: '',
  count: null,
  total: null,
  details: '',
  columns: null,
};

export default StatisticsCard;
