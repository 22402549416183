import React, {useEffect, useRef, useState, useCallback} from 'react';
import PropTypes from 'prop-types';

const modulo = (n, m) => (n % m + m) % m;

const DonutChart = ({
  width: defaultWidth,
  height: defaultHeight,
  data: dataInput,
  holeScale,
  chartScale,
  labelScale,
  leaderOffset,
  padding,
  aspectRatio,
  centerText,
  centerTextColor,
  centerTextSize,
  centerTextStyle,
  centerTextWeight,
  font,
}) => {
  const shuffleData = useCallback(
    dataInput => {
      let dataSorted = [...dataInput];

      dataSorted.sort((d1, d2) =>
        d1.value !== d2.value ? d1.value > d2.value && -1 || 1 : 0);
      dataSorted = dataSorted
        .slice(0, dataSorted.length / 2)
        .concat(dataSorted.slice(dataSorted.length / 2, dataSorted.length).reverse());
      const result = [];
      const len = dataSorted.length;

      for (let i = 0; i < len; i += 1) {
        const odd = i % 2 === 1;

        result.push(...dataSorted.splice(odd ? dataSorted.length - 1 : 0, 1));
      }

      return result;
    },
    [dataInput],
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(shuffleData(dataInput));
  }, [dataInput, shuffleData]);

  const uniqueId = () => Math.random()
    .toString(36)
    .slice(2, 11);

  const labelRefs = useRef([]);
  const [labelSizes, setLabelSizes] = useState([]);

  useEffect(() => {
    setLabelSizes(labelRefs.current.map(textLabels =>
      textLabels.reduce((max, textLabel) => {
        if (textLabel) {
          const {width} = textLabel.getBoundingClientRect();

          return Math.max(max, width / labelScale);
        }

        return max;
      }, 0)));
  }, [defaultWidth, defaultHeight, data, labelScale]);

  const setLabelRef = (index, labelIndex) => label => {
    labelRefs.current[index] = labelRefs.current[index] || [];
    labelRefs.current[index][labelIndex] = label;
  };

  const width
    = defaultWidth || aspectRatio && defaultHeight * aspectRatio || 0;
  const height
    = defaultHeight || aspectRatio && defaultWidth * aspectRatio || 0;

  if (!width || !height) {
    return null;
  }

  const cX = width / 2;
  const cY = height / 2;
  const strokeWidth = Math.min(width, height) * ((1 - holeScale) * 0.2);
  const radius
    = (Math.min(width, height) / 2 - strokeWidth / 2) * chartScale - padding;
  const circumference = Math.PI * 2 * radius;

  const total
    = data && data.reduce((total, {value}) => total + value, 0) || 0;

  if (!total) {
    return (
      <svg
        width={width}
        height={height}
        role="img"
      />
    );
  }

  let offset = 0;
  const segments = [];

  for (const [i, {title, subtitle, value, color}] of data.entries()) {
    if (value) {
      const percentage = value / total;
      const size = circumference * percentage;
      const centerOffset = offset - size / 2;

      segments.push({
        index: i,
        value,
        title,
        subtitle,
        offset,
        centerOffset,
        color,
        strokeDasharray: `${size} ${circumference - size}`,
      });

      offset -= size;
    }
  }

  const isRight = offset => {
    if (Math.abs(offset) > circumference / 2) {
      return circumference - Math.abs(offset) < circumference * 0.25;
    }

    return Math.abs(offset) < circumference * 0.25;
  };

  const getDistanceFromRight = offset => {
    if (Math.abs(offset) > circumference / 2) {
      return (circumference - Math.abs(offset)) * -Math.sign(offset);
    }

    return offset;
  };

  let offsetBias = 0;

  for (const {centerOffset} of segments) {
    const rotation = modulo(centerOffset, circumference);
    const right = isRight(rotation);

    offsetBias += getDistanceFromRight(right ? rotation : modulo(rotation + circumference / 2, circumference));
  }
  offset = offsetBias / segments.length;
  for (const segment of segments) {
    segment.centerOffset = modulo(
      segment.centerOffset - offset,
      circumference,
    );
  }

  const labelHeight = 45 * labelScale;

  const getY = (offset, distance) =>
    Math.sin(modulo(offset, circumference) / circumference * -Math.PI * 2)
    * (radius + strokeWidth / 2 + (distance || 0));
  const getX = (offset, distance) =>
    Math.cos(modulo(offset, circumference) / circumference * -Math.PI * 2)
    * (radius + strokeWidth / 2 + (distance || 0));

  const getLabelPositions = (segments, gap) => {
    if (segments.length === 1) {
      const pos = getY(segments[0].centerOffset);

      return [
        pos
          + Math.min(
            Math.max(
              cY * 0.334 * Math.sign(pos || -1),
              -cY / 2 + labelHeight / 2,
            ),
            cY / 2 - labelHeight / 2,
          ),
      ];
    }
    let lastPosition;
    let shift = 0;
    const shifts = [0];
    let shiftedLabels = segments.map(({centerOffset}, key) => {
      const labelPosition = getY(centerOffset);

      if (key) {
        const prev = lastPosition;

        lastPosition = Math.max(lastPosition + gap, labelPosition);
        shifts[key] = lastPosition - prev - gap;
        shift += shifts[key];

        return lastPosition;
      }
      lastPosition = labelPosition;

      return labelPosition;
    });
    let labelSpan = shiftedLabels[shiftedLabels.length - 1] - shiftedLabels[0];
    let scale = shift / labelSpan;
    let shiftReduced = 0;

    shiftedLabels = shiftedLabels.map((labelPosition, key) => {
      const reduce = shifts[key] - shifts[key] * scale;

      shiftReduced += reduce;
      shift -= reduce;

      return labelPosition - shiftReduced;
    });
    const yOffset
      = shiftedLabels.reduce((addUp, y) => addUp + y, 0) / shiftedLabels.length;

    shiftedLabels = shiftedLabels.map(labelPosition => labelPosition - yOffset);

    labelSpan = shiftedLabels[shiftedLabels.length - 1] - shiftedLabels[0];
    scale = (height - gap) / (labelSpan + labelHeight / 2);

    return shiftedLabels.map(labelPosition => labelPosition * scale);
  };

  const sortY = ({centerOffset: offset1}, {centerOffset: offset2}) => {
    const y1 = getY(offset1);
    const y2 = getY(offset2);

    return y1 !== y2 && (y1 < y2 ? -1 : 1) || 0;
  };

  const leftSegments = segments
    .filter(({centerOffset}) => !isRight(centerOffset))
    .sort(sortY);
  const leftLabelGap = Math.max(
    labelHeight,
    (height - labelHeight * 2) / (leftSegments.length || 1),
  );
  const leftLabelPositions = getLabelPositions(leftSegments, leftLabelGap);

  const rightSegments = segments
    .filter(({centerOffset}) => isRight(centerOffset))
    .sort(sortY);
  const rightLabelGap = Math.max(
    labelHeight,
    (height - labelHeight * 2) / (rightSegments.length || 1),
  );
  const rightLabelPositions = getLabelPositions(rightSegments, rightLabelGap);

  const renderLeader = (fromX, fromY, toX, toY, labelWidth) => {
    const inverted = Math.abs(fromY - cY) < Math.abs(toY - cY);
    const id = uniqueId();

    const path = d => (
      <>
        <defs>
          <mask
            id={id}
            width={width}
            height={height}
          >
            <rect
              width={width}
              height={height}
              fill="#fff"
            />
            <rect
              x={toX - labelWidth / 2}
              y={toY - labelHeight / 2}
              width={labelWidth}
              height={labelHeight}
              fill="#000"
            />
          </mask>
        </defs>
        <path
          mask={`url(#${id})`}
          d={d}
          stroke="#6a6868"
          fill="transparent"
          strokeWidth="0.8"
        />
      </>
    );

    if (fromY !== toY) {
      let angleHeight
        = (fromY - toY)
        * Math.cos(6)
        * Math.sign(fromX - toX)
        * -Math.sign(fromY - toY);

      if (Math.abs(angleHeight) > Math.abs(fromX - toX)) {
        angleHeight = Math.abs(fromX - toX) * Math.sign(angleHeight);
      }
      if (inverted) {
        return path(`M ${fromX} ${fromY} L ${fromX + angleHeight} ${toY} L ${toX} ${toY}`);
      }

      return path(`M ${fromX} ${fromY} L ${toX - angleHeight} ${fromY} L ${toX} ${toY}`);
    }

    return path(`M ${fromX} ${fromY} L ${toX} ${toY}`);
  };

  const renderLabel = (segment, key, right, positions) => {
    if (segment.value <= 0) {
      return null;
    }

    const x = getX(segment.centerOffset, leaderOffset);
    const y = getY(segment.centerOffset, leaderOffset);
    const labelPos = cY + positions[key];
    const labelWidth
      = (labelSizes[segment.index] + leaderOffset * 2) * labelScale;

    if (!segment.title && !segment.subtitle) {
      return null;
    }

    return (
      <>
        {!!labelWidth
          && renderLeader(
            cX + x,
            cY + y,
            right ? width - labelWidth / 2 : labelWidth / 2,
            labelPos,
            labelWidth,
          )}
        <text
          ref={setLabelRef(segment.index, 0)}
          x={
            right
              ? width - labelWidth + leaderOffset * labelScale
              : leaderOffset * labelScale
          }
          y={labelPos - 8 * labelScale}
          fontSize={20 * labelScale}
          dominantBaseline="middle"
          fontFamily={font}
          fontWeight="bold"
          fill={labelWidth ? '#6a6868' : 'transparent'}
        >
          {segment.title}
        </text>
        <text
          ref={setLabelRef(segment.index, 1)}
          x={
            right
              ? width - labelWidth + leaderOffset * labelScale
              : leaderOffset * labelScale
          }
          y={labelPos + 12 * labelScale}
          fontSize={16 * labelScale}
          dominantBaseline="middle"
          fontFamily={font}
          fill={labelWidth ? '#aeaead' : 'transparent'}
        >
          {segment.subtitle}
        </text>
      </>
    );
  };

  return (
    <svg
      width={width}
      height={height}
      role="img"
    >
      {segments.map(({strokeDasharray, offset: segmentOffset, color}) => (
        <circle
          cx={cX}
          cy={cY}
          r={radius}
          fill="transparent"
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={segmentOffset - offset}
        />
      ))}
      {leftSegments.map((segment, key) =>
        renderLabel(segment, key, false, leftLabelPositions))}
      {rightSegments.map((segment, key) =>
        renderLabel(segment, key, true, rightLabelPositions))}
      {centerText
        ? (
          <text
            x={cX}
            y={cY}
            fill={centerTextColor}
            fontSize={centerTextSize}
            dominantBaseline="middle"
            textAnchor="middle"
            fontStyle={centerTextStyle}
            fontWeight={centerTextWeight}
            fontFamily={font}
          >
            {centerText}
          </text>
        )
        : null}
    </svg>
  );
};

DonutChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.required,
    subtitle: PropTypes.string.required,
    value: PropTypes.string.required,
    color: PropTypes.string.required,
  })).isRequired,
  holeScale: PropTypes.number,
  chartScale: PropTypes.number,
  labelScale: PropTypes.number,
  leaderOffset: PropTypes.number,
  padding: PropTypes.number,
  aspectRatio: PropTypes.number,
  centerText: PropTypes.string,
  centerTextColor: PropTypes.string,
  centerTextSize: PropTypes.string,
  centerTextStyle: PropTypes.string,
  centerTextWeight: PropTypes.string,
  font: PropTypes.string,
};

DonutChart.defaultProps = {
  width: undefined,
  height: undefined,
  holeScale: 0.86,
  chartScale: 1,
  labelScale: 1,
  leaderOffset: 6,
  padding: 8,
  aspectRatio: undefined,
  centerText: undefined,
  centerTextColor: undefined,
  centerTextSize: undefined,
  centerTextStyle: undefined,
  centerTextWeight: undefined,
  font: undefined,
};

export default DonutChart;
