import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';

import './modal.scss';
import {getIsMobile} from '@selectors/global.selectors';
import {i18n} from '@src/i18n';
import {StyledModalTitle, LearnMoreWrapper, BottomButtonsWrapper, ActionButtonsWrapper, StyledModalBody, Wrapper, LearnMoreLink, BodyWrapper, TitleWrapper, ContentWrapper, CloseButton} from './modal-styles';
import {IconButton} from '@components/buttons/iconButton';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import {ArrowCircleUpRight} from 'phosphor-react';
import {ActionButton} from '@components/buttons/actionButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Modal = connect(state => ({isMobile: getIsMobile(state)}))(({
  isMobile,
  onClose,
  className,
  withPhosphorIcon,
  onCancel,
  onConfirm,
  classNameContent,
  disableActionButtons,
  variant,
  title,
  disableCloseButton,
  withReadMoreLnk,
  submitBtnText,
  cancelBtnText,
  children,
  isFetching,
  cancelButtonText,
  submitButtonText,
}) => {
  const TitleComponent = title;

  const PhosphorIconComponent = withPhosphorIcon && withPhosphorIcon.icon;

  return createPortal(
    <Wrapper
      role="modal"
    >
      <div
        className="modal__backdrop"
        onClick={() => variant !== 'warning' && onClose && onClose()}
      />
      {isFetching && <LoadingIndicator />}
      <ContentWrapper
        hasIcon={withPhosphorIcon}
        isMobile={isMobile}
        className={classNames('modal__content', classNameContent)}
        style={
            isFetching
              ? {display: 'none'}
              : {}
          }
      >
        {variant !== 'warning' && !disableCloseButton &&
        (
          <CloseButton
            className="close-btn"
            ariaLabel={i18n('globals.close')}
            title={i18n('globals.close')}
            onClick={() => onClose && onClose()}
            style={{
              position: 'absolute',
              right: '1.75em',
              top: '1.75em',
            }}
          >
            {' '}
            <FontAwesomeIcon icon={faTimes} />
            {' '}

          </CloseButton>
        )}

        <TitleWrapper>
          {withPhosphorIcon
       && (
         <PhosphorIconComponent
           css={{
             marginRight: '0.7rem',
             width:'1.75rem',
             height: '1.75rem',
             alignSelf: 'center',
             ...withPhosphorIcon.css || {},
           }}

           {...withPhosphorIcon}
         />
       )}
       {TitleComponent && 
          <TitleComponent /> }

        </TitleWrapper>
        <BodyWrapper>
          {children}
        </BodyWrapper>
        {withReadMoreLnk || !disableActionButtons && (
              <BottomButtonsWrapper>
                {withReadMoreLnk
              && (
                <LearnMoreWrapper>
                  <LearnMoreLink
                    href={withReadMoreLnk.href}
                    target="_blank"
                  >
                    {withReadMoreLnk.text || i18n('global.read-more')}
                  </LearnMoreLink>
                  <ArrowCircleUpRight
                    weight="fill"
                    style={{fontSize:'1.1em'}}
                  />
                </LearnMoreWrapper>
              )}
                {!disableActionButtons
                && (
                  <ActionButtonsWrapper>
                    <ActionButton
                      variant="text"
                      theme="black"
                      onClick={onCancel}
                    >
                      {cancelButtonText || i18n('globals.cancel')}
                    </ActionButton>
                    <ActionButton
                      variant="round"
                      onClick={onConfirm}
                      theme="black"
                    >
                      {submitButtonText || i18n('globals.confirm')}
                    </ActionButton>
                  </ActionButtonsWrapper>
                )}
              </BottomButtonsWrapper>
            )}
      </ContentWrapper>
    </Wrapper>,
    document.querySelector('#modal'),
  );
});

const LoadingIndicator = () => {
  const maxLoadingState = 3;
  const loadingChar = '.';
  const [loadingState, setLoadingState] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingState(loadingState =>
        loadingState >= maxLoadingState ? 0 : loadingState + 1);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <p className="modal__loading-text">
      {i18n('globals.loading')}
      {' '}
      {loadingChar.repeat(loadingState)}
    </p>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  classNameContent: PropTypes.string,

  // if the data for the modal is loaded
  dataLoaded: PropTypes.bool,

  // if true - displays a loading animation untill dataLoaded is true and then opens the modal
  waitForLoadedDataWithAnimation: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  className: null,
  classNameContent: null,
  dataLoaded: false,
  waitForLoadedDataWithAnimation: false,
};

export const Title = ({children, withPhosphorIcon, ...props}) => (
  <StyledModalTitle {...props}>

    {children}
  </StyledModalTitle>
)
  ;

Modal.Title = Title;

export default Modal;
