import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from '@styles/facepaint';
import { getImageUrl } from '@utils/misc.utils';

const leftSideOnlyBreakpoint = '970px';

export const LeftPart = styled.div`
  width: 43%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 4em;
  height: 100%;

  @media (max-height: 780px) {
    height: auto;
  }

  @media (max-width: ${leftSideOnlyBreakpoint}) {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 0 1.5em;
  }

  @media (max-width: 350px) {
    padding: 0 1.2em;
  }
`;
export const RightPart = styled.div`
  height: 100%;
  width: 67%;

  @media (max-width: ${leftSideOnlyBreakpoint}) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

export const MainHeader = styled.h1`
  margin-bottom: 1.59em;
  font-size: 1.8em;
  z-index: 2;
`;

export const BgImage = styled.div`
  background-color: #b2b2b2; /*shown while loading image*/
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 100%;
  height: 100%;
`;

export const LanguageSelectWrapper = styled.div`
  position: absolute;
  left: 2em;
  top: 2em;
  width: calc(100% - 2em);

`;

export const Warning = styled.div`
  padding-left: 10px;
  color: red;
  font-weight: 900;
`;

export const LoginFormWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: column;
  align-items: baseline;

  ${mq.small} {
    width: 100%;
  }

  ${mq.medium} {
    width: 100%;
  }

  ${mq.large} {
    width: 480px;
  }

  @media (max-height: 650px) {
    margin-top: 3.8em;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 1.2em;

  @media (max-height: 780px) {
    margin-top: 2.2em;
  }
`;

export const FooterLogo = styled.div`
  height: 65px;
  margin-right: 1.3em;
`;

export const FooterTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;

`;

export const FooterMainText = styled.p`
  margin: 0;
  font-size: 0.85em;
  margin-bottom: 0.1em;
  a {
    color: #575757;
    white-space: nowrap;
    text-decoration: underline;
    &.focus-visible {
      color: #202020;
    }
  }
`;
export const FooterSecondaryText = styled.p`
  margin: 0;
  font-size: 0.67em;
  color: #878482;
`;
