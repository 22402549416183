import React from 'react';
import {jsx, css} from '@emotion/react';
import styled from '@emotion/styled';
import {mq} from '@facepaint';

export const Stack = styled('div')({
  label: 'Stack',
  display: 'flex',
  color: 'white',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  justifyContent: 'inherit',
  width: '80%',
});

export const Base = styled('div')({label: 'Base'});

export const Box = styled('div')({
  label: 'Box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  [mq.medium]: {padding: '1em'},
  [mq.large]: {padding: '3em'},
});
