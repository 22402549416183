import {eventChannel, END} from 'redux-saga';
import {
  all,
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  select,
  throttle,
} from 'redux-saga/effects';
import axios from 'axios';
import {backendUrl} from '@config';

import retry from '@utils/sagas.utils';

import * as mapCoursesActions from '@actions/map.actions';

import {
  getCurrTrack, getPropertiesForCurrLangAndTrackBadge,
} from '@selectors/config.selectors';
import {getIsAllMapDotsCompleted} from '@selectors/map.selectors';
import {useSelector} from 'react-redux';
import {selectMapCourse} from '@actions/map.actions';

export function* fetchMapCourses(action) {
  const {id} = action.payload;
  const currTrack = id ? id : yield select(getCurrTrack);

  yield put(mapCoursesActions.fetchMapCoursesRequest());
  try {
    const mapCourses = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/player/track`,
          params: {id: currTrack},
          withCredentials: true,
        })
        .then(res => res.data));
    yield put(mapCoursesActions.fetchMapCoursesSucceeded({data: mapCourses}));
    const isAllDone = yield select(getIsAllMapDotsCompleted);
    const badgeInfo = yield select( getPropertiesForCurrLangAndTrackBadge);

    if (isAllDone && badgeInfo) {
      yield put(selectMapCourse({id: 'badge'}))
    }
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMapVerificationCourse(action) {
  yield put(mapCoursesActions.fetchMapVerificationCompetenceRequest());
  const {cid} = action.payload;

  try {
    const course = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competences/${cid}?fields=short_description,files`,
          withCredentials: true,
        })
        .then(res => res.data));

    yield put(mapCoursesActions.fetchMapVerificationCompetenceSucceeded({data: course.competences[0]}));
  } catch (error) {
    console.error(error);
  }
}

export default [
  takeLatest(mapCoursesActions.FETCH_MAP_COURSES, fetchMapCourses),
  takeLatest(
    mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE,
    fetchMapVerificationCourse,
  ),
];
