import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getProfile} from '@selectors/profile.selectors';

import {routerMyEducationMessagesMainViewDidMount} from '@actions/router.actions';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';

import {ProfileBaseInformation} from '@routes/my-education';
import '../styles/my-education-container.scss';
import {getRouteWithParams} from '@utils/routes.utils';
import PersonInfo from '@routes/my-education/components/person-info';

import MessagesList from '../components/messages-list';


class MyEducationMessagesContainer extends Component {
  constructor(props) {
    super(props);
    this.redirectToProfileView = this.redirectToProfileView.bind(this);
  }

  componentDidMount() {
    const {routerMyEducationMessagesMainViewDidMount} = this.props;

    routerMyEducationMessagesMainViewDidMount('messages');
  }

  redirectToProfileView() {
    const {history} = this.props;

    history.push(myEducationRoutes.profileView.path);
  }

  render() {
    const {profile, messages} = this.props;
    const profileData = profile.data;

    return (
      <div className="my-education">
        <div className="my-education__section">
          <div className="my-education__section">
            {messages && messages.data && (
              <div className="messages-list-wrapper">
                <h2>Mine meldinger</h2>
                <MessagesList messages={messages.data} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MyEducationMessagesContainer.propTypes = {
  history: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  messages: PropTypes.shape({}).isRequired,
  routerMyEducationMessagesMainViewDidMount: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  messages: state.messages.all,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {routerMyEducationMessagesMainViewDidMount},
    dispatch,
  );

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(MyEducationMessagesContainer);
