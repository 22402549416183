import {screenreaderOnlyMinxin} from '@styles/mixins';

import {css} from '@emotion/react';
import React, {useEffect, useRef, useState} from 'react';
import {useOnClickOutside} from '@src/hooks/hooks';

import {Wrapper, DropdownItemsWrapper, ButtonsWrapper, ActionButton, DropdownItem, ToggleDropdownButton, DropdownItemsLabel} from './dropdown-styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {i18n} from '@src/i18n';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';

/**
 * A simple style select-list.
 * Items are provided using items-prop, like this:
 * [{label:"First name", value:"firstname"}]
 */
export const Dropdown = ({items, multiple = false, actionButton,  sortItems, onFirstOpen, isFetching, toggleDropdownButton, onChange, dropdownItemsLabel, ...props}) => {
  const [isOpen, setIsOpen] = useState(null);
  const itemsWrapperRef = useRef();
  const toggleDropdownButtonRef = useRef();

  useOnClickOutside([itemsWrapperRef, toggleDropdownButtonRef], () => setIsOpen(false));

  const handleSelectOption = value => {
    onChange(value);
    setIsOpen(false);
  };

  const handleToggleOpen = () => {
    if(isOpen === null && onFirstOpen) {
      onFirstOpen();
    }
    setIsOpen(old => !old);
  };

  const focusFirstOption = () => {
    if(itemsWrapperRef.current) {
      const firstFocusable = [...itemsWrapperRef.current.childNodes].find(cn => cn.tabIndex >= 0);

      setTimeout(() => {
        firstFocusable.focus();
      });
    }
  };

  const focusNextOption = () => {
    const focused = document.activeElement;

    if(focused) {
      const next = focused.nextElementSibling;

      if(next && [...next.classList].includes('dropdown-item')) {
        next.focus();
      }
    }
  };

  const focusPrevOption = () => {
    const focused = document.activeElement;

    if(focused) {
      const prev = focused.previousElementSibling;

      if(prev && [...prev.classList].includes('dropdown-item')) {
        prev.focus();
      }
    }
  };

  const  handleKeyUp = evt => {
    if(evt.key === 'Escape') {
      setIsOpen(false);
    }

    // focus on first item matching key
    const firstMatch = [...itemsWrapperRef.current.childNodes].find(e => e.innerHTML[0].toLowerCase() === evt.key.toLowerCase());

    if(firstMatch) {
      firstMatch.focus();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);

    return () => document.removeEventListener('keyup', handleKeyUp);
  }, []);
  
  const itemsForDisplay = sortItems === 'alpha' &&  items && items.sort((a, b) => a.label.localeCompare(b.label)) || items;

  return (
    <Wrapper {...props}>

      <ButtonsWrapper aria-hidden="true">
        {actionButton 
            && (
              <ActionButton
                onClick={() => {
                  setIsOpen(false);
                  if(actionButton.onClick) {
                    actionButton.onClick();
                  }

                  if(actionButton.souldOpenDropdown) {
                    handleToggleOpen();
                  }
                }}
              >
                {actionButton.text}
              </ActionButton>
            )}
        <ToggleDropdownButton
          isNextToActionButton={actionButton}
          ref={toggleDropdownButtonRef}
          title={toggleDropdownButton.title}
          aria-label={toggleDropdownButton.title}
          onClick={handleToggleOpen}
          onKeyDown={e => {
            if(e.key === 'ArrowDown') {
              e.preventDefault();
              handleToggleOpen();
              focusFirstOption();
            }
          }}
        >
          {toggleDropdownButton.text}
          <FontAwesomeIcon icon={faCaretDown} />
        </ToggleDropdownButton>
      </ButtonsWrapper>

      <DropdownItemsWrapper
        ref={itemsWrapperRef}
        isOpen={isOpen}
      >
        {dropdownItemsLabel
           && <DropdownItemsLabel>{dropdownItemsLabel}</DropdownItemsLabel>}

        {isFetching && (
          <DropdownItem>
            {i18n('globals.loading', {ellipsis: true})}
          </DropdownItem>
        )}
        {!isFetching && itemsForDisplay && itemsForDisplay.map(item => (
          <DropdownItem
            className="dropdown-item"
            tabIndex={item.disabled ? '-1' : '0'}
            key={item.value}
            disabled={item.disabled}
            onClick={() =>
              !item.disabled 
                && handleSelectOption(item.value)}
            onKeyUp={e => {
              if(e.key === 'Enter' || e.key === ' ') {
                handleSelectOption(item.value);
              };
            }}

            onKeyDown={e => {
              // prevent site from scrolling
              if(['ArrowDown', 'ArrowUp', ' '].includes(e.key)) {
                e.preventDefault();
              }
              if(e.key === 'ArrowDown') {
                focusNextOption();
              } else if(e.key === 'ArrowUp') {
                focusPrevOption();
              }
            }}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownItemsWrapper>

      {/* Screenreder-only select*/}
      <div css={screenreaderOnlyMinxin}>
        {dropdownItemsLabel
           && <DropdownItemsLabel>{dropdownItemsLabel}</DropdownItemsLabel>}
        {isFetching && i18n('globals.loading', {ellipsis: true})}
        {!isFetching && itemsForDisplay
        && (
          <select
            tabIndex="-1"
            onBlur={evt => handleSelectOption(evt.target.value)}
            {...multiple ? {multiple:'true'} : {}}
          >
            {itemsForDisplay.map(item => (
              <option
                value={item.value}
                disabled={item.disabled}
              >
                {item.label}
              </option>
            ))}
          </select>
        )}
      </div>
    </Wrapper>
  );
};