import * as rolesActions from '@actions/roles.actions';

const initialState = {
  roles: {
    isFetching: false,
    data: null,
    error: null,
  },
  required: {
    isFetching: false,
    data: null,
    error: null,
  },
  organisations: {
    isFetching: false,
    data: null,
    error: null,
  },
};

const roles = (state = initialState, action) => {
  switch (action.type) {
  case 'RESET_STORE':
    return initialState;
  case rolesActions.FETCH_ROLES_REQUEST:
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: true,
        data: null,
        error: null,
      },
    };
  case rolesActions.FETCH_ROLES_SUCCESS:
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: false,
        data: action.payload.roles,
      },
    };
  case rolesActions.FETCH_ROLES_FAILURE:
    return {
      ...state,
      roles: {
        ...state.roles,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case rolesActions.FETCH_ROLES_REQUIRED_REQUEST:
    return {
      ...state,
      required: {
        ...state.roles,
        isFetching: true,
        data: null,
        error: null,
      },
    };
  case rolesActions.FETCH_ROLES_REQUIRED_SUCCESS:
    return {
      ...state,
      required: {
        ...state.roles,
        isFetching: false,
        data: action.payload.roles,
      },
    };
  case rolesActions.FETCH_ROLES_REQUIRED_FAILURE:
    return {
      ...state,
      required: {
        ...state.roles,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case rolesActions.FETCH_ORGANISATIONS_REQUEST:
    return {
      ...state,
      organisations: {
        ...state.organisations,
        isFetching: true,
        data: null,
        error: null,
      },
    };
  case rolesActions.FETCH_ORGANISATIONS_SUCCESS:
    return {
      ...state,
      organisations: {
        ...state.organisations,
        isFetching: false,
        data: action.payload.organisations,
      },
    };
  case rolesActions.FETCH_ORGANISATIONS_FAILURE:
    return {
      ...state,
      organisations: {
        ...state.organisations,
        isFetching: false,
        error: action.payload.error,
      },
    };
  default:
    return state;
  }
};

export default roles;
