import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const AspectRatio = ({ratio, children, style, ...props}) => {
  const [height, setHeight] = useState(null);
  const [refWrapper, setRefWrapper] = useState(null);

  const updateHeight = () => {
    if (!refWrapper) {
      return;
    }
    if (!ratio) {
      return;
    }
    const {width} = refWrapper.getBoundingClientRect();

    const newHeight = width / ratio;

    if (newHeight !== height) {
      setHeight(width / ratio);
    }
  };

  const onRef = element => {
    if (!element) {
      return;
    }
    if (!refWrapper) {
      setRefWrapper(element);
    }
    if (!height) {
      updateHeight();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateHeight, false);
    window.addEventListener('orientationchange', updateHeight, false);

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('orientationchange', updateHeight);
    };
  }, [height]);

  return (
    <div
      {...props}
      ref={onRef}
      style={{
        ...style || {},
        height,
      }}
    >
      {children}
    </div>
  );
};

AspectRatio.propTypes = {
  ratio: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default AspectRatio;
