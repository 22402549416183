import styled from '@emotion/styled';
import {motion} from 'framer-motion';

export const TreeViewer = styled(motion.div)`
  display: flex;
  height: auto;
  width: 100%;
  border-bottom: 1px solid #efefef;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const LoadingWrapper = styled.div`
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
`;