import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PersonAvatar from '@components/person-avatar/person-avatar';

import '@routes/employees/styles/search-bar.scss';

const SearchBar = ({
  input,
  name,
  className,
  selectedPerson,
  children,
  loading,
  ...props
}) => (
  <div
    className={classNames('search-bar', className, {'search-bar-open': children && Object.keys(children).length})}
  >
    <label
      htmlFor={input.name || name}
      className="search-bar__label"
    >
      <input
        {...props}
        autoComplete="off"
        className={classNames('search-bar__input', {'search-bar__input-inset': selectedPerson})}
        id={input.name || name}
        name={input.name || name}
        type="text"
      />
      {selectedPerson && (
        <div className="search-bar__avatar">
          <PersonAvatar
            size="32px"
            fontSize="18px"
            person={selectedPerson}
          />
        </div>
      )}
      <div className="search-bar__icon">
        {loading 
          && <i className=" fas fa-spin fa-spinner search-bar__icon__loading" />
         || <i className="fas fa-search" />}
      </div>
    </label>
    {children}
  </div>
);

SearchBar.propTypes = {
  input: PropTypes.shape({}),
  name: PropTypes.string,
  className: PropTypes.string,
  selectedPerson: PropTypes.shape({}),
  children: PropTypes.node,
  loading: PropTypes.bool,
};

SearchBar.defaultProps = {
  input: {},
  name: null,
  className: '',
  selectedPerson: null,
  children: [],
  loading: false,
};

export default SearchBar;
