import { put, select, takeLatest, call } from 'redux-saga/effects';
import axios from 'axios';
import { backendUrl, backendUrlV2, configFileUrl } from '@config';
import * as configActions from '@actions/config.actions';
import retry from '@utils/sagas.utils';

const cc = `{

}`;

const configFullrulle = `
{
  "params": {
    "default-img": "/grape/files/file/6752",
    "default-track": "fullrulle",
    "default-lang": "no",
    "reloading-indicator-variant": "fullscreen",
    "default-title-key": "TRAINER_CERTIFICATE",
    "map-img": {
      "backgroundPosition": "80% 50%"
    },
    "menu": {
      "type: "menu-one",
      "logo": "/grape/files/file/6811",
      "baseColorMobile": "rgb(1, 0, 49)",
      "baseColorDesktop": "rgb(1, 0, 49)",
      "buttons": {
        "0": {
          "text": "Gå til resursside",
          "textKey": "GO_TO_TRAINERCOURSES",
          "to": "/atlas",
          "routeHomeWhenActive": true,
          "icon": [
            "fas",
            "hiking"
          ]
        },

        "1": {
          "text": "Min side",
          "textKey": "MY_PAGE",
          "to": "https://coopkompetanse.no",
          "icon": [
            "fas",
            "door-open"
          ]
        }
    }
    }
  },
  "routes": {
    "atlas": {
      "disabled": true,
      "configByTrackAndLang": {
        "fullrulle": {
          "no": {
            "mapId": 1907,
            "verify": {},
            "outro": {
              "courseId": 1917,
              "redirectAfterFinished": "/course-catalog"
            }
          }
        }
      }
    },
    "my-education": {
      "widgets": {
        "user": {},
        "news": {
          "start": 607
        },
        "education": {},
        "passed-competence": {},
        "messages": {}
      }
    },
    "course-catalog": {
      "hideSearchField": true,
      "hideTypeFilter": true,
      "hideSorting": true,
      "configByTrack": {
        "fullrulle": {
          "startAtGroupId": 115,
          "defaultSelectedCompetenceGroupId": 115
        }
      },
      "getCourseInfoFromCmsForGroups": [34,61,56],
      "disalowwedCompetenceGroupIds": [107],
      "widgets": {},
      "customToplevelSelector": {
        "featuredTiles": {
          "autoplayDuration": "10000",
          "contentIds": 1396
        }
      }
    },
    "employess": {}
  },
  "employees": {
    "manage-employees": false
  },
  "reports": [
    {
      "module": "elastic"
    }
  ]
}
`;

const configTrenerattestStartpakka = `
{
  "params": {
    "default-img": "/files/file/701",
    "default-track": "66",
    "default-lang": "no",
    "reloading-indicator-variant": "fullscreen",
    "default-title-key": "TRAINER_CERTIFICATE",
    "map-img": {
      "backgroundPosition": "80% 50%"
    },
    "menu": {
      "type": "menu-two",
      "configByTrack": {
        "66": {
          "configByLang": {
            "no": {
              "logo": "/grape/files/file/829"
            },
            "en": {
              "logo": "/grape/files/file/910"
            }
          }
        },
        "127": {
          "configByLang": {
            "no": {
              "logo": "/grape/files/file/909"
            },
            "en": {
              "logo": "/grape/files/file/911"
            }
          }
        }
      },
      "baseColorMobile": "rgb(1, 0, 49)",
      "baseColorDesktop": "rgb(1, 0, 49)",
      "buttons": {
        "0": {
          "text": "Gå til Trenerattest",
          "textKey": "GO_TO_TRAINERCOURSES",
          "to": "/atlas",
          "routeHomeWhenActive": true
        },
        "1": {
          "text": "Logg ut",
          "textKey": "GO_BACK",
          "to": "https://trenerdev.nif.no"
        }
      }
    }
  },
  "routes": {
    "atlas": {
      "configByTrackAndLang": {
        "66": {
          "en": {
            "outro": {
              "redirectAfterFinished": "/course-catalog"
            }
          },
          "no": {
            "outro": {
              "redirectAfterFinished": "/course-catalog"
            }
          }
        }
      }
    },
    "my-education": {
      "widgets": {
        "user": {},
        "news": {
          "start": 607
        },
        "education": {},
        "passed-competence": {},
        "messages": {}
      }
    },
    "content": {},
    "course-catalog": {
      "hideTypeFilter": true,
      "hideSearchField": true,
      "hideSorting": true,
      "autostartCourseTypes": [
        "Nano course",
        "Nano Speed Course'"
      ],
      "configByTrack": {
        "66": {
          "startAtGroupId": 54,
          "defaultSelectedCompetenceGroupId": 56
        },
        "127": {
          "startAtGroupId": 53,
          "defaultSelectedCompetenceGroupId": 61
        }
      },
      "disalowwedCompetenceGroupIds": [
        107
      ],
      "widgets": {},
      "customToplevelSelector": {
        "featuredTiles": {
          "autoplayDuration": "10000",
          "configByTrack": {
            "66": {
              "featuredTiles": {
                "contentIds": [
                  67
                ]
              }
            },
            "127": {
              "featuredTiles": {
                "contentIds": [
                  67
                ]
              }
            }
          }
        },
        "fullwidthTile": {
          "placement": "bottom",
          "screenTargets": [
            "mobile"
          ],
          "text": {
            "primaryText": "Trenerattest",
            "secondaryText": "Gå til Trenerattest",
            "background": "linear-gradient(#2b2c2d 0%, #252627 100%)"
          },
          "image": {
            "src": "/grape/files/file/811"
          },
          "route": "/atlas"
        }
      }
    }
  },
  "employees": {
    "manage-employees": false
  }
}
`;

const configTrenerattestDemo01 = `
{
  "params": {
    "default-img": "/files/file/701",
    "default-track": "66",
    "default-lang": "en",
    "reloading-indicator-variant": "fullscreen",
    "default-title-key": "TRAINER_CERTIFICATE",
    "default-route": "/my-education",
    "map-img": {
      "backgroundPosition": "80% 50%"
    },
    "menu": {
      "type": "menu-two",
      "configByTrack": {
        "66": {
          "configByLang": {
            "no": {
              "logo": "/grape/files/file/1232"
            },
            "en": {
              "logo": "/grape/files/file/1232"
            }
          }
        }
      },
      "baseColorMobile": "rgb(1, 0, 49)",
      "baseColorDesktop": "rgb(1, 0, 49)",
      "buttons": {
        "0": {
          "textKey": "GO_TO_TRAINERCOURSES",
          "to": "/atlas",
          "routeHomeWhenActive": true
        },
        "1": {
          "textKey": "LOG_OUT",
          "allowLogout": true,
          "icon": [
            "fas",
            "door-open"
          ]
        }
      }
    }
  },
  "routes": {
    "atlas": {
      "disabled": true,
      "configByTrackAndLang": {
        "66": {
          "en": {
            "outro": {
              "redirectAfterFinished": "/course-catalog"
            }
          },
          "no": {
            "outro": {
              "redirectAfterFinished": "/course-catalog"
            }
          }
        }
      }
    },
    "my-education": {
      "disabled": false,
      "widgets": {
        "user": {},
        "news": {
          "start": 607
        },
        "education": {},
        "passed-competence": {},
        "messages": {}
      }
    },
    "content": {},
    "course-catalog": {
      "hideTypeFilter": true,
      "hideSearchField": true,
      "hideSorting": true,
      "startAtGroupId": 73,
      "autostartCourseTypes": [
        "Nano course",
        "Nano Speed Course'"
      ],
      "configByTrack": {
        "66": {
          "startAtGroupId": 54,
          "defaultSelectedCompetenceGroupId": 58
        }
      },
      "disalowwedCompetenceGroupIds": [
        107
      ],
      "widgets": {},
      "customToplevelSelector": {
        "featuredTiles": {
          "autoplayDuration": "10000",
          "configByTrack": {
            "66": {
              "featuredTiles": {
                "contentIds": [
                  67
                ]
              }
            }
          }
        },
        "fullwidthTile": {
          "placement": "bottom",
          "screenTargets": [
            "mobile"
          ],
          "text": {
            "primaryText": "Trenerattest",
            "secondaryText": "Go to course",
            "background": "linear-gradient(#2b2c2d 0%, #252627 100%)"
          },
          "image": {
            "src": "/grape/files/file/1246"
          },
          "route": "/atlas"
        }
      }
    }
  },
  "employees": {
    "manage-employees": false
  }
}
`;

export function* getConfig(payload) {
  yield put(configActions.configGetConfigRequest());
  try {
    if (!configFileUrl) {
      console.warn(
        'Url for config-file not configured. Please configure using key REACT_APP_CONFIG_FILE_URL in environmentfile'
      );
      yield put(configActions.configGetConfigSuccess({config: {}}));
      return;
    }

    const configObject = localStorage.getItem('config');
    let config = null;
    if (backendUrlV2) {
      config = yield retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${backendUrlV2}/app/${
              configObject ? '/' + configObject : ''
            }`,
            withCredentials: true,
          })
          .then((response) => response.data)
      );
    } else {
      config = yield retry(() =>
        axios
          .request({
            method: 'GET',
            url: `${configFileUrl}${configObject ? '?id=' + configObject : ''}`,
            withCredentials: true,
          })
          .then((response) => response.data)
      );
    }

    const recievedValidJSON = typeof config === 'object' && config !== null;
    if (recievedValidJSON) {
      localStorage.setItem('learningportalConfig', JSON.stringify(config));
      yield put(configActions.configGetConfigSuccess({ config }));
    } else {
      console.error('Invaldid JSON for config-file');
      if (process.env.NODE_ENV === 'development') {
        alert('Invalid JSON for config file');
      }
      yield put(configActions.configGetConfigFailure({ error: {} }));
    }

    if (config.css && config.css.length > 0) {
      config.css.forEach(addCss => {
          var sheet = document.createElement('link');
          sheet.rel = 'stylesheet';
          sheet.href = addCss;
          sheet.type = 'text/css';
          document.head.appendChild(sheet);
        }
      )
    }
    return config;
  } catch (error) {
    console.error(error);
    yield put(configActions.configGetConfigFailure({ error }));
  }
}

function* loadConfigFromLocalStorage() {
  try {
    const stored = localStorage.getItem('learningportalConfig');
    const json = JSON.parse(stored);

    yield put(configActions.configGetConfigSuccess({ config: json }));
  } catch (error) {
    yield put(configActions.configGetConfigFailure({ error }));
    console.error(
      'Failed to load config from localstorage, invalid json',
      error
    );
  }
}

export function* loadOrGetConfig() {
  const missingConfig = !localStorage.getItem('learningportalConfig');
  const isDevEnvAndNotDisabledAutoload =
    process.env.NODE_ENV === 'development' &&
    localStorage.getItem('loadConfigOnRefresh') !== 'off';

  yield missingConfig || isDevEnvAndNotDisabledAutoload
    ? put(configActions.configGetConfig())
    : put(configActions.configLoadConfigFromLocalStorage());
}

export default [
  takeLatest(configActions.CONFIG_GET_CONFIG, getConfig),
  takeLatest(
    configActions.CONFIG_LOAD_FROM_LOCALSTORAGE,
    loadConfigFromLocalStorage
  ),
];
