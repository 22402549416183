import no from './languages/no.json';
import en from './languages/en.json';

const _ = require('lodash');

const strMap2 = {

  START_TRAINING: {
    no: 'Start',
    en: 'Start',
  },
  MY_PAGE: {
    no: 'trener.nif.no',
    en: 'trener.nif.no',
  },

  GO_TO_TRAINERCOURSES: {
    no: 'Gå til løype',
    en: 'Go to Course',
  },

  VERIFICATION_AGREEMENT: {
    no: 'Jeg bekrefter å ha lest, gjennomført og er kjent med innholdet.',
    en: 'I confirm to have read, completed and am familiar with the content.',
  },
  VERIFICATION:{
    no:'Verifikasjon',
    en:'Verification',
  },

  VERIFICATION_PROGRESS_BAR_ARIA_LABEL: {
    no: 'Fremdrift',
    en: 'Progress',
  },

  MISSING_USER_OFFER_TO_REGISTER: {
    no: 'Har du ikke en bruker? Registrer deg',
    en: 'Dont have an user? Register',
  },
  INVALID_USERNAME_OR_PASSWORD: {
    no: 'Brukernavn eller passord stemmer ikke.',
    en: 'Username or password is incorrect.',
  },

  LOGGING_IN_PROGRESS: {
    no: 'Logger inn ...',
    en: 'Logging in...',
  },
  RECIEVE_NEW_PASSWORD: {
    no: 'Få nytt passord',
    en: 'Recieve new password', // grape
  },

  SEND_NEW_PASSWORD:{
    no:'Send nytt passord',
    en:'Send new password',
  },
  SEND_NEW_PASSWORD_DESC:{
    no:'Et nytt engangspassord blir opprettet og sendt til brukeren på e-post.',
    en:'A new one-time password will be created and sent to the user by email.',
  },

  CANCEL: {
    no: 'Avbryt',
    en: 'Cancel',
  },
  NO_USER_FOUND: {
    no: 'Fant ingen buker',
    en: 'No user found',
  },

  NO_USER_FOUND_HELP_EMAIL: {
    no: 'Send en e-epost til - så hjelper vi deg',
    en: 'To get help, please send an email to -',
  },

  CHANGE_USERNAME_PASSWORD: {
    no:'Endre brukernavn/passord',
    en:'Change username/password',
  },

  EDIT_EMPLOYMENT: {
    no:'Endre ansettelse',
    en:'Change employment',
  },

  SEND_MESSAGE_TO: {
    no:'Send melding til',
    en:'Send a message to',
  },

  SEND_MESSAGE:{
    no:'Send melding',
    en:'Send message to',
  },
  SEND:{
    no:'Send',
    en:'Send',
  },
  SEND_NEW_PASSORD_TO_USER_SUCCESS:{
    no:'Et nytt passord er sendt til brukeren.',
    en: 'A new password has been sent to the user.',
  },
  SEND_NEW_PASSORD_TO_USER_ERROR:{
    no: 'Feil, fikk ikke sendt nytt passord.',
    en: 'Could not send a new password.',
  },
  CHANGE_EMPLOYMENT_SUCCESS:{
    no:'Oppdatert med ny dato',
    en:'Updated with new date',
  },

  CHANGE_EMPLOYMENT_ERROR:{
    no:'Problem med oppdatering av brukeren.',
    en:'Problem updating the user.',
  },

  SEND_MESSAGE_SEC:{
    SUBJECT:{
      no:'Emne',
      en:'Subject',
    },
    BODY:{
      no:'Tekst',
      en:'Text',
    },
  },

  ADD_PERSONAL_COMPETENCE_REQUIREMENTS:{
    no:'Legg til personlige kompetansekrav',
    en:'Add personal competence requirements',
  },

  COMPETENCES_TYPES_SEC: {
    MANDATORY:{
      no:'Obligatorisk kompetanse',
      en: 'Mandatory competence',
    },
    RECOMENDED: {
      no:'Anbefalt',
      en:'Recomended',
    },
    OTHER_COMPLETED:{
      no:'Annen anbefalt kompetanse',
      en:'Other completed competencies',
    },
  },

  COMPETENCE_COMPONENTS:{
    no:'Komponenter',
    en:'Components',
  },
  LOADING_NANO:{
    no:'Laster læringsløp',
    en:'Loading bit-learning',
  },
  LOADING_APP:{
    no:'Laster applikasjon',
    en:'Loading application',
  },
  LOADING_REG:{
    no:'Gjennomføring registreres...',
    en:'Praticipation is registrating...',
  },

  SIGN:{
    no:'Signer',
    en:'Sign',
  },

  SUBMIT_EMPLOYMENT_FORM:{
    no:'Send',
    en:'Send',
  },

  SUBMIT_SEARCH_AND_ADD_MOVE_EMPLOYEE_FORM:{
    no:'Legg til',
    en: 'Add',
  },

  SUBMIT_SEND_NEW_PASSWORD:{
    no:'Send',
    en:'Send',
  },

  SAVE_CHANGES:{
    no:'Lagre endringer',
    en:'Save changes',
  },

  MISSING_MESSAGE_SUBJECT_FIELD:{
    no:'Emne må fylles ut',
    en:'Subject is required',
  },

  MISSING_MESSAGE_BODY_FIELD:{
    no:'Meldingsteksten må fylles ut',
    en:'The message text is required',
  },

  DURATIONS_SEC:{
    HOURS: {
      no:'Timer',
      en:'Hours',
    },
    HOUR: {
      no:'Time',
      en:'Hour',
    },
    MINUTES:{
      no:'Minutter',
      en:'Minutes',
    },
    MINUTE:{
      no: 'Minutt',
      en:'Minute',
    },
    DAYS:{
      no:'Dager',
      en:'Days',
    },
    DAY:{
      no:'Dag',
      en:'Day',
    },

  },

};

const strMap = {
  no,
  en,
};

export const MISSING_TRANSLATION = '[missing translation]';

const loggedErrorsFor = {};

const getCurrLang = () =>  localStorage.getItem('language')
|| (JSON.parse(localStorage.getItem('learningportalConfig')) || {} .params || {})['default-language']
|| 'no';

/**
 * Gets translated strings. Strings are defined in index.jsx strMap-variable. Current language is retrieved automatically from localStorage or configFile
 *
 * Examples:
 *
 *  i18n('NAME') -> gets the translated string for 'NAME'
 *
 *  i18n('NAME', {'lowerCase': true}) -> gets the translated string for 'NAME' in lower case
 *
 *  i18n('NAME', {'ellipsis': true}) -> gets the translated string for 'NAME' with ellipsis: "..." added at the end
 *
 *  i18n('SECTION.PART') -> gets the translated string for strMap['SECTION']['PART']
 *
 *  i18n('HAPPY_BIRTHDAY', {functionArgs: {'to':'Test mc. Test' } }) -> uses the function stored in strMap['HAPPY_BIRTHDAY'] and the arguments
 * given in functionArgs to create a string
 *
 * If not found, defaultValue-parameter or '[missing translation]' is returned.
 */

export const i18n = (key, options, defaultValue = '') => {
  const lang = getCurrLang();
  const compoundKey = key.includes('.');
  let target = compoundKey ? _.get(strMap[lang], key) : strMap[lang] && strMap[lang][key];

  if (options && options.functionArgs && target) {
    Object.keys(options.functionArgs).forEach(op => {
      target = target.replace('[' + op + ']', options.functionArgs[op]);
    });
  }else if (typeof target === 'string' && options) {
    if (options.lowerCase) {
      target = target && target.toLocaleLowerCase();
    }else if (options.upperCase) {
      target = target && target.toLocaleUpperCase();
    }

    if(options.ellipsis) {
      target = lang === 'no' ? `${target} ...` : `${target}...`;
    }

    return target;
  }

  if (!target) {
    if (!loggedErrorsFor[`${lang}-${key}`]) {
      console.error(`[i18n] Missing i18n for key ${key} and lang ${lang}`);
      loggedErrorsFor[`${lang}-${key}`] = true;
    }
    if (defaultValue) {
      return defaultValue;
    }

    return MISSING_TRANSLATION + ' - ' + key;
  }

  return target;
};

export const i18nFormatTimeRange = ({from, to}) => {
  const lang = getCurrLang();

  const formatTimePart = timePart => timePart.toString().padStart(2, '0');

  if(lang === 'no') {
    return `kl. ${formatTimePart(from.hours)}:${formatTimePart(from.minutes)} - ${formatTimePart(to.hours)}:${formatTimePart(to.minutes).toString()}`;
  } else if(lang === 'en') {
    return `${formatTimePart(from.hours)}:${formatTimePart(from.minutes)} - ${formatTimePart(to.hours)}:${formatTimePart(to.minutes).toString()}`;
  }

  return '';
};
