import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import moment from 'moment';
import {i18n} from '@src/i18n';

class ExpiringList extends Component {
  render() {
    const {expiring, isPerson, orgId} = this.props;

    if (!expiring) {
      return null;
    }

    if (isPerson) {
      return (
        <div className="events-list">
          {expiring.map(item => {
            const status = moment(item.date).isBefore(new Date()) ? 'past' : 'not-past';
            return (
              <div className="events-list__item expire">
                <div className="events-list__date">
                  <div className="events-list__date__day">
                    {moment(item.date).format('DD')}
                  </div>
                  <div className="events-list__date__month">
                    {moment(item.date).format('MMM')}
                  </div>
                </div>
                <div className="events-list__card">
                  <div className="events-list__card__contents">
                    <div className="events-list__text_2_title">
                      <Link
                        to={`/course-catalog/${item.competence_id}`}
                        className="activity-list__activity-description"
                      >
                        {`«${item.competence_title}»`}
                      </Link>
                      {status === 'past' && (
                        <div className="events-list__text__description">
                          {i18n('competence.expired', {lowerCase: true})}
&nbsp;
                          {moment(item.date).format('DD. MMM YY')}
                        </div>
                      ) || (
                        <div className="events-list__text__description">
                          {i18n('competence.expires', {lowerCase: true})}&nbsp;
                          {moment(item.date).format('DD. MMM YYYY')}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    const smallExpire = (expiring.length > 2 ? expiring.slice(0,2) : expiring);
    return (
      <div className="events-list">
        {smallExpire.map(item => {
          const status = moment(item.valid_until).isBefore(new Date()) ? 'past' : 'not-past';
          return (
            <div className="events-list__item expire">
              <div className="events-list__date">
                <div className="events-list__date__day">
                  {moment(item.date).format('DD')}
                </div>
                <div className="events-list__date__month">
                  {moment(item.date).format('MMM')}
                </div>
              </div>
              <div className="events-list__card">
                <div className="events-list__card__contents">

                  <div className="events-list__text_2_title">
                    <Link
                      to={`/employees/${orgId}/${item.person.user_name}`}
                      className="activity-list__activity-description"
                    >
                      {item.person.fullname}<br />
                    </Link>
                    <Link
                      to={`/course-catalog/${item.competence_id}`}
                      className="activity-list__activity-description"
                    >
                      {`«${item.competence_title}»`}
                    </Link>
                    {status === 'past' && (
                      <div className="events-list__text__description">
                        {i18n('competence.expired', {lowerCase: true})}
                        &nbsp;
                        {moment(item.date).format('DD. MMM YY')}
                      </div>
                    ) || (
                      <div className="events-list__text__description">
                        løper ut&nbsp;
                        {moment(item.date).format('DD. MMM YYYY')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

ExpiringList.defaultProps = {
  isPerson: false,
  loading: false,
};

ExpiringList.propTypes = {
  expiring: PropTypes.arrayOf(PropTypes.shape({person: PropTypes.shape({}).isRequired})).isRequired,
  isPerson: PropTypes.bool,
  loading: PropTypes.bool,
};


export default ExpiringList;
