import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {searchFilter} from '@routes/reports/input-search-multiple';
import {useOnClickOutside} from '@src/hooks/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useCallback, useEffect, useRef, useState} from 'react';
import Highlighter from 'react-highlight-words';
import SearchBox, {DropdownItem} from '../../../routes/reports/search-box';
import './dropdown-select.scss';

export const optionLabels = (options, key) => ({
  ...options
    && options.reduce(
      (optionsList, option) => ({
        ...optionsList,
        [option[key]]: {...option},
      }),
      {},
    )
    || {},
});

const DropdownSelect = ({
  className,
  options,
  label,
  onChange,
  onSelect,
  selected: defaultSelected,
  optionFilter,
  fullWidth,
  keepOpen,
  renderItem,
  renderColumnHeader,
  rows,
  iconClassName,
  width,
  color,
  backgroundColor,
  centerLabel,
  dropdownAlignRight,
  applyButton,
  searchItems,
  searchItemNameKey,
  searchItemDescriptionKey,
  placeholder,
  smallOptions,
}) => {
  const [open, setOpen] = useState(false);
  const [term, setTerm] = useState('');
  const [searchDropdownOpen, setSearchDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(defaultSelected);

  const shouldKeepOpen = option => {
    if (keepOpen instanceof Function) {
      return keepOpen(option);
    }

    return keepOpen;
  };

  const isOptionSelected = option => {
    if (defaultSelected instanceof Function) {
      return option !== undefined && defaultSelected(option, selected);
    }

    return option === selected;
  };

  const isOptionFiltered = option => optionFilter && !optionFilter(option);

  const onOptionSelected = data => {
    if (!shouldKeepOpen(data)) {
      setOpen(false);
    }
    setTerm('');
    setSelected(data);
    if (onSelect) {
      onSelect(data);
    }
    if (!applyButton && onChange) {
      onChange(data);
    }
  };

  const onApply = () => {
    setOpen(false);
    setTerm('');
    if (onChange) {
      onChange(selected);
    }
  };

  const clearSelection = () => {
    setTerm('');
    if (onSelect) {
      onSelect(null);
    }
    setSelected(null);
  };

  const handleKeyPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

  const setColumnWidths = columnRef => {
    if (!columnRef) {
      return;
    }

    const activeChildren = new Array(columnRef.children.length);

    for (let i = 0; i < columnRef.children.length; i += 1) {
      const {classList, style} = columnRef.children[i];

      activeChildren[i] = classList.contains('dropdown-select__dropdown__item-active');
      if (activeChildren[i]) {
        classList.remove('dropdown-select__dropdown__item-active');
      }

      style.width = 'min-content';
      style.fontWeight = 'normal';
    }

    const maxWidth = [...columnRef.children].reduce((maxWidth, elem) => {
      const elemWidth = elem.clientWidth;

      if (elemWidth > maxWidth) {
        return elemWidth;
      }

      return maxWidth;
    }, 0);

    const {style} = columnRef;

    style.display = 'grid';
    style.gridTemplateColumns = `repeat(auto-fit, minmax(${maxWidth}px, 1fr))`;

    for (let i = 0; i < columnRef.children.length; i += 1) {
      const {classList, style} = columnRef.children[i];

      if (activeChildren[i]) {
        classList.add('dropdown-select__dropdown__item-active');
      }

      style.width = 'auto';
      style.fontWeight = null;
    }
  };

  const isColumns = options && !Array.isArray(options);

  const renderDropdown = () => {
    const getOptionData = (options, option) => {
      if (Array.isArray(options)) {
        return option;
      }

      return options[option];
    };

    const renderOptions = options =>
      (Array.isArray(options) && options || Object.keys(options)).map((option, key) => {
        const selected = isOptionSelected(getOptionData(options, option));
        const filtered = isOptionFiltered(getOptionData(options, option));
        const optionProps = {
          key,
          className: classNames('dropdown-select__dropdown__item', {
            'dropdown-select__dropdown__item-active': selected,
            'dropdown-select__dropdown__item-filtered': filtered,
          }),
          selected,
          role: 'button',
          onMouseDown: () => onOptionSelected(getOptionData(options, option)),
          onKeyPress: e =>
            handleKeyPress(e, () =>
              onOptionSelected(getOptionData(options, option))),
          style: smallOptions ? {padding: '4px 10px'} : null,
          tabIndex: '0',
        };

        return renderItem 
          ? renderItem(getOptionData(options, option), option, optionProps)
          :             <div {...optionProps}>{option}</div>
        ;
      });

    // eslint-disable-next-line no-unused-vars
    const renderColumns = options => {
      const columnHeaderProps = {className: 'dropdown-select__dropdown__column__title'};

      return (
        <div className="dropdown-select__dropdown__column-wrapper">
          {(Object.keys(options) || []).map((name, key) => (
            <div
              className="dropdown-select__dropdown__column"
              key={key}
            >
              {renderColumnHeader 
                ? renderColumnHeader(name, key, columnHeaderProps)
                :                 <div {...columnHeaderProps}>{name}</div>}
              <div
                className="dropdown-select__dropdown__column__content"
                ref={setColumnWidths}
              >
                {renderOptions(options[name])}
              </div>
            </div>
          ))}
        </div>
      );
    };

    return (
      <>
        {Array.isArray(searchItems) && (
          <DropdownSearchBoxWrapper>
            <DropdownSearchBox
              onSearch={term => setTerm(term)}
              value={term}
              onChange={item => onOptionSelected(item)}
              items={searchFilter(searchItems, term, [searchItemNameKey])}
              iconLeft
              rowHeight={42}
              placeholder={placeholder || ''}
              onOpen={open => setSearchDropdownOpen(open)}
              renderItemText={item => (
                <>
                  <DropdownItemText
                    as={Highlighter}
                    searchWords={[term]}
                    autoEscape
                    textToHighlight={
                      searchItemNameKey && item[searchItemNameKey] || item
                    }
                  />
                  {searchItemDescriptionKey && (
                    <DropdownItemDescription>
                      {item[searchItemDescriptionKey]}
                    </DropdownItemDescription>
                  )}
                </>
              )}
              renderDropdown={render => (
                <DropdownRow>
                  <DropdownItems>
                    {!searchItems.length 
                      && <DropdownItem>Fant ingen resultater</DropdownItem>}
                    {render()}
                  </DropdownItems>
                </DropdownRow>
              )}
              closeOnSelect
            />
          </DropdownSearchBoxWrapper>
        )}
        <div
          className="dropdown-select__dropdown__content"
          css={
            searchDropdownOpen
            && 'opacity: 0.2; pointer-events: none; overflow-y: hidden;'
          }
        >
          {isColumns && renderColumns(options) || renderOptions(options)}
        </div>
        {applyButton && (
          <Row
            center
            css={css`flex: 1 0 auto; padding: 20px; border-top: 2px solid #eeeeee;`}
          >
            <Button
              css={css`margin-right: 12px;`}
              onClick={() => onApply()}
            >
              Filtrer
            </Button>
            <Button
              secondary
              onClick={() => clearSelection()}
              disabled={!selected}
            >
              Nullstill
            </Button>
          </Row>
        )}
      </>
    );
  };

  const onBlur = () => {
    if (!searchDropdownOpen) {
      setTerm('');
      setOpen(false);
    }
  };

  const buttonRef = useRef();
  const dropdownRef = useRef();

  useOnClickOutside([buttonRef, dropdownRef], onBlur);

  const onScroll = useCallback(() => {
    if (!dropdownRef.current) {
      return;
    }

    const {top} = dropdownRef.current.getBoundingClientRect();
    const dropdownHeight = window.innerHeight - top;
    const {style} = dropdownRef.current;

    style.maxHeight = `${dropdownHeight - 8}px`;
  }, []);

  const appContentElement = document
    .querySelector('.app-wrapper__content');

  useEffect(() => {
    appContentElement?.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    window.addEventListener('orientationchange', onScroll);
    onScroll();

    return () => {
      appContentElement?.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
      window.removeEventListener('orientationchange', onScroll);
    };
  }, [appContentElement, onScroll]);

  return (
    <>
      <div
        className={classNames(
          'dropdown-select',
          {'dropdown-select--open': open},
          className,
        )}
        style={{
          backgroundColor,
          width,
        }}
        ref={buttonRef}
        role="button"
        onClick={() => setOpen(!open)}
        onKeyPress={e => handleKeyPress(e, () => setOpen(!open))}
        tabIndex="0"
      >
        <div
          className="dropdown-select__label"
          style={{
            color,
            textAlign: centerLabel ? 'center' : 'left',
          }}
        >
          {label}
        </div>
        <i
          className={classNames(
            iconClassName || 'fas fa-angle-down',
            'dropdown-select__arrow',
          )}
          style={{color}}
        />
        {open && !fullWidth && (
          <div
            className={classNames('dropdown-select__dropdown', {
              'dropdown-select__dropdown-rows': rows,
              'dropdown-select__dropdown-rightalign': dropdownAlignRight,
            })}
            ref={current => {
              dropdownRef.current = current;
              onScroll();
            }}
          >
            {renderDropdown()}
          </div>
        )}
      </div>
      {open && fullWidth && (
        <div className="dropdown-select__dropdown-fullwidth-wrapper">
          <div
            className={classNames('dropdown-select__dropdown-fullwidth', {'dropdown-select__dropdown-rows': rows})}
            ref={current => {
              dropdownRef.current = current;
              onScroll();
            }}
          >
            {renderDropdown()}
          </div>
        </div>
      )}
    </>
  );
};

DropdownSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  selected: PropTypes.any,
  optionFilter: PropTypes.func,
  fullWidth: PropTypes.bool,
  keepOpen: PropTypes.any,
  renderItem: PropTypes.func,
  renderColumnHeader: PropTypes.func,
  rows: PropTypes.bool,
  iconClassName: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  centerLabel: PropTypes.bool,
  dropdownAlignRight: PropTypes.bool,
  applyButton: PropTypes.bool,
  searchItems: PropTypes.array,
  searchItemNameKey: PropTypes.string,
  searchItemDescriptionKey: PropTypes.string,
  placeholder: PropTypes.string,
  smallOptions: PropTypes.bool,
};

DropdownSelect.defaultProps = {
  className: null,
  label: null,
  onSelect: null,
  onChange: null,
  selected: null,
  optionFilter: null,
  fullWidth: false,
  keepOpen: false,
  renderItem: null,
  renderColumnHeader: null,
  rows: false,
  iconClassName: null,
  width: null,
  color: null,
  backgroundColor: null,
  centerLabel: false,
  dropdownAlignRight: false,
  applyButton: false,
  searchItems: null,
  searchItemNameKey: null,
  searchItemDescriptionKey: null,
  placeholder: null,
  smallOptions: false,
};

const DropdownSearchBoxWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
`;
const DropdownSearchBox = styled(SearchBox)`
  background-color: #fff;
  border-radius: 12px;
  border: 2px solid #cdcdcd;
`;

const DropdownItemText = styled.div`
  flex: 1 1 auto;

  mark {
    font-weight: 600;
    background: none;
    color: inherit;
  }
`;
const DropdownItemDescription = styled.div`
  flex: 0 1 auto;
  color: #9d9d9d;
`;

const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const DropdownItems = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${({center}) =>
  center
    && css`
      justify-content: center;
    `}
`;

const Button = styled.button`
  border: none;
  border-radius: 5px;
  background-color: #618da7;
  cursor: pointer;
  padding: 16px 40px;
  flex: 0 1 auto;
  margin: 0;
  align-self: flex-end;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  transition: background 0.115s ease-out, color 0.115s ease-out;

  :hover,
  :active {
    background-color: #4f748a;
  }

  ${({secondary, disabled}) =>
  secondary
      && css`
        padding: 16px;
        background-color: transparent;

        ${disabled
          && css`
            color: #b6bac5;
            cursor: default;

            :hover,
            :active {
              color: #b6bac5;
              background-color: transparent;
            }
          `
          || css`
            color: #6590a9;

            :hover,
            :active {
              color: #6590a9;
              background-color: #f0f0f0;
            }
          `}
      `
    || disabled
      && css`
        background-color: #dededc;
        color: #b6bac5;
        cursor: default;

        :hover,
        :active {
          background-color: #dededc;
        }
      `}
`;

export default DropdownSelect;
