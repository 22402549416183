/* eslint-disable no-debugger */
import React, {useState, useEffect} from 'react';

import {css, jsx} from '@emotion/react';
import {getImageUrl} from '@utils/misc.utils';
import {
  Wrapper,
  Image,
  ContentWrapper,
  AuthorAvatar,
  AuthorName,
  HeaderWrapper,
  Header,
  Category,
} from './styles';

export const CarouselCard = ({
  authorName,
  authorImage,
  category,
  text,
  teaser,
  image,
  imageAltText,
  isMobile,
  onClick,
  variant = 'large',
  mobileBreakpoint,
  mobileWrapperStyle,
  ...props
}) => (
  <Wrapper
    className="carousel-card-wrapper"
    {...props}
    role="button"
    tabIndex="0"
    mobileBreakpoint={mobileBreakpoint}
    variant={variant}
    onClick={onClick}
    isMobile={isMobile}
    mobileWrapperStyle={mobileWrapperStyle}
  >
    {variant === 'listCard' && (
      <Category
        className="category"
        variant="listCard"
      >
        {category}
      </Category>
    )}
    <Image
      className="cover-image"
      src={getImageUrl(image)}
      aria-label={imageAltText}
      variant={variant}
    >
      <AuthorAvatar
        hide={!authorImage}
        src={getImageUrl(authorImage)}
        variant={variant}
        className="avatar-img"
      />
    </Image>
    <ContentWrapper
      variant={variant}
      className="content-wrapper"
    >
      <HeaderWrapper
        variant={variant}
        className="header-wrapper"
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {category && (
            <div css={{transform: 'translate(0, 16px)'}}>
              <p
                css={{
                  margin: 0,
                  color: '#222',
                }}
              >
                {category}
              </p>
            </div>
          )}
          <Header
            variant={variant}
            className="header"
            dangerouslySetInnerHTML={{__html: text}}
          />

          {teaser && (
            <div
              className="html"
              dangerouslySetInnerHTML={{__html: teaser}}
            />
          )}

          {authorName && (
            <AuthorName
              variant={variant}
              hide={!authorName}
              className="author-name"
            >
              {authorName && `Trenertips med ${authorName}` || null}
            </AuthorName>
          )}
        </div>
      </HeaderWrapper>
    </ContentWrapper>
  </Wrapper>
);
