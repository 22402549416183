import {profileFetchCv} from '@actions/profile.actions';
import {getCV, getProfile} from '@selectors/profile.selectors';
import React, {useEffect, useState} from 'react';
import {css, jsx} from '@emotion/react';
import {useDispatch, useSelector} from 'react-redux';

import {NoDataSection, LoadingWrapper, Wrapper, TopDescription, ContentWrapper, MainHeader, ErrorIcon, TopBar} from './cv-styles';
import {ActionButton} from '@components/buttons/actionButton';
import {InfoCollection} from './cv-components';

import {CVView} from './view/cv-view';
import {CVEdit} from './edit/cv-edit';

// UIDs are unique identifiers for each experience used to indentify which experience to manage. It is necesarry bacause the experiences may
// not yet have assigned ids.
// experience types listed in typesToOnlyGetYearFromDate will get convrerted to years
const divideByTypeAssignUIDFixDate = (CVData, typesToOnlyGetYearFromDate) => {
  const dividedByType = {};
  // eslint-disable-next-line prefer-destructuring
  const experiences = CVData.experiences;

  if (!experiences) {
    return dividedByType;
  }
  let UIDcounter = 0;

  for (const currExperience of experiences) {
    const currKey = currExperience.experience_type;

    if (!dividedByType[currKey]) {
      dividedByType[currKey] = [];
    }

    if (currExperience.startdate) {
      currExperience.startdate = new Date(currExperience.startdate);
    }

    if (currExperience.enddate) {
      currExperience.enddate = new Date(currExperience.enddate);
    }

    currExperience.UID = UIDcounter;
    UIDcounter += 1;
    dividedByType[currKey].push(currExperience);
  }

  return dividedByType;
};

export const CvContainer = ({}) => {
  const modeStrings = {
    edit: {
      actionBtnTxt: 'Tilbake',
      header: 'Rediger CV',
    },
    view: {
      actionBtnTxt: 'Rediger',
      header: 'CV',
    },
  };

  const CV = useSelector(getCV);
  const profile = useSelector(getProfile);

  const dispatch = useDispatch();
  const [mode, setMode] = useState('view');
  const disableEdit = false;
  const manageModeToUse = disableEdit ? 'view' : mode;


  const onChangeManageMode = () => {
    setMode(old => old === 'view' ? 'edit' : 'view');
  };
  const prepareCVData = CVData =>
    divideByTypeAssignUIDFixDate(CVData, ['education']);

  useEffect(() => {
    if(!CV.data && !CV.isFetching && !CV.error) {
      dispatch(profileFetchCv());
    }
  }, [CV, CV.data, CV.error, CV.isFetching, dispatch]);

  if (CV.error) {
    return (
      <NoDataSection>
        <InfoCollection
          title={modeStrings[manageModeToUse].header}
        />
        <LoadingWrapper>
          <ErrorIcon className="fas fa-exclamation-circle" />
          {' '}
          Det oppsto en feil
          ved henting av dataen
        </LoadingWrapper>
      </NoDataSection>
    );
  }

  if (CV.isFetching || !CV.data) {
    return (
      <NoDataSection>
        <InfoCollection
          title={modeStrings[manageModeToUse].header}
        />
        <LoadingWrapper>
          <i className="fa fa-spin fa-spinner" />
          {' '}
          Laster...
        </LoadingWrapper>
      </NoDataSection>
    );
  }


  return (
    <Wrapper mode={manageModeToUse}>

      {!profile.data && <p>Laster...</p>}

      {profile.data 
      && (
        <>
          <TopBar>
            <TopDescription>Project manager</TopDescription>
            <ActionButton
              onClick={onChangeManageMode}
              variant="submit"
              theme="blue"
              css={css`
                margin-top: 0;
                margin-left: auto;
                margin-right: 1.1rem;
              `}
            >
              {modeStrings[manageModeToUse].actionBtnTxt}
            </ActionButton>
          </TopBar>
          <ContentWrapper>

            <MainHeader>
              {profile.data.fullname}
            </MainHeader>

            <InfoCollection>
              {manageModeToUse === 'view'
                ? (
                  <CVView
                    experienceData={CV.data}
                    fullwidth
                    disableEdit={disableEdit}
                    experiencesDividedByType={prepareCVData(CV.data)}
                    description={CV.data.description}
                  />
                )
                : (
                  <CVEdit
                    initialExperienceData={prepareCVData(CV.data)}
                    initialDescription={CV.data.description}
                    onSave={() => {
                      onChangeManageMode();
                    }}
                    cvID={CV.data.id}
                  />
                )}
            </InfoCollection>
          </ContentWrapper>  
        </>
      )}
    </Wrapper>
  );
};


