import React, {
  Fragment,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import Highlighter from 'react-highlight-words';
import SearchBox, {DropdownItem} from './search-box';
import {
  Loading,
  DropdownItemText,
  DropdownItemDescription,
  DropdownRow,
  DropdownFilters,
  DropdownItems,
  DropdownFilterCheckbox,
  ItemRow,
  ItemLabel,
  ItemPlus,
  ItemLabelButton,
  ItemLabelIcon,
} from './styles/input-styles';
import {useAxiosFetch} from '@snapper/core';
import {faAsterisk} from '@fortawesome/pro-solid-svg-icons';

export const searchFilter = (dataset, query, keys) => {
  if (!query || !query.trim()) {
    return dataset;
  }

  const simpleStr = str =>
    str
      .toString()
      .toLowerCase()
      .replace(/[^\da-zäåæöø]/g, '');

  return keys.reduce((results, key) => {
    const elements
      = key && dataset.map(item => item[key])
      || Array.isArray(dataset) && dataset
      || Object.keys(dataset);

    return [...results, ...dataset.filter((item, index) =>
      simpleStr(elements[index]).includes(simpleStr(query)))];
  }, []);
};

export const InputSearchMultiple = forwardRef((
  {
    url,
    selectRequired,
    getResponseDataKey,
    nameKey,
    getTypeNameKey,
    getTypeIdKey,
    showItemId,
    showTypeNameInBadge,
    params,
    onChange,
    ...props
  },
  ref,
) => {
  const [filters, setFilters] = useState([]);
  const [changed, setChanged] = useState(false);

  const {data, fetch, loading} = useAxiosFetch(
    {
      method: 'GET',
      url,
      withCredentials: true,
    },
    [],
  );

  const [items, setItems] = useState([]);
  const [requiredIds, setRequiredIds] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);

  const toggleRequired = useCallback(id => {
    setRequiredIds(ids => ({
      ...ids,
      [id]: !ids[id],
    }));
    setChanged(true);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    if (loading || !data) {
      return;
    }

    const items = getResponseDataKey(data);

    if (!items) {
      return;
    }

    items.sort((item1, item2) =>
      (getTypeIdKey && getTypeIdKey(item1) || 0) - (getTypeIdKey && getTypeIdKey(item2) || 0) || item1?.[nameKey]?.localeCompare(item2?.[nameKey]));
    
    setItems(items);

    const filters = {};

    if (getTypeIdKey && getTypeNameKey) {
      items.forEach(item => {
        const id = getTypeIdKey(item);
        const text = getTypeNameKey(item);
  
        filters[id] = {
          text,
          type: id,
          enabled: false,
        };
      });
    }
    setFilters(Object.values(filters));
  }, [data, loading, getResponseDataKey, getTypeIdKey, getTypeNameKey, nameKey]);

  const [term, setTerm] = useState('');

  const [value, setValue] = useState([]);
  const [requestSelectIds, setRequestSelectIds] = useState(null);

  const noFiltersEnabled = useMemo(() => filters
    .map(({enabled}) => enabled)
    .every(enabled => enabled === false), [filters]);

  const selectedFilterTypes = useMemo(() => filters.reduce(
    (result, {type, enabled}) =>
      enabled ? [...result, type] : result,
    [],
  ), [filters]);

  const toggleFilter = key => {
    setFilters(filters => {
      const newFilters = [...filters];

      newFilters[key].enabled = !newFilters[key].enabled;

      return newFilters;
    });
  };

  const onItemSelected = item => {
    setValue(values => [...values, item]);
    setTerm('');
    setChanged(true);
  };
  
  const removeAtIndex = index => {
    setValue(values => {
      values.splice(index, 1);
      
      return [...values];
    });
    setChanged(true);
  };
  
  useImperativeHandle(ref, () => ({setSelectedIds: setRequestSelectIds}));
  
  useEffect(() => {
    if (requestSelectIds) {
      if (!requestSelectIds.length) {
        setValue([]);
        setRequestSelectIds(null);
        setChanged(true);
      } else if (items) {
        const value = items.filter(({id}) => requestSelectIds.includes(id));
        
        if (value.length) {
          setValue(value);
          setRequestSelectIds(null);
          setChanged(true);
        }
      }
    }
  }, [items, requestSelectIds]);

  useEffect(() => {
    setFilteredItems(items
      ? searchFilter(
        items.filter(item =>
          !value.includes(item)
          && (noFiltersEnabled || !getTypeIdKey || selectedFilterTypes.includes(getTypeIdKey(item)))),
        term,
        [nameKey, 'id'],
      )
      : []);
  }, [getTypeIdKey, items, nameKey, noFiltersEnabled, selectedFilterTypes, term, value]);

  useEffect(() => {
    if (changed && onChange) {
      onChange(value.map(item => ({
        ...item,
        required: requiredIds[item.id],
      })));
    }
    setChanged(false);
  }, [value, onChange, changed, requiredIds]);

  return (
    <>
      <SearchBox
        {...props}
        loading={loading}
        onSearch={term => {
          setTerm(term);
        }}
        value={term}
        onChange={onItemSelected}
        items={filteredItems}
        rowHeight={42}
        renderItemText={item => (
          <>
            <DropdownItemText
              as={Highlighter}
              searchWords={[term]}
              autoEscape
              textToHighlight={item?.[nameKey]}
            />
            {getTypeNameKey
              ? showItemId
                ? (
                  <DropdownItemDescription>
                    {getTypeNameKey(item)}
                    {' '}
                    / #
                    {item.id}
                  </DropdownItemDescription>
                )
                : <DropdownItemDescription>{getTypeNameKey(item)}</DropdownItemDescription>
              : null}
          </>
        )}
        renderDropdown={render => (
          <DropdownRow>
            {filteredItems
              && filters.length > 1 && (
                <DropdownFilters>
                  {filters.map(({text, type: filterType, enabled}, index) => (
                    <DropdownFilterCheckbox key={index}>
                      <input
                        type="checkbox"
                        checked={enabled}
                        onChange={() => toggleFilter(index)}
                      />
                      {text}
                    </DropdownFilterCheckbox>
                  ))}
                </DropdownFilters>
            )}
            <DropdownItems>
              {!filteredItems?.length
                && (loading && (
                  <DropdownItem>
                    <Loading />
                  </DropdownItem>
                ) || <DropdownItem>Fant ingen resultater</DropdownItem>)}
              {render()}
            </DropdownItems>
          </DropdownRow>
        )}
      />
      <ItemRow>
        {value.map((item, index) => (
          <Fragment key={index}>
            {!!index && (
              <ItemPlus>
                <i className="fa fa-plus" />
              </ItemPlus>
            )}
            <ItemLabel
              required={requiredIds[item.id]}
              onClick={(e => {
                if (selectRequired) {
                  toggleRequired(item.id);
                  e.stopPropagation();
                }
              })}
              onKeyPress={e => {
                if (selectRequired && e.key === 'Enter') {
                  toggleRequired(item.id);
                  e.stopPropagation();
                }
              }}
              tabIndex="0"
              role="button"
            >
              {requiredIds[item.id] 
                && <ItemLabelIcon icon={faAsterisk} />}
              {showTypeNameInBadge
                ? `${getTypeNameKey(item)}: ${item?.[nameKey]}`
                : item?.[nameKey]}
              {selectRequired && (
                <ItemLabelButton>
                  <i className="fa fa-exchange-alt" />
                </ItemLabelButton>
              ) || null}
              <ItemLabelButton
                onClick={e => {
                  removeAtIndex(index);
                  e.stopPropagation();
                }}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    removeAtIndex(index);
                    e.stopPropagation();
                  }
                }}
                tabIndex="0"
                role="button"
              >
                <i className="fa fa-times" />
              </ItemLabelButton>
            </ItemLabel>
          </Fragment>
        ))}
      </ItemRow>
    </>
  );
});
