const prefix = 'roles';

export const FETCH_ROLE = `${prefix}/fetch/role`;
export const FETCH_ROLE_REQUEST = `${prefix}/fetch/role/request`;
export const FETCH_ROLE_SUCCESS = `${prefix}/fetch/role/success`;
export const FETCH_ROLE_FAILURE = `${prefix}/fetch/role/failure`;


export const FETCH_ROLES = `${prefix} fetch roles`;
export const FETCH_ROLES_REQUEST = `${prefix} fetch roles request`;
export const FETCH_ROLES_SUCCESS = `${prefix} fetch roles success`;
export const FETCH_ROLES_FAILURE = `${prefix} fetch roles failure`;

export const FETCH_ROLES_REQUIRED = `${prefix} fetch roles required`;
export const FETCH_ROLES_REQUIRED_REQUEST = `${prefix} fetch roles required request`;
export const FETCH_ROLES_REQUIRED_SUCCESS = `${prefix} fetch roles required success`;
export const FETCH_ROLES_REQUIRED_FAILURE = `${prefix} fetch roles required failure`;

export const fetchRoles = payload => ({
  type: FETCH_ROLES,
  payload,
});

export const fetchRole = payload => ({
  type: FETCH_ROLE,
  payload,
});

export const fetchRoleRequest = payload => ({
  type: FETCH_ROLE_REQUEST,
  payload,
});

export const fetchRoleSuccess = payload => ({
  type: FETCH_ROLE_SUCCESS,
  payload,
});

export const fetchRoleFailure = payload => ({
  type: FETCH_ROLE_FAILURE,
  payload,
});

export const fetchRolesRequest = () => ({type: FETCH_ROLES_REQUEST});

export const fetchRolesSuccess = payload => ({
  type: FETCH_ROLES_SUCCESS,
  payload,
});

export const fetchRolesFailure = payload => ({
  type: FETCH_ROLES_FAILURE,
  payload,
});

export const fetchRolesRequired = () => ({type: FETCH_ROLES_REQUIRED});

export const fetchRolesRequiredRequest = () => ({type: FETCH_ROLES_REQUIRED_REQUEST});

export const fetchRolesRequiredSuccess = payload => ({
  type: FETCH_ROLES_REQUIRED_SUCCESS,
  payload,
});

export const fetchRolesRequiredFailure = payload => ({
  type: FETCH_ROLES_REQUIRED_FAILURE,
  payload,
});

export const FETCH_ORGANISATIONS = `${prefix} fetch organisations`;
export const FETCH_ORGANISATIONS_REQUEST = `${prefix} fetch organisations request`;
export const FETCH_ORGANISATIONS_SUCCESS = `${prefix} fetch organisations success`;
export const FETCH_ORGANISATIONS_FAILURE = `${prefix} fetch organisations failure`;

export const fetchOrganisations = payload => ({
  type: FETCH_ORGANISATIONS,
  payload,
});

export const fetchOrganisationsRequest = () => ({type: FETCH_ORGANISATIONS_REQUEST});

export const fetchOrganisationsSuccess = payload => ({
  type: FETCH_ORGANISATIONS_SUCCESS,
  payload,
});

export const fetchOrganisationsFailure = payload => ({
  type: FETCH_ORGANISATIONS_FAILURE,
  payload,
});
