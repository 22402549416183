import '../styles/messages-list.scss';
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Message from './message';
import axios from 'axios';

import {backendUrl} from '@config';
import {Calendar} from '../../../common/components/calendar/calendar';

const MessagesList = ({messages}) => {
  const [messageData, setMessageData] = useState(null);

  const loadMessageData = () => {
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/messages`,
        withCredentials: true,
      })
      .then(({data: {messages: message}}, // etc
      ) => setMessageData(message));
  };

  useEffect(() => {
    loadMessageData();
  }, []);

  return (
    <>
      <Calender />
      <div className="messages-list">
        {messages.length === 0
          && <div className="nomsg">Du har ikke motatt noen meldinger.</div>
         || (
           <div className="messages-wrapper">
             {messageData
              && messageData.map(message => (
                <Message
                  key={message.message_id}
                  message={message}
                />
              ))}
           </div>
         )}
      </div>
    </>
  );
};

MessagesList.propTypes = {messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired};

export default MessagesList;
