import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';
const _ = require('lodash');

export const Wrapper = styled.div`
    background: ${rgba('#BE4BDB', 0.4)};
    border-radius: 8px;
    padding: 1.1em;
    margin-bottom: 2.5em;
    margin-top: 1.15em;
`;
export const InfoPart = styled.div`
  display: flex;
`;
export const DayInMonth = styled.div`
    border-radius: 4px;
    background: #FFFFFF;

    padding: 0.5em;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-weight: 599;font-size: 0.95em;
`;
export const ActionButtonPart = styled.div``;
export const Name = styled.div`
    font-weight: 500;
    font-size: 0.95em;
`;
export const Info = styled.div`
    font-size: 0.8em;
`;
export const Left = styled.div`
    margin-right: 1.2em;
`;
export const Right = styled.div``;
