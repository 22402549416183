import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';
const _ = require('lodash');

export const MainPart = styled.div`
    width: ${props => props.classRoom ? '70%' : '100%'};
    margin-right: ${props => props.classRoom ? '4em' : '0'};
`;
export const Sidepanel = styled.div`
    width: 30%;
`;
export const ContentWrapper = styled.div`
    display: flex;
`;

export const Participants = styled.div`

`;
