import moment from 'moment';

export const maskDate = value => value && moment(value, 'DD.MM.YYYY').toDate();
export const validateDate = dateStr => {
  if (!dateStr) {
    return undefined;
  }

  try {
    if (moment(dateStr, 'DD.MM.YYYY', true).isValid()) {
      return undefined;
    }
  } catch {
    //
  }

  return 'Ugyldig dato';
};
