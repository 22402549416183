/* eslint-disable react/prop-types */
import {useLayoutEffect, useState} from 'react';
import {jsx, css} from '@emotion/react';
import {useDispatch, useSelector} from 'react-redux';
import {faLock} from '@fortawesome/pro-solid-svg-icons';
import {withRouter} from 'react-router';
import {coursesStartCourse} from '@actions/courses.actions';
import {selectMapCourse} from '@actions/map.actions';
import {ReactComponent as Hexagon} from '@src/assets/svg/map/Hexagon.svg';
import {ReactComponent as Dot} from '@src/assets/svg/map/Dot.svg';
import {getConfigObject} from '@selectors/config.selectors';
import {stack, Base, text, Title, Lock, CourseNumber} from './styles';

export const Item = withRouter(({
  history: {push},
  absolute,
  x,
  y,
  index,
  course,
  course: {id, type, status: actualStatus, title, img, href},
  activeCourseID,
  clickMode,
  completedCourses,
  animateCompleted,
  disableInteraction,
}) => {
  const status
      = actualStatus === 'DONE' && completedCourses <= index - 1 && 'DONE'
      || actualStatus;

  const config = useSelector(getConfigObject);

  const mapHexagons = config.getProperty('map.useHexagonSvg');

  const dispatch = useDispatch();

  const handleClick = () => {
    if (status === 'LOCKED') {
      return;
    }
    if (href) {
      push(href);
      window.location = href;
    } else {
      if (clickMode === 'OPEN_COURSEPLAYER') {
        dispatch(coursesStartCourse({
          cid: id,
          type,
        }));
      } else if (clickMode === 'SELECT_ACTIVE_COURSE' && activeCourseID !== id) {
        dispatch(selectMapCourse(course));
      }
    }
  };

  const [prevCompletedCourses, setPrevCompletedCourses] = useState(completedCourses);

  const [animateUnlocked, setAnimateUnlocked] = useState(false);

  const locked = status === 'LOCKED' || completedCourses < index - 1;
  const active = activeCourseID === id && !locked;

  useLayoutEffect(() => {
    if (completedCourses !== prevCompletedCourses) {
      setAnimateUnlocked(status === 'OPEN'
            && completedCourses >= index - 1
            && (prevCompletedCourses < index - 1 || index === 1));
      setPrevCompletedCourses(completedCourses);
    }
  }, [completedCourses, index, prevCompletedCourses, status]);

  const isIntroCourse = index === 0;

  return (
    <div
      css={absolute
        ? css`
          position: absolute;
          left: ${x}px;
          top: ${y}px;
          transform: translate(-50%, -50%);
          flexDirection: column;
          justifyContent: center;
          alignItems: center;
          display: flex;
          &.focus-visible: {
            border: 3px solid gray;
          }
        `
        : stack}
      role="button"
      tabIndex={disableInteraction ? -1 : 0}
      onKeyDown={evt => {
        if (evt.key === 'Enter') {
          handleClick();
        }
      }}
    >
      {!img
        ? (
          <Base
            onClick={handleClick}
            role="button"
            className={`map-dot-button ${
            disableInteraction && 'disableInteraction'
          }`}
            disableInteraction={disableInteraction}
            aria-hidden={disableInteraction}
            status={status}
            active={active}
            animateCompleted={animateCompleted}
            animateUnlocked={animateUnlocked}
            lockedOpacity={!mapHexagons}
            locked={locked}
          >
            {mapHexagons ? <Hexagon /> : <Dot />}
            <div css={text}>
              <CourseNumber
                aria-label={isIntroCourse ? 'Intro ' : 'Del nummer'}
                animateUnlocked={animateUnlocked}
                locked={locked}
              >
                {index + 1}
              </CourseNumber>
            </div>
            {mapHexagons && (locked || animateUnlocked) && (
              <Lock
                icon={faLock}
                animateUnlocked={animateUnlocked}
                aria-hidden="true"
              />
            )}
          </Base>
        )
        : (
          <img
            src={img}
            onClick={handleClick}
            style={{
              maxWidth: 'inherit',
              cursor: 'pointer',
            }}
          />
        )}
      {!absolute && (
        <Title
          absolute={absolute}
          dangerouslySetInnerHTML={{__html: title}}
        />
      )}
      {locked && (
        <p
          css={css`
              position: absolute;
              width: 0;
              height: 0;
              overflow: hidden;
              display: none;
            `}
        >
          Låst
        </p>
      )}
    </div>
  );
});
