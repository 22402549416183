import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {mq, or} from '@styles/facepaint';
import {motion} from 'framer-motion';

export const Header = styled('h1')({}, props => ({
  color: 'black',
  wordBreak: 'break-word',
  [or(mq.medium, mq.large)]: {marginBottom: '1.7em'},
  [mq.small]: {marginBottom: '1em'},
  marginTop: !props.hasAuthorInfo ? '0.85em' : 'unset',
}));

export const Wrapper = styled('div')({
  paddingBottom: '5em',
  alignSelf: 'center',
  justifySelf: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  wordBreak: 'break-word',
});

export const GradientOverlay = styled('div')({}, props => ({
  background: props.gradient,
  position: 'absolute',
  width: '100%',
  height: props.height,
  zIndex: -1,
}));

export const textElementBaseStyle = {
  margin: '0 auto',
  maxWidth: '768px',
  [mq.small]: {
    width: '100%',
    padding: '0 5%',
  },
  [mq.medium]: {
    width: '100%',
    padding: '0 6%',
  },
  [mq.large]: {
    width: '80%',
    padding: 0,
  },
};

export const Teaser = styled('div')({
  fontWeight: 'bold',
  marginBottom: '2em',
});

export const Body = styled('p')({
  p: {margin: '1.3em 0'},
  h2: {fontSize: '1em'},
  li: {marginBottom: '1.4em'},
});

export const TeaserImg = styled('img')({
  maxHeight: '650px',
  width: 'auto',
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
});

export const TeaserImgContainer = styled('figure')({
  margin: 0,
  marginBottom: '1.2em',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '910px',
  [mq.small]: {marginTop: '0'},
  [or(mq.medium, mq.large)]: {marginTop: '2.9em'},
});

export const TeaserImgCaption = styled('figcaption')({
  margin: '0',
  fontSize: '0.8em',
  display: 'block',
  textAlign: 'left',
  marginTop: '0.45em',
});

export const animateVisibleHiddenVariants = {
  visible: {
    opacity: 1,
    filter: 'blur(0)',
    background: 'none',
    scale: 1,
    height: 'auto',
    transition: {
      ease: 'easeOut',
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.9,
    filter: 'blur(0.2em)',
    height: '437px',
  },
};

export const AuthorHeader = styled('p')({
  textTransform: 'uppercase',
  margin: '1em 0',
  fontSize: '0.7em',
  textAlign: 'center',
  fontWeight: '600',
});

export const AuthorAvatar = styled('img')({
  borderRadius: '50%',
  filter: 'grayscale(100%)',
  width: '4em',
  height: '4em',
  objectFit: 'cover',
  alignSelf: 'center',
  border: '1px solid  #00000026',
});

export const AuthorInfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1.75em',
});

export const TeaserImageAnimationWrapper = styled(motion.div)``;

export const ArticleContentWrapper = styled('div')({...textElementBaseStyle});

export const VideoPlayerOuterWrapper = styled(motion.div)({
  ...textElementBaseStyle,
  marginTop: '2.9em',
});

export const VideoPlayerInnerWrapper = styled('div')({}, props => ({
  visibility: props.hide ? 'hidden' : 'visible',
  position: 'relative',
  paddingTop: '56.25%',
  '.player': {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
}));
