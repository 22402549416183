import React from 'react';
import {jsx, css} from '@emotion/react';
import {rgba} from 'polished';

import {container, overlay, img} from './styles';

export const ImageWithOverlay = ({
  color = '#000',
  src,
  imagePosition = 'initial',
  customCssWrapper,
  customCssImageParts,
  colorAlpha = 0.5,
}) => (
  <div css={[container, {...customCssWrapper}]}>
    <div
      css={css(
        overlay,
        css`
            transition: background 0.2s ease-out;
          `,
        {background: color && rgba(color, colorAlpha)},
        {...customCssImageParts},
      )}
    />
    <div
      css={[
        img,
        {
          backgroundImage: `url(${src})`,
          backgroundPosition: imagePosition,
        },
        {...customCssImageParts},
      ]}
    />
  </div>
);
