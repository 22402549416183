/* eslint-disable react/prop-types */
import React from 'react';
import {jsx} from '@emotion/react';
import {CourseInformationPane} from '@routes/atlas/components/CourseInformationPane/CourseInformationPane';
import {CourseInformationTitle} from '@routes/atlas/components/CourseInformationTitle/CourseInformationTitle';
import {CourseInformationDescription} from '@routes/atlas/components/CourseInformationDescription/CourseInformationDescription';
import {CourseInformationButton} from '@routes/atlas/components/CourseInformationButton/CourseInformationButton';
import {CourseInformationContent} from '@routes/atlas/components/CourseInformationContent/CourseInformationContent';
import {i18n} from '@src/i18n';

export const CourseInformation = ({
  coursesInfo: {id, title, description},
  startSelectedCourse,
  selectedCourse,
  findCourseIndex,
}) => (
  <CourseInformationPane>
    <CourseInformationContent>
      {findCourseIndex(id) - 1 !== -1 && (
        <div className="course-information-part">
          {i18n('map.part')}
          {' '}
          {findCourseIndex(id)}
        </div>
      )}
      <CourseInformationTitle title={title} />
      {description && (
        <CourseInformationDescription>
          <span dangerouslySetInnerHTML={{__html: description}} />
        </CourseInformationDescription>
      )}
    </CourseInformationContent>
    {startSelectedCourse && (
      <CourseInformationButton
        className="course-action-button"
        onClick={startSelectedCourse}
        text={
          selectedCourse.status !== 'LOCKED'
            ? i18n('map.start')
            : i18n('map.locked')
        }
        disabled={selectedCourse.status === 'map.locked'}
      />
    )}
  </CourseInformationPane>
);
