export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  mobileCourseCatalog: '1000px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const fontSize = {
  ss: '0.7rem',
  s: '0.8rem',
  sm: '0.9rem',
  m: '1rem',
  ml: '1.1rem',
  l: '1.2rem',
  xl: '1.5rem',
  xxl: '2rem'
}

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};
