import {shape, bool} from 'prop-types';

export const fetchedDataWrapperShape = data => {
  if (!data) {
    throw new Error('fetchedDataWrapperShape: data model is not defined');
  }

  return shape({
    isFetching: bool.isRequired,
    data,
    error: shape({}),
  });
};
