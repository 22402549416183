import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {rgb} from 'chalk';
import {darken, lighten} from 'polished';

export const Wrapper = styled.div`
  display: flex;
  background: #495866;
  color: #ffffff;
  padding: 4.5em 0em;
  position: relative;
`;

export const ContentWrapper = styled.div`
  display: flex;
  max-width: 950px;
  margin: 0 auto;
  width: 100%;
`;

export const LeftPart = styled.div``;
export const RightPart = styled.div`
  margin-left: auto;
`;
export const Heading = styled.p`
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 0.4em;
  margin-top: 0;
`;
export const SubHeading = styled.p`
  color: white;
  margin: 0 !important;
  font-weight: 500;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin: 1.5em 0em;
`;


export const PersonInfoWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5em;
`;
export const Personalia = styled.div`
  margin-left: 1.5em;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.9em;
`;
