export const getRoles = ({roles: {roles}}) => roles;
export const getOrganisations = ({roles: {organisations}}) => organisations;
export const getRequired = ({roles: {required}}) => required;

export const getRoleId = ({roles: {required}}) => {
  if (!required.data) {
    return null;
  }

  return null;
};

export const getRequiredIds = ({roles: {required}}) => {
  if (!required.data) {
    return [];
  }
  const roleId = 1;
  const ids = [];

  for (let i = 0; i < required.data.length; i += 1) {
    if (required.data[i].id === roleId) {
      for (
        let ii = 0;
        ii < required.data[i].required_competences.length;
        ii += 1
      ) {
        ids.push(required.data[i].required_competences.competence_id);
      }
    }
  }

  return ids;
};
