import {css} from '@emotion/react';

export const overlay = css({
  position: 'absolute',
  zIndex: 2,
  width: '100%',
  height: '100%',
});

export const container = css({
  position: 'relative',
  height: '100%',
  width: '100%',
});

export const img = css({
  position: 'relative',
  height: '100%',
  width: '100%',
  zIndex: 1,
  backgroundSize: 'cover',
});
