import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {mq} from '@facepaint';
import {motion} from 'framer-motion';

export const Base = styled('div')(
  {
    height: 60,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    borderBottom: '1px solid #c4c4c6',
  },
  props => ({backgroundColor: props.backgroundColor || '#010031'}),
);

export const Stack = styled('div')({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexBasis: '95%',
  [mq.medium]: {flexBasis: '90%'},
  [mq.large]: {flexBasis: '80%'},
});

export const Logo = styled('img')({
  maxHeight: '80px',
  minHeight: '20px',
  '&:hover': {cursor: 'pointer'},
});

export const Button = styled('button')(
  {},
  props =>
    props.variant === 'hamburger' && {
      backgroundColor: 'white',
      padding: '1.05em 1.15em',
      fontWeight: 600,
      fontSize: '1.15em',
      width: '100%',
      borderTop: '1px solid rgb(209, 208, 206)',
      flexDirection: 'row',
      alignItems: 'stretch',
      borderRadius: '0',
      display: 'flex',
      '&:hover': {cursor: 'pointer'},
      borderBottom: props.lastItem ? '1px solid rgb(209, 208, 206)' : 'none',
    }
    || props.variant === 'horizontal' && {
      backgroundColor: 'white',
      padding: '8px 16px',
      borderRadius: 20,
      fontWeight: 600,
      border: '1px solid rgb(209, 208, 206)',
      flexDirection: 'row',
      alignItems: 'stretch',
      display: 'flex',

      '&:hover': {
        color: '#FFF',
        backgroundColor: '#000',
        cursor: 'pointer',
        border: '1px solid #000',
      },
    },
);

export const row = css({
  display: 'flex',
  flexDirection: 'row',
});

export const separate = css({'& > :not(:last-child)': {marginRight: 8}});

export const display = css({
  display: 'none',
  [mq.medium]: {display: 'flex'},
});

export const HamburgerMenuToggle = styled(motion.div)({
  marginRight: '0.8em',
  transformOrigin: 'center',
  fontSize: '1.5em',
});

export const HamburgerMenuItemsWrapper = styled(motion.div)({}, props => ({
  position: 'absolute',
  top: '100%',
  right: '0',
  background: 'white',
  width: '100%',
  height: '100vh',
  borderBottom: props.state === 'open' ? '1px solid rgb(209,208,206)' : 'none',
}));

export const HamburgerMenuBackdrop = styled(motion.div)({
  background: 'rgba(0, 0, 0, 0.8)',
  position: 'absolute',
  top: '100%',
  right: '0',
  width: '100%',
  height: '100vh',
});
