import {useEffect, useState} from 'react';
import createPersistedState from 'use-persisted-state';

const useClearCache = ({duration = 300 * 1000, auto = false}) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [isLatestVersion, setIsLatestVersion] = useState(true);
  const useAppVersionState = createPersistedState('appVersion');
  const [appVersion, setAppVersion] = useAppVersionState('');
  const [latestVersion, setLatestVersion] = useState(appVersion);

  const refreshIfOutdated = version => {
    if (version && window.appVersion && version !== window.appVersion) {
      window.appVersion = null;
      window.location.reload(true);

      return true;
    }

    return false;
  };

  async function setVersion(version) {
    await setAppVersion(version);
  }

  const emptyCacheStorage = async version => {
    if ('caches' in window) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(names => {
        // eslint-disable-next-line no-restricted-syntax
        for (const name of names) caches.delete(name);
      });
    }

    // clear browser cache and reload page
    await setVersion(version || latestVersion).then(() => {
      window.appVersion = null;
      window.location.reload(true);
    });
  };

  function fetchMeta() {
    if (!refreshIfOutdated(appVersion)) {
      fetch(`/meta.json?_=${Date.now()}`, {cache: 'no-store'})
        .then(response => response.json())
        .then(meta => {
          const newVersion = meta.version;
          const isUpdated = newVersion === appVersion;

          if (meta.message) {
            setMessage(meta.message);
          }
          if (!isUpdated && window.appVersion) {
            setLatestVersion(newVersion);
            setLoading(false);
            setIsLatestVersion(false);
          } else if (!isUpdated && appVersion && (auto || !window.appVersion)) {
            emptyCacheStorage(newVersion);
          } else {
            setIsLatestVersion(true);
            setLoading(false);
            if (appVersion && !window.appVersion) {
              window.appVersion = appVersion;
            }
          }
        });
    }
  }

  useEffect(() => {
    window.appVersion = null;
    fetchMeta();

    const onLocalStorage = e => {
      if (e.key !== 'appVersion') {
        return;
      }
      if (e.newValue && appVersion && e.newValue !== appVersion) {
        refreshIfOutdated(e.newValue);
      }
    };

    window.addEventListener('storage', onLocalStorage);
    const fetchCacheTimeout = setInterval(() => fetchMeta(), duration);

    return () => {
      window.removeEventListener('storage', onLocalStorage);
      clearInterval(fetchCacheTimeout);
    };
  }, []);

  return {
    loading,
    message,
    isLatestVersion,
    emptyCacheStorage,
    latestVersion,
  };
};

const ClearCache = ({children, ...props}) => {
  const {
    loading,
    message,
    isLatestVersion,
    emptyCacheStorage,
  } = useClearCache(props);

  return children({
    loading,
    message,
    isLatestVersion,
    emptyCacheStorage,
  });
};

export default ClearCache;
