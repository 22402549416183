import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';

import classNames from 'classnames';
import FormInput from '@components/form-input';

import '../styles/login-form.scss';

const LoginForm = ({
  handleSubmit,
  handleCancel,
  stage,
  invalidCredentials,
  submitting,
}) => (
  <form
    className="login-form__container"
    onSubmit={handleSubmit}
  >
    <div className="login-form__input-wrapper">
      <Field
        autoComplete="username"
        className="login-form__input"
        component={FormInput}
        validate={() => undefined}
        label="Brukernavn:"
        name="username"
        type="text"
      />
    </div>
    <Field
      autoComplete="username"
      className="login-form__input"
      component={FormInput}
      validate={() => undefined}
      name="stage"
      type="hidden"
    />
    {stage === 'valid' && (
      <div className="login-form__input-wrapper">
        <Field
          autoComplete="password"
          className="login-form__input"
          component={FormInput}
          validate={() => undefined}
          label="Passord:"
          name="password"
          type="password"
        />
      </div>
    )}
    <p
      className={classNames(
        'login-form__invalid-credentials',
        stage === 'no-user' && !submitting
          ? 'login-form__invalid-credentials__visible'
          : 'login-form__invalid-credentials__hidden',
      )}
    >
      Ikke gylidg brukernavn eller e-post
    </p>
    <p
      className={classNames(
        'login-form__invalid-credentials',
        invalidCredentials && !submitting
          ? 'login-form__invalid-credentials__visible'
          : 'login-form__invalid-credentials__hidden',
      )}
    >
      Brukernavn eller passord stemmer ikke. Klikk på glemt
      brukernavn/passord.
    </p>

    <div className="login-form__submit-button-wrapper">
      <button
        className="btn login-form__submit-button"
        type="submit"
      >
        {stage === 'valid' ? 'Login' : 'Neste'}
      </button>
      <button
        className="btn login-form__forgot-button"
        type="button"
        onClick={handleCancel}
      >
        Glemt brukernavn/passord
      </button>
    </div>
  </form>
);

LoginForm.defaultProps = {
  invalidCredentials: false,
  submitting: false,
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  stage: PropTypes.string.isRequired,
  invalidCredentials: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default reduxForm({form: 'login'})(LoginForm);
