import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {Field, reduxForm} from 'redux-form';

import Modal from '@components/modal/modal';
import FormInput from '@components/form-input';
import EditPasswordForm from '@routes/employees/components/edit-password-form';

import {ActionButton} from '@components/buttons/actionButton';

import './person-form.scss';
import Separator from '@components/separator/separator';
import {i18n} from '@src/i18n';

const validateRequired = input => {
  if (!input || !input.trim()) {
    return i18n('globals.required-field');
  }

  return undefined;
};

const validateMobile = input => {
  if (!input || !input.trim()) {
    return undefined;
  }
  const number = input
    .replace(/^\s*(?:\+|00)(?:45|46|47|358|354)\s*/g, '')
    .trim();
  const digits = number.match(/\d/g);

  if (
    !digits
    || !/^[\d\s]+$/g.test(number)
    || digits.length < 5
    || digits.length > 12
  ) {
    return 'Ugyldig telefonnummer';
  }

  return undefined;
};

export const PersonFormLoading = () => (
  <div className="person-form">
    <div className="person-form__input-wrapper">
      <FormInput
        className="person-form__input"
        label={<>&nbsp;</>}
        type="text"
        input={{}}
        meta={{}}
        disabled
      />
    </div>
    <div className="person-form__input-wrapper">
      <FormInput
        className="person-form__input"
        label={<>&nbsp;</>}
        type="text"
        input={{}}
        meta={{}}
        disabled
      />
    </div>
    <div className="person-form__input-wrapper">
      <FormInput
        className="person-form__input"
        label={<>&nbsp;</>}
        type="email"
        input={{}}
        meta={{}}
        disabled
      />
    </div>

    <div className="person-form__input-wrapper">
      <FormInput
        label={<>&nbsp;</>}
        type="text"
        input={{}}
        meta={{}}
        disabled
      />
    </div>

    <div className="person-form__submit-button-wrapper">
      <ActionButton
        variant="text"
        type="button"
        disabled
      >
        {i18n('globals.cancel')}
      </ActionButton>

      <ActionButton
        type="button"
        disabled
        variant="round"
      >
        {i18n('globals.save')}
      </ActionButton>
    </div>
  </div>
);

const PersonForm = ({handleSubmit, history, manager, onEditPassword, isMobile}) => {
  const [setResetPasswordModalVisible] = useState(false);
  const [editPasswordModalVisible, setEditPasswordModalVisible] = useState(false);

  const escapeListener = ({key}) => {
    if (key === 'Escape') {
      setEditPasswordModalVisible(false);
    }
  };

  const toggleEscapeListener = on => {
    if (on) {
      document.addEventListener('keydown', escapeListener);
    } else {
      document.removeEventListener('keydown', escapeListener);
    }
  };

  const setModalVisibility = (type, visibility) => {
    if (type === 'reset') {
      setResetPasswordModalVisible(visibility);
    } else if (type === 'edit') {
      setEditPasswordModalVisible(visibility);
    }

    toggleEscapeListener(visibility);
  };

  const handleEditPassword = data => {
    onEditPassword({
      ...data,
      user: data.user_name,
    });
    setModalVisibility('edit', false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="person-form"
    >

      <div className="person-form__inputs-wrapper">

        <div className="person-form__input-wrapper">
          <Field
            autoComplete="name"
            className="person-form__input"
            component={FormInput}
            verticalSpacing="large"
            label={i18n('person.firstname')}
            name="firstname"
            type="text"
            validate={[validateRequired]}
          />
        </div>
        <div className="person-form__input-wrapper">
          <Field
            autoComplete="surname"
            className="person-form__input"
            component={FormInput}
            label={i18n('person.lastname')}
            verticalSpacing="large"
            name="lastname"
            type="text"
            validate={[validateRequired]}
          />
        </div>
        <div className="person-form__input-wrapper">
          <Field
            autoComplete="email"
            className="person-form__input"
            component={FormInput}
            verticalSpacing="large"
            label={i18n('person.email')}
            name="email"
            type="email"
            validate={[validateRequired]}
          />
        </div>
        <div className="person-form__input-wrapper">
          <Field
            className="person-form__input"
            component={FormInput}
            verticalSpacing="large"
            label={i18n('person.phone')}
            name="mobile"
            type="text"
            validate={[validateMobile]}
          />
        </div>
      </div>

      {!manager && (
        <div>
          <button
            className="employee-preview__add-menu-item"
            type="button"
            onClick={() => setModalVisibility('edit', true)}
          >
            <i className="fas fa-pencil-alt task-list__item-icon" />
            Endre passord
          </button>
        </div>
      )}
      {editPasswordModalVisible && (
        <Modal
          onCancel={() => setModalVisibility('edit', false)}
          onClose={() => setModalVisibility('edit', false)}
          disableActionButtons
          title={() => <Modal.Title>{i18n('person.change-password')}</Modal.Title>}
        >
          <EditPasswordForm
            isMobile={isMobile}
            managerForm={false}
            onSubmit={handleEditPassword}
            onCancel={() => setModalVisibility('edit', false)}
          />
        </Modal>
      )}

      <Separator
        style={{
          marginTop: '0.95em',
          marginBottom:0,
        }}
      />
      <div className="person-form__submit-button-wrapper">

        <ActionButton
          variant="text"
          theme="black"
          type="button"
          onClick={history.goBack}
        >
          {i18n('globals.cancel')}
        </ActionButton>

        <ActionButton
          type="submit"
          variant="round"
        >
          {i18n('globals.save')}
        </ActionButton>

      </div>
    </form>
  );
};

PersonForm.defaultProps = {
  manager: false,
  isMobile: false,
};

PersonForm.propTypes = {
  manager: PropTypes.bool,
  isMobile: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onEditPassword: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default compose(
  withRouter,
  reduxForm({
    form: 'person',
    enableReinitialize: true,
    updateUnregisteredFields: true,
  }),
)(PersonForm);
