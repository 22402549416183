import React from 'react';
import PropTypes from 'prop-types';
import {SplitPane, LeftPane, RightPane} from './styles';

const HorizontalSplitPane = ({className, left, right, variantLeft, variantRight}) => (
  <SplitPane className={className}>
    <LeftPane variant={variantLeft}>{left}</LeftPane>
    <RightPane variant={variantRight}>{right}</RightPane>
  </SplitPane>
);

HorizontalSplitPane.propTypes = {
  left: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]).isRequired,
  right: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  variantLeft: PropTypes.string,
  variantRight: PropTypes.string,
};

HorizontalSplitPane.defaultProps = {
  right: null,
  variantLeft: null,
  variantRight: null,
};

export default HorizontalSplitPane;
