import * as mapCoursesActions from '@actions/map.actions';
import * as authActions from '@actions/auth.actions';

export const initialState = {
  mapCourses: {
    isFetching: false,
    data: null,
    error: null,
    mapIsVerified: null,
    outroIsDone: null,
  },
  mapVerificationCompetence: {
    isFetching: false,
    data: null,
    error: null,
  },
  selectedMapCourse: {data: null},
};

const map = (state = initialState, action) => {
  switch (action.type) {
  case mapCoursesActions.FETCH_MAP_COURSES_REQUEST:
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        isFetching: true,
      },
    };
  case mapCoursesActions.FETCH_MAP_COURSES_SUCCEEDED:
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        error: null,
        data: action.payload.data,
        isFetching: false,
      },
    };

  case mapCoursesActions.FETCH_MAP_COURSES_FAILED:
    return {
      ...state,
      mapCourses: {
        ...initialState.mapCourses,
        error: action.payload.error,
        isFetching: false,
      },
    };

  case mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE_REQUEST:
    return {
      ...state,
      mapVerificationCompetence: {
        ...state.mapVerificationCompetence,
        isFetching: true,
      },
    };
  case mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE_SUCCEEDED:
    return {
      ...state,
      mapVerificationCompetence: {
        ...state.mapVerificationCompetence,
        error: null,
        data: action.payload.data,
        isFetching: false,
      },
    };

  case mapCoursesActions.FETCH_MAP_VERIFICATION_COMPETENCE_FAILED:
    return {
      ...state,
      mapVerificationCompetence: {
        ...initialState.mapVerificationCompetence,
        error: action.payload.error,
        isFetching: false,
      },
    };

  case mapCoursesActions.SET_MAP_IS_VERIFIED:
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        mapIsVerified: true,
      },
    };

  case mapCoursesActions.SELECT_MAP_COURSE:
    return {
      ...state,
      selectedMapCourse: {
        ...initialState.selectedMapCourse,
        data: action.payload,
      },
    };

  case mapCoursesActions.SET_OUTRO_IS_COMPLETED:
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        outroIsDone: true,
      },
    };

  case mapCoursesActions.SET_OUTRO_IS_NOT_COMPLETED:
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        outroIsDone: false,
      },
    };

  case mapCoursesActions.SET_MAP_IS_NOT_VERIFIED:
    return {
      ...state,
      mapCourses: {
        ...state.mapCourses,
        mapIsVerified: false,
      },
    };
  case authActions.AUTH_LOGOUT_SUCCESS:
    return {...initialState};

  default:
    return state;
  }
};

export default map;
