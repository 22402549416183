import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';


export const Header = styled.h3`
	color: #494949;
	font-weight: 500;
	margin-left: 25px;
	margin-top: 15px;
`;

export const Desc = styled.p`
	margin-left: 25px;
`;


export const DescriptionEdit = styled.textarea`
    padding: 4px;
    margin-bottom: 30px;
    margin-left: 25px;
    border: 1px solid #c9c9c9;
    width: 80%;
`;