import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {connect} from 'react-redux';

import {RoleShape} from '@types/role';

import FormMultiselect from '@components/form-multiselect';
import FormSelect from '@components/form-select';

import {ActionButton} from '@components/buttons/actionButton';

import {getOrganisationId} from '@selectors/profile.selectors';

import './role-form.scss';
import axios from 'axios';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';

const getRoles = (data, type = 'role') =>
  data.filter(({rolemetatype}) => rolemetatype === type);
  
const getTypeNameKey = ({roletype_name}) => roletype_name;
const getTypeIdKey = ({role_type_id}) => role_type_id;

class RoleForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: {
        isFetching: true,
        data: null,
      },
      user: {
        isFetching: true,
        data: null,
      },
    };
  }

  componentDidMount() {
    const {initialize, userName, orgId, onGotData} = this.props;

    axios
      .all([
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/roles?limit=1000&organisation_ids=${orgId}`,
            params: {role_meta_types: 'position,role'},
            withCredentials: true,
          })
          .then(({data}) => {
            this.setState({
              roles: {
                isFetching: false,
                data: data.roles,
              },
            });
          }),

        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/roles`,
            params: {
              user_name: userName,
              fields: 'rolemetatype,role_id,title,organisation_id',
              role_meta_types: 'position,role,specialrole',
            },
            withCredentials: true,
          })
          .then(({data}) => {
            this.setState(
              {
                user: {
                  isFetching: false,
                  data: data.roles,
                },
              },
              () => initialize({roles: getRoles(data.roles)}),
            );
          }),
      ])
      .then(() => {
        if (onGotData !== null) {
          onGotData();
        }
      });
  }

  handleRolesOnChange = ({values}) => {
    const {change} = this.props;

    change('roles', values);
  }

  render() {
    const {handleSubmit, onCancel, orgId} = this.props;
    const {
      roles,
      roles: {isFetching: isRolesFetching},
      user,
      user: {isFetching: isUserFetching},
    } = this.state;


    let selectRoles = [];
    let positions = [];
    let userPosition = [];
    let userRoles = [];
    let defaultPosition = '';

    if (!isRolesFetching) {
      positions = getRoles(roles.data, 'position');
      selectRoles = getRoles(roles.data);
    }

    if (!isUserFetching) {
      userPosition = user.data.filter(({rolemetatype}) => rolemetatype === 'position');

      if (userPosition.length !== 0
          && userPosition.filter(p => p.organisation_id === orgId).length !== 0
      ) {
        defaultPosition = userPosition.find(p => p.organisation_id === orgId).id;
      }
      userRoles = getRoles(user.data);
    }

    return (
      <div>
        {!isRolesFetching && !isUserFetching && (
          <div>
            <form
              onSubmit={handleSubmit}
              className="role-form"
            >
              <div className="person-form__input-wrapper">
                <Field
                  component={FormSelect}
                  options={positions}
                  className="employee-add__input"
                  name="position"
                  label="Stilling"
                  defaultValue={defaultPosition}
                />

                <Field
                  className="person-form__input employee-add__input--multiselect"
                  component={FormMultiselect}
                  label="Roller"
                  name="roles"
                  defaultValue={userRoles}
                  data={selectRoles}
                  busy={roles.isFetching}
                  textField=""
                  messages={{emptyList: 'Ingen roller å velge'}}
                  getTypeNameKey={getTypeNameKey}
                  getTypeIdKey={getTypeIdKey}
                  maxDropdownHeight={400}
                />

              </div>

              <div className="role-form__submit-button-wrapper">
                <ActionButton
                  variant="text"
                  type="button"
                  theme="black"
                  onClick={onCancel}
                >
                  {i18n('globals.cancel')}
                </ActionButton>

                <ActionButton
                  type="submit"
                  variant="round"
                  theme="black"
                >
                  {i18n('globals.save')}
                </ActionButton>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

RoleForm.propTypes = {
  userName: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  roles: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  user: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onGotData: PropTypes.func,
  change: PropTypes.func.isRequired,
};

RoleForm.defaultProps = {onGotData: null};

const mapStateToProps = state => ({orgId: getOrganisationId(state)});

export default reduxForm({form: 'role'})(connect(mapStateToProps)(RoleForm));
