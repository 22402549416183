

class AuthService {
  constructor() {
    this.isAuthorized = this.isAuthorized.bind(this);
  }

  isAuthorized(sessionId) {
    return !!sessionId;
  }
}

export default new AuthService();


