import React, {useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import moment from 'moment';

const createMessageContent = content => ({__html: content});
const Message = ({message}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function formatStringToDate(timestamp) {
    // Gets timestamp as String and makes a Date out of it
    // const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    // const date = new Date(timestamp.replace(pattern, '$3-$2-$1'));

    const day = moment(timestamp).format('DD.MM.YYYY');
    const time = moment(timestamp).format('HH:mm');

    return moment(timestamp).isSame(moment(), 'day')
      ? `i dag kl. ${time}`
      : `${day} kl. ${time}`;
  }

  const handleKeyPress = event => {
    if (event.which === 13) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div
      className={classNames({
        message: true,
        'message--expanded': isExpanded,
      })}
    >
      <div
        className="message__header"
        role="button"
        onKeyPress={handleKeyPress}
        tabIndex={0}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className="message__info">
          <div
            className={classNames({
              message__subject: true,
              'message__subject--expanded': isExpanded,
            })}
          >
            {message.subject}
          </div>
        </div>
        <div className="message__date">{formatStringToDate(message.ts)}</div>
        <button
          className="message__collapse"
          type="button"
        >
          <i className={`fas fa-angle-${isExpanded ? 'up' : 'down'}`} />
        </button>
      </div>
      {isExpanded && (
        <div className="message__meta">
          <div className="message__recipent">
            <div>Fra:</div>
            <span>{`${message.sender_name}`}</span>
            {message.sender_address && (
              <>
                {'<'}
                <Link to={`mailto:${message.sender_address}`}>
                  {message.sender_address}
                </Link>
                {'>'}
              </>
            )}
          </div>
          <div className="message__recipent">
            <div>Til:</div>
            {`${message.recipient_name} <`}
            <Link to={`mailto:${message.recipient_address}`}>
              {message.recipient_address}
            </Link>
            {'>'}
          </div>
        </div>
      )}
      {isExpanded && (
        <div
          className="message__content"
          dangerouslySetInnerHTML={createMessageContent(message.text)}
        />
      )}
    </div>
  );
};

Message.propTypes = {message: PropTypes.shape({}).isRequired};
export default Message;
