import DropdownSelect from '@components/dropdown-select/dropdown-select';
import {css} from '@emotion/react';
import styled from '@emotion/styled';

export const OrgCardWrapper = styled.div`
  display: flex;
  flex: 1 1 50%;
  max-width: 50%;
  padding: 0 20px;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

export const OrgsBadges = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -20px;
  margin-bottom: 60px;
`;

export const SortRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  margin: auto;
  margin-right: 0;
  align-items: center;
  justify-content: flex-end;
  color: #6a6868;
`;

export const StealthButton = css`
  position: relative;
  display: flex;
  flex: 0 1 auto !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-self: flex-start;
  border: 1px solid transparent !important;
  border-radius: 5px;
  padding: 10px 12px;
  user-select: none;
  cursor: pointer;
  color: #6a6868;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  background: none !important;
  box-shadow: none !important;
  line-height: 1.5;

  .dropdown-select__label {
    padding: 0 12px;
  }
`;

export const StealthButtonHover = css`
  box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.1) !important;
  border: 1px solid #e5e3e1 !important;
  background-color: #fff !important;
`;

export const SortToggleButton = styled.button`
  ${StealthButton} :hover,
  :active {
    ${StealthButtonHover}
  }
`;

export const SortSelect = styled(DropdownSelect)`
  ${StealthButton} :hover,
  :active,
  :focus {
    ${StealthButtonHover}
  }
`;

export const CourseCards = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: -14px;
  z-index: 100;
`;

export const ClickableText = styled.span`
  display: inline;
  cursor: pointer;

  :hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 0.3em;
  }
`;

export const BrandschoolTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  color: #7d7e7e;

  th {
    padding-left: 16px;
    padding-bottom: 8px;
    text-align: left;
    font-size: 0.9em;
  }

  tbody {
    tr {
      background: #fff;

      &:nth-child(2) {
        background: #ececeb;
      }
    }
    td {
      padding: 20px;
      border: 1px solid #cdcdcd;
      border-bottom: 1px solid #dededc;
    }
    tr:last-child td {
      border-bottom: 1px solid #cdcdcd;
    }
  }
`;

export const ReportDescription = styled.div`
  color: #6a6868;
  padding-top: 10px;
  padding-bottom: 20px;

  ${({hasDescription}) =>
  hasDescription
      && css`
        margin-bottom: 60px;
        border-bottom: 1px solid #6a6868;
      `
    || css`
      margin-bottom: 20px;
    `}
`;

export const SavedReportsList = styled.div`
  display: block;
  flex: 1 1 auto;
  margin: 20px -24px;
  height: 300px;
  max-height: 300px;
  overflow-y: auto;
`;

export const SavedReportsEntry = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  border-bottom: 1px solid #e7e7e7;
  line-height: 2;
  padding: 4px;
  padding-left: 24px;
  padding-right: 8px;
  color: #474747;
  background-color: #fff;
  cursor: pointer;
  transition: background 0.115s ease-out;
  span {
    transition: color 0.115s ease-out;
  }

  :hover {
    background-color: #f0f0f0;

    span {
      color: #000;
    }
  }
`;

export const GarbagoButton = styled.div`
  background: none;
  border: none;
  display: inline-block;
  color: #868686;
  cursor: pointer;
  line-height: 2;
  min-width: 2em;
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  transition: background 0.115s ease-out, color 0.115s ease-out;
  border-radius: 1em;

  :hover {
    background-color: #e00d2e;
    color: #fff;
  }

  ${({confirm}) =>
  confirm
    && css`
      padding: 0 1em;
      background-color: #e00d2e;
      color: #fff;
    `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ModalBackground = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  border-radius: 8px;
  z-index: 100;
  overflow: hidden;
  padding: 24px;
  width: 100vw;
  max-width: 600px;

  p {
    color: #868686;
  }
`;

export const ModalTitle = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: #474747;
`;

export const Label = styled.label`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

export const ReportTextInput = styled.input`
  border: 2px solid #e7e7e7;
  padding: 12px 8px;
  margin: 4px 0;
`;
export const ReportTextInputLabel = styled.div`
  color: #474747;
`;

export const CompletionChart = styled.div`
  position: relative;
  ${({stretch}) =>
  stretch
    ? css`
          flex: 1 1 auto;
          max-width: 200px;
        `
    : css`
          width: 200px;
        `}
  height: 1px;
  background-color: #9b9b9b;
  margin-top: 10px;
`;

export const CompletionBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${({completion}) => completion}%;
  background-color: #e77f1e;
`;

export const CompletionDot = styled.div`
  position: absolute;
  right: -2px;
  bottom: 4px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #e77f1e;

  ${({small}) =>
  small
    ? css`
          bottom: -2px;
        `
    : css`
          bottom: 4px;

          :after {
            content: '';
            display: block;
            position: absolute;
            right: 2px;
            top: 4px;
            width: 2px;
            height: 6px;
            background-color: #e77f1e;
          }
        `}
`;

export const CompletionText = styled.div`
  position: absolute;
  bottom: 6px;
  font-size: 14px;
  overflow: visible;
  white-space: nowrap;
  transform: translateX(-50%);
  color: #e77f1e;
  font-weight: bold;
`;

export const NarrowColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const PersonsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 80px;
`;

export const EmployeeRow = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: 14px 0;
`;

export const EmployeeAvatar = styled.div`
  display: block;
  flex: 0 0 auto;
  align-self: center;

  .person-avatar__image {
    background-color: #fff !important;
    box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  }
  .person-avatar__initials {
    color: #aaa;
    font-size: 1.2em;
  }
`;
export const EmployeeRowCard = styled.div`
  display: flex;
  flex: 1 1 auto;
  margin-left: 20px;
  background-color: #fff;
  box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);
  color: #6a6868;
  padding: 10px 14px;
`;

export const PersonsNameColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
`;

export const PersonsName = styled.div`
  display: flex;
  flex: 1 1 auto;
  font-weight: bold;
  font-size: 1.2em;
`;

export const PersonsPosition = styled.div`
  display: flex;
  flex: 1 1 auto;
  color: #aaa;
`;

export const BrandschoolCompletion = styled.div`
  display: flex;
  flex: 1 1 auto;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
`;

export const PersonsCompletion = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
`;

export const PersonsDetails = styled.div`
  display: block;
  flex: 0 0 100%;
  max-width: 150px;
  text-align: right;
  align-self: center;
  padding-right: 20px;
  color: #aaa;
`;

export const TopFilterRow = styled.div`
  .filter-row + & {
    border-top: 1px solid #d0cfcf;
  }
  .filter-row.expanded + &.expanded {
    border-top: none;
  }

  margin-bottom: 15px;
  &.expanded {
    margin-bottom: 0;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: ${({shrink}) => shrink ? '0 1 auto' : '1 1 auto'};
  flex-wrap: wrap;
`;

export const FlexColumn = styled(FlexRow)`
  flex-direction: column;
`;

export const BadgeRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -4px -8px;
  margin-bottom: 20px;
`;
export const ClickyBadge = styled.button`
  border: none;
  flex: 0 1 auto;
  margin: 4px 8px;
  margin-bottom: 8px;
  background-color: #c9c9c9;
  border-radius: 10px;
  cursor: pointer;
  padding: 8px 24px;
  font-size: 1.1em;

  ${({active}) =>
  active
    && css`
      background-color: #618da7;
      color: #fff;
    `}
`;
export const LinkButton = styled.button`
  background: none;
  border: none;
  display: inline-block;
  color: #1468b2;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.3em;
  cursor: pointer;
  align-self: flex-end;
  line-height: 2.5;
`;

export const Button = styled.button`
  border: none;
  border-radius: 5px;
  background-color: #618da7;
  cursor: pointer;
  padding: 16px 40px;
  flex: 0 1 auto;
  margin: 0;
  align-self: flex-end;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  transition: background 0.115s ease-out, color 0.115s ease-out;

  :hover,
  :active {
    background-color: #4f748a;
  }

  ${({secondary}) =>
  secondary
    && css`
      padding: 16px;
      background-color: transparent;
      color: #6590a9;

      :hover,
      :active {
        color: #6590a9;
        background-color: #f0f0f0;
      }
    `}

  ${({disabled}) =>
      disabled
    && css`
      background-color: #dededc;
      color: #b6bac5;
      cursor: default;

      :hover,
      :active {
        background-color: #dededc;
      }
    `}
`;

export const TopSectionBg = styled.div`
  border-bottom: 1px solid #d2d2d1;
  background-color: #e3e3e0;
  overflow: hidden;
  max-height: max-content;
  box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.1);

  ${({loading}) =>
  !loading
    && css`
      transition: max-height 0.4s ease-out;
    `}

  ${({collapsed}) =>
      collapsed
    && css`
      max-height: 70px !important;
    `}
`;

export const TopSection = styled.div`
  padding-bottom: 0 !important;
`;

export const ReportBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 600px;
`;

export const ReportSection = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  width: 90%;
  max-width: 1600px;
  padding: 40px 80px;

  @media screen and (max-width: 1000px) {
    padding: 40px 20px;
  }
`;
export const ReportSectionNarrowInner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  padding: 80px;
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 1200px;
  margin: 0 auto;
  transition: padding 0.4s ease-out;

  ${({collapsed}) =>
  collapsed
    && css`
      padding-top: 0;
    `}
`;

export const TopSectionToggleButton = styled.div`
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 20px;
  background-color: #e3e3e0;
  border: 1px solid #d2d2d1;
  border-top: none;
  color: #6a6868;
  transition: background-color 0.115s ease-out;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(100, 100, 100, 0.1);
  z-index: 1;

  :hover {
    background-color: #dbdbdb;
  }

  i {
    transform: scaleY(0.8);
  }
`;

export const InfoBoxRow = styled.div`
  display: flex;
  margin-bottom: -100px;
  justify-content: space-evenly;

  > div {
    margin: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

export const ReportHeader = styled.h2`
  flex: 0 1 auto;
  align-self: baseline;
  margin-right: auto;
  margin-bottom: 0;
  color: #7d7e7e;
  font-weight: 700;
  font-size: 32px;
  position: relative;
`;
export const ReportSubHeader = styled(ReportHeader)`
  margin-top: 0;
  font-size: 18px;
  font-weight: normal;
`;
export const ReportSectionHeader = styled(ReportHeader)`
  font-size: 24px;
  ${({light}) =>
  light
      && css`
        color: #6a6868;
      `
    || css`
      color: #474747;
    `}

  ${({center}) =>
      center
    && css`
      text-align: center;
      align-self: center;
      margin: 0;
    `}

  ${({underlined}) =>
      underlined
    && css`
      ::after {
        content: '';
        bottom: -10px;
        height: 4px;
        width: 120px;
        background-color: $color-orange;
        display: block;
        position: absolute;
      }
    `}
`;

export const ChartWrapper = styled.div`
  position: relative;
  overflow: visible;
  flex: 1 1 auto;
  height: 280px;
  justify-content: center;

  .extra-chart {
    overflow: visible;
    height: 100%;
  }
`;

export const ChartStatsMain = styled.div`
  position: relative;
  flex: 1 1 600px;
  margin-right: 40px;
  margin-left: -80px;

  @media screen and (max-width: 1000px) {
    margin-right: 10px;
    margin-left: -40px;
  }

  @media screen and (max-width: 800px) {
    flex: 1 0 auto;
    margin-top: -40px;

    ${ChartWrapper} {
      height: 220px;
    }
  }
`;
export const ChartStatsSidebar = styled.div`
  position: relative;
  display: flex;
  flex: 0 1 400px;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
`;

export const ChartStats = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  margin: 20px 0;

  @media screen and (max-width: 800px) {
    flex-direction: column;

    ${ChartStatsSidebar} {
      flex: 1 0 auto;
    }
  }
`;

export const BrandschoolStatsCell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
`;
export const BrandschoolRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  margin: 0 -40px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin: 0 -20px;

    ${BrandschoolStatsCell} {
      flex: 1 1 auto;
      margin-bottom: 180px;

      :last-child {
        margin-bottom: 0;
      }
    }

    ${ChartWrapper} {
      height: 220px;
    }
  }
`;