import React, {useCallback, useEffect, useMemo} from 'react';
import {css, jsx} from '@emotion/react';
import {useSelector, useDispatch} from 'react-redux';
import {coursesStartCourse} from '@actions/courses.actions';
import {useRouteMatch, withRouter} from 'react-router-dom';

import {
  selectMapCourse,
  fetchMapCourses,
  fetchMapVerificationCompetence,
} from '@actions/map.actions';
import {
  getIsAllMapDotsCompleted,
  getIsFirstMapDotCompleted,
  getIsMapVerified,
  getSelectedMapCourse,
  getOutroIsCompleted,
  getMapCourses,
  getMapOutro,
  getMapVerification,
  getMapDotts,
  getMapVerificationCompetence,
} from '@selectors/map.selectors';
import {getIsMobile} from '@selectors/global.selectors';
import {Map} from '@routes/atlas/components/Map/Map';
import HorizontalSplitPane from '@routes/atlas/layouts/HorizontalSplitPane/HorizontalSplitPane';
import {getConfigObject, getPropertiesForCurrLangAndTrackBadge} from '@selectors/config.selectors';
import {getPassed} from '@selectors/profile.selectors';
import {competencesToggle} from '@actions/competences.actions';
import {CompleteCourse} from '@routes/atlas/components/CompleteCourse';
import {CourseInformation} from '@routes/atlas/components/CourseInformation/course-information';
import {MapLoader} from '@routes/content/components/loader/loader';

import {getImageUrl} from '@utils/misc.utils';
import {getLoading} from '@selectors/alert.selectors';
import {MapBounding} from '../components/MapBounding/MapBounding';
import {Title} from '@routes/atlas/components/Hexagon/styles';
import {i18n} from '@src/i18n';
import {CourseInformationButton} from '@routes/atlas/components/CourseInformationButton/CourseInformationButton';

/**
 * Displays the map, consisting of courses known as "dots"
 *
 * Also handles playing of intro, verification and outro
 *
 * atlas: this entire page, with the map, the descripotion-part and the verify-part
 * map: consists of multiple "dots" along a path (aka the "track") that gets unlocked and completed in a linear fashion
 * dot: a course that can be completed
 *
 */
// eslint-disable-next-line react/prop-types
const AtlasContainer = ({history}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);
  const mapCourse = useSelector(getMapCourses);

  const track = mapCourse.data || null;

  const dotts = useSelector(getMapDotts);

  const isAllMapDotsCompleted = useSelector(getIsAllMapDotsCompleted);
  const isFirstMapDotCompleted = useSelector(getIsFirstMapDotCompleted);
  const configObject = useSelector(getConfigObject);

  const isMobile = useSelector(getIsMobile);
  const passedCompetences = useSelector(getPassed);
  const selectedDot = useSelector(getSelectedMapCourse);
  const isMapVerified = useSelector(getIsMapVerified);
  const isOutroDone = useSelector(getOutroIsCompleted);
  const badgeInfo = useSelector(getPropertiesForCurrLangAndTrackBadge);

  const outro = useSelector(getMapOutro);
  const verification = useSelector(getMapVerification);

  const isLoadingTrack = mapCourse.isFetching && !mapCourse.error;
  const shouldShowOutro
    = Boolean(isMapVerified && isAllMapDotsCompleted) && isOutroDone === false;

  const mapVerificationCompetence = useSelector(getMapVerificationCompetence);
  const match = useRouteMatch();
  const atlasId = match.params.id ? match.params.id : localStorage.getItem('track');

  // if map is not enabled, route to /
  useEffect(() => {
    const mapIsEnabled = configObject.isMapActivated;

    if (!mapIsEnabled) {
      // eslint-disable-next-line react/prop-types
      history.push('/');
    }
  }, []);

  // load the map if we dont have it
  useEffect(() => {
    if (!track && !isLoadingTrack) {
      dispatch(fetchMapCourses({id:atlasId}));
    }
  }, [dispatch, isLoadingTrack, track]);

  const startSelectedCourse = useCallback(() => {
    if (selectedDot.id) {
      dispatch(coursesStartCourse({
        cid: selectedDot.id,
        type: selectedDot.type,
      }));
    }
  }, [selectedDot, dispatch]);

  const findFirstOpenCourse = useCallback(() => {
    if (track) {
      const result = dotts.find(({status}) => status === 'OPEN');

      return result;
    }

    return null;
  }, [track]);

  const findCourseIndex = useCallback(
    val => {
      if (track && typeof val === 'string') {
        const result = dotts.findIndex(({status}) => status === val);

        return result;
      }

      if (track && typeof val === 'number') {
        const result = dotts.findIndex(({id}) => id === val);

        return result + 1;
      }

      return null;
    },
    [track],
  );

  // set the current active course
  // if we havent completed any, the first one is started automatically
  useEffect(() => {
    if (!isAllMapDotsCompleted && track) {
      const firstOpenCourse = findFirstOpenCourse();

      dispatch(selectMapCourse(firstOpenCourse));

      if (!isFirstMapDotCompleted) {
        dispatch(coursesStartCourse({
          cid: firstOpenCourse.id,
          type: 24,
        }));
      }
    }
  }, [
    dispatch,
    findFirstOpenCourse,
    isAllMapDotsCompleted,
    isFirstMapDotCompleted,
    track,
  ]);

  // start the outro if relevant
  useEffect(() => {
    if (shouldShowOutro) {
      dispatch(coursesStartCourse({
        cid: outro.id,
        type: 24,
      }));
    }
  }, [outro, dispatch, shouldShowOutro]);

  const completedCourses = useMemo(
    () =>
      track && dotts.filter(({status}) => status === 'DONE').length || null,
    [track],
  );

  useEffect(() => {
    const onKeyPress = ({key}) => {
      if (['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key)) {
        const course = dotts[Number(key) - 1];

        if (course && course.status !== 'LOCKED') {
          dispatch(selectMapCourse(course));
        }
      }
    };

    window.addEventListener('keypress', onKeyPress, false);

    return () => {
      window.removeEventListener('keypress', onKeyPress, false);
    };
  }, [dispatch, dotts]);

  const readyToShowVerification = !!(
    isAllMapDotsCompleted
    && isMapVerified === false
    && !mapCourse.isFetching
    && dotts
  );

  const shouldShowCongratsAndVerifyPage = !!(
    readyToShowVerification && mapVerificationCompetence.data
  );
  console.log('shouldShowCongratsAndVerifyPage', shouldShowCongratsAndVerifyPage)

  useEffect(() => {
    if (
      readyToShowVerification
      && mapVerificationCompetence.data === null
      && !mapVerificationCompetence.isFetching
      && !mapVerificationCompetence.error
    ) {
      dispatch(fetchMapVerificationCompetence({cid: verification.id}));
    }
  }, [readyToShowVerification, mapVerificationCompetence]);

  const mapProps = {
    track,
    hideDotsAndPath: !track,
    data: dotts,
    activeCourseID:
      !shouldShowCongratsAndVerifyPage && selectedDot && selectedDot.id,
    completedCourses,
    allCompleted: isAllMapDotsCompleted,
    hide: !isFirstMapDotCompleted || passedCompetences.isFetching,
    backgroundImage: track && track.tracks[0].image,
    backgrondPosition:
      configObject.getProperty('params.map-img.backgroundPosition')
      || '80% 50%',
    finishedWithFirstMapRuntrough: isOutroDone,
  };

  const setVerificationAsPassed = useCallback(() => {
    dispatch(competencesToggle({
      cid: verification.id,
      disableNotifications: true,
      disableStatusUpdateCheck: true,
      noRefetchCompetencesAfterPosting: true,
    }));
  }, [verification, dispatch]);

  // start loading the map image right away
  useEffect(() => {
    const {backgroundImage} = mapProps;

    if (!backgroundImage) {
      return;
    }

    const img = new Image();

    img.src = getImageUrl(backgroundImage);
  }, [mapProps, mapProps.mapImg]);

  const onVerifiedAndDone = useCallback(() => {
    if (verification) {
      setVerificationAsPassed();
    }
  }, [verification, setVerificationAsPassed]);

  const isLoadingVerification
    = readyToShowVerification
    && mapVerificationCompetence.isFetching
    && !mapVerificationCompetence.error;

  const courseInProgres = selectedDot && selectedDot.status === 1;

  if (courseInProgres) {
    return null;
  }

  if (
    isLoading
    || !dotts
    || isLoadingTrack
    || isLoadingVerification
    || !track && !(isMapVerified && !isOutroDone)
  ) {
    return (
      <MapLoader

      />
    );
  }

  const infoForSelectedDot
    = selectedDot && dotts && dotts.find(d => d.id === selectedDot.id);

  const grid = track.layout === 'grid';

  return (
    <div
      className={`atlas-wrapper ${isMobile ? 'mobile' : ''}` }
      css={css(
        css`
          position: relative;
          height: 100%;
        `,
        !isMobile
          && css`
            padding: 3em;
          `,
      )}
    >
      {!isMobile && grid && (
          <HorizontalSplitPane
          left={
            !!dotts && (
              <Map
                key={track.id}
                badge={badgeInfo}
                allCompleted={isAllMapDotsCompleted}
                {...mapProps}
                disableInteraction={shouldShowCongratsAndVerifyPage}
                isMobile={false}
                grid={grid}
              />
            )
          }
          right={
            selectedDot && selectedDot.id === 'badge' && (
              <div>
                <CourseInformation
                  coursesInfo={{
                    id: 'badge',
                    title: '',
                    description: badgeInfo.text.content,
                  }}
                  selectedCourse={selectedDot}
                  findCourseIndex={findCourseIndex}
                />
              </div>
            ) || shouldShowCongratsAndVerifyPage && (
              <CompleteCourse
                onVerifiedAndDone={onVerifiedAndDone}
                verificationCompetence={mapVerificationCompetence.data}
              />
            )
            || infoForSelectedDot && (
              <CourseInformation
                coursesInfo={{
                  id: infoForSelectedDot.id,
                  title: infoForSelectedDot.title,
                  description: infoForSelectedDot.short_description,
                }}
                startSelectedCourse={startSelectedCourse}
                selectedCourse={selectedDot}
                findCourseIndex={findCourseIndex}
              />
            )
            || null
          }
        />
      )}

      {!!((isMobile || !grid) && !shouldShowCongratsAndVerifyPage && dotts)
        && (
          <Map
            css={css`width: 100%;`}
            isMobile
            allCompleted={isAllMapDotsCompleted}
            badge={badgeInfo}
            grid={grid}
            key={track.id}
            {...mapProps}
          />
        )}
      {isMobile && shouldShowCongratsAndVerifyPage && (
        <MapBounding
          {...mapProps}
          name="map"
        >
          <div
            css={css(css`
                position: relative;
                color: #fff;
                label {
                  color: white;
                }
              `)}
          >
            <CompleteCourse
              onVerifiedAndDone={onVerifiedAndDone}
              verificationCompetence={mapVerificationCompetence.data}
            />
          </div>
        </MapBounding>
      )}
      {isMobile && selectedDot && selectedDot.id === 'badge'  && (
        <>
          <div style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            backgroundColor: 'rgba(0,0,0,.6)',
          }} />
        <div
          css={css(css`
            top: 4%;
            left: 10%;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 0 5px rgba(0,0,0,.2);
            background-color: white;
            position: absolute;
            z-index: 1000;
            width: 80%;
            max-height: 80%;
            overflow: auto;
          `)}
          name="map"
        >

          <div
            css={css(css`
                color: black;
                height: 100%;
                overflow: auto;
              `)}
          >
            <div
              dangerouslySetInnerHTML={{__html: badgeInfo.text.content}}
            />
              <CourseInformationButton
                style={{marginTop: 40, marginBottom: 40, width: '100%'}}
                className="course-action-button"
                onClick={() => dispatch(selectMapCourse({}))}
                text={'Close'}
              />
          </div>
        </div>
        </>
      )}
    </div>
  );
};

export default withRouter(AtlasContainer);
