import styled from '@emotion/styled';
import {FlexRow, LinkButton, ReportSectionHeader, TopFilterRow} from './styles/report-styles';

const Header = styled(ReportSectionHeader)`margin-top: 16px;`;

export const ExpandableInput = ({title, actionTitle, expanded, onExpand, children}) => !expanded
  ? (
    <TopFilterRow className="filter-row">
      <FlexRow>
        <FlexRow>
          <Header
            light
          >
            {title}
          </Header>
        </FlexRow>
        <FlexRow shrink>
          <LinkButton onClick={onExpand}>
            {actionTitle}
          </LinkButton>
        </FlexRow>
      </FlexRow>
    </TopFilterRow>
  )
  : (
    <TopFilterRow className="filter-row expanded">
      <Header
        light
      >
        {title}
      </Header>
      {children}
    </TopFilterRow>
  );