import {backendUrl} from '@config';
import axios from 'axios';
import {takeLatest, put, call, select} from 'redux-saga/effects';
import {
  COMPETENCES_TOGGLE,
  competencesToggleRequest,
  competencesToggleSuccess,
  competencesToggleFailure,
} from '@actions/competences.actions';

import {profileUpdateOneCompetences} from '@actions/profile.actions';
import {notificationsAdd} from '@actions/notifications.actions';

import {
  employeesFetchChecklists,
  employeesFetchSelectedPersonChecklists,
} from '@actions/employees.actions';

import {
  getOrganisationId,
  getProfileUserName,
} from '@selectors/profile.selectors';
import {
  getConfigObject,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';
import {setMapIsVerified} from '@actions/map.actions';
import {getSelectedPersonUsername} from '../selectors/employees.selectors';
import {getMapVerification} from '@selectors/map.selectors';

function* toggleCompetence(payload) {
  yield put(competencesToggleRequest());
  const organisationId = yield select(getOrganisationId);

  try {
    const {
      cid,
      pid,
      size,
      disableNotifications,
      disableStatusUpdateCheck,
      noRefetchCompetencesAfterPosting,
    } = payload.payload;

    let resp = null;

    if (size === 'dashboard') {
      /*
      THIS IS EMPLOYEE, SO IT IS MANAGER THAT DOES THIS.
       */
      const action_name = `checklist_item_manager_${cid}`;

      resp = yield call(axios.request, {
        method: 'POST',
        url: `${backendUrl}/courses/setchecklistitem`,
        params: {
          name: action_name,
          value: true,
          person_id: pid,
        },
        withCredentials: true,
      });
      if (window.location.pathname.includes('/dashboard')) {
        yield put(employeesFetchChecklists());
      } else {
        const userName = yield select(getSelectedPersonUsername);

        yield put(employeesFetchSelectedPersonChecklists({
          userName,
          organisationId,
        }));
      }

      if (!disableNotifications) {
        yield put(notificationsAdd({
          notification: {
            text: 'Sjekkliste er oppdatert.',
            color: 'green',
          },
        }));
      }
    } else {
      const userName = yield select(getProfileUserName);

      resp = yield call(axios.request, {
        method: 'POST',
        url: `${backendUrl}/api/personcompetences`,
        params: {
          competence_id: cid,
          user_name: userName,
          passed: 100,
          action: 'on',
        },
        withCredentials: true,
      });

      if (!disableNotifications) {
        yield put(notificationsAdd({
          notification: {
            text: 'Sjekkliste er oppdatert.',
            color: 'green',
          },
        }));
      }

      if (!noRefetchCompetencesAfterPosting) {
        yield put(profileUpdateOneCompetences({disableStatusUpdateCheck}));
      }
    }

    // check if this action did verify the map
    const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);
    const mapVerification = yield select(getMapVerification);

    if (mapVerification) {
      const responseCompetence
        = resp.data.personcompetences
        && Array.isArray(resp.data.personcompetences)
        && resp.data.personcompetences.length > 0
        && resp.data.personcompetences[0];

      const verifiedMap
        = cid === responseCompetence.competence_id
        && !responseCompetence.cancelled;

      if (verifiedMap) {
        yield put(setMapIsVerified());
      }
    }

    yield put(competencesToggleSuccess({}));
    /*
    yield put(
      fetchCompetencesChildren({
        courseId: 30,
        personId: profileId,
        orgId: organisationId,
        root: 'show-role',
      })
    );
    */
  } catch (error) {
    console.error(error);
    yield put(competencesToggleFailure({error}));
  }
}

export default [takeLatest(COMPETENCES_TOGGLE, toggleCompetence)];
