import React from 'react';

import CompetenceChecklistItem from '@components/competences/competence-checklist-item';

import '../styles/employees-checklist.scss';
import {connect} from 'react-redux';
import propTypes from 'prop-types';
import {
  getIsFetchingEmployees,
  getEmployees,
} from '@selectors/employees.selectors';


const EmployeesChecklist = ({items, type}) => (
  <div className="employees-checklist">
    {items && items.map(item => (
      <div>
        <CompetenceChecklistItem
          type={type}
          course={item}
          size="dashboard"
        />
      </div>
    ))}
  </div>
);

EmployeesChecklist.propTypes = {
  type: propTypes.string,
  items: propTypes.shape({}).isRequired,
};
EmployeesChecklist.defaultProps = {type: ''};

const mapStateToProps = state => ({
  isFetchingEmployees: getIsFetchingEmployees(state),
  employees: getEmployees(state),
});

export default connect(mapStateToProps)(EmployeesChecklist);
