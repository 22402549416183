import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Field, reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {connect, useDispatch} from 'react-redux';

import Modal from '@components/modal/modal';
import FormMultiselect from '@components/form-multiselect';

import './course-sign-button.scss';
import {
  getSigningOnCourse,
  getSignOnCourseResults,
} from '@selectors/courses.selectors';
import {isManager} from '@selectors/profile.selectors';
import {
  getEmployees,
  getIsFetchingEmployees,
} from '@selectors/employees.selectors';
import {employeesGet} from '@actions/employees.actions';
import {courseSignOn, courseSignOff} from '@actions/courses.actions';
import SearchBar from '@routes/employees/components/search-bar';
import {i18n} from '@src/i18n/index';
import {ActionButton} from '@components/buttons/actionButton';
import {Dropdown} from '@components/dropdown/dropdown';
import {css} from '@emotion/react';

const validateNotEmpty = input => {
  if (!input || !input.length || input.length === 1 && input[0] === '') {
    return 'Du må velge en ansatt fra listen for å melde på';
  }

  return undefined;
};

const ModalForm = reduxForm({form: 'register-employees'})(({handleSubmit, onSubmit, onSearchChange, onCancel, employees}) => (
  <form
    onSubmit={handleSubmit(onSubmit)}
    className="modal-form"
  >
    <div className="course-sign__search-wrapper">
      <SearchBar
        placeholder={i18n('globals.search', {ellipsis:true})}
        name="search-bar"
        style={{
          fontSize: '15px',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
        loading={employees.isFetching === 'NOT_LOADED'}
        onChange={({target: {value}}) => {
          onSearchChange(value);
        }}
      />
    </div>
    <Field
      className="modal-form__multiselect"
      component={FormMultiselect}
      name="employees"
      data={employees.data.map(({fullname, user_name}) => ({
        id: user_name,
        title: fullname,
      }))}
      busy={employees.isFetching}
      messages={{emptyList: 'Ingen ansatte å velge'}}
      validate={[validateNotEmpty]}
    />
    <div
      css={{
        display:'flex',
        marginLeft:'auto',
        width:'fit-content',
      }}
    >
      <ActionButton
        variant="text"
        type="button"
        onClick={onCancel}
      >
        {i18n('globals.cancel')}
      </ActionButton>
      <ActionButton
        type="submit"
      >
        {i18n('course.sign-up')}
      </ActionButton>
    </div>
  </form>
));

const CourseSignButtonAndModal = ({
  courseEvent,
  selfCourseEvent,
  courseSignOn,
  courseSignOff,
  signOff,
  employeesData: {data: employeesData},
  isFetchingEmployees,
  withDropdown,
  signingResults,
}) => {
  const initialResults = {
    results: {
      correct: [],
      errors: [],
    },
    isFetching: false,
  };


  const [isBusy, setBusy] = useState('');
  const [isModalVisible, setModalVisibility] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [{results, isFetching}, setLocalResults] = useState(initialResults);
  const [searchPhraze, setSearchPhraze] = useState('');

  const dispatch = useDispatch();

  const employees = {
    isFetching: isFetchingEmployees,
    data: employeesData,
  };

  useEffect(() => {
    setLocalResults(signingResults);
  }, [signingResults, signingResults.results]);

  const {id: courseId} = courseEvent;

  const closeModal = () => {
    setModalVisibility(false);
    setShowResults(false);
    setLocalResults(initialResults);
  };

  const onSignOffCourseClick = (ceid, employees, key) => {
    courseSignOff({
      courseEventId: ceid,
      employees,
    });
    setBusy(key);
  };

  const onSignOnCourseClick = (ceid, employees) => {
    courseSignOn({
      courseEventId: ceid,
      employees,
    });
  };

  const opnOpenEmployeeSelect = () => {
    setModalVisibility(true);
    if(!employees.data && employees.isFetching === 'NOT_LOADED') {
      dispatch(employeesGet({all: true}));
    }
  };

  const onSignEmployeesConfirm = ({employees: selectedEmployees}) => {
    const registeredEmployees
      = selectedEmployees
      && selectedEmployees.reduce((acc, cur) => {
        const employee = employees.data.find(({user_name}) => user_name === cur.id);

        if (employee) {
          acc.push(employee);
        }

        return acc;
      }, []);

    if (registeredEmployees) {
      courseSignOn({
        courseEventId: courseId,
        employees: registeredEmployees,
      });
    }

    setShowResults(true);
  };

  const onSearchChange = value => {
    setSearchPhraze(value);
  };
  const courseKey = `${courseId}-${courseEvent.person ? courseEvent.person.user_name : 'me'}`;

  const selfAlreadySignedOn =  Array.isArray(selfCourseEvent) && selfCourseEvent.length > 0;

  return (
    <div
      className="course-sign 2"
      key={courseKey}
    >
      {withDropdown
        ?             signOff && (
          <button
            type="button"
            className="btn course-sign__button"
            onClick={() => {
              onSignOffCourseClick(
                courseId,
                courseEvent.person ? courseEvent.person : null,
                courseKey,
              );
            }}
          >
            {isBusy === courseKey ? '...' : 'Meld av'}
          </button>
        ) || (

          <Dropdown
            css={css`margin-top: 1.15em;`}
            actionButton={{
              text:i18n('course.sign-up'),
              souldOpenDropdown: true,
            }}
            toggleDropdownButton={{title:i18n('course.choose-employees-for-signup')}}
            onChange={val => {
              if(val === 'me') {
                onSignOnCourseClick(courseId);
              } else if(val === 'employees') {
                opnOpenEmployeeSelect();
              }
            }}
            items={[{
              label:i18n('course.sign-up-employees'),
              value:'employees',
            }, {
              label: selfAlreadySignedOn ?  i18n('course.yourself-already-signed-on') : i18n('course.sign-up-yourself'),
              value:'me',
              disabled: selfAlreadySignedOn,
            }]}
          />
        )
        
        : (
          <>
            {signOff && (
              <button
                type="button"
                className="btn course-sign__button"
                onClick={() => {
                  onSignOffCourseClick(courseId, null, courseKey);
                }}
              >
                {isBusy === courseKey ? '...' : 'Meld av'}
              </button>
            ) || (
              <button
                type="button"
                className="btn course-sign__button"
                onClick={() => {
                  onSignOnCourseClick(courseId);
                }}
              >
                {isBusy ? '...' : 'Meld på'}
              </button>
            )}
          </>
        )}

      {isModalVisible && (
        <Modal
          onClose={closeModal}
          disableActionButtons
          title={() => <Modal.Title>{i18n('course.sign-up-employees-for-course')}</Modal.Title>}
        >
          <div className="">
            {employees.isFetching === 'IS_LOADING' && <span>{i18n('globals.loading', {ellipsis: true})}</span>}
            {employees.data && !showResults && (
              <ModalForm
                employees={
                  searchPhraze !== ''
                    ? {
                      ...employees,
                      data: employees.data.filter(ed =>
                        ed.fullname
                          .toLowerCase()
                          .includes(searchPhraze.toLowerCase())),
                    }
                    : employees
                }
                onSubmit={onSignEmployeesConfirm}
                onSearchChange={onSearchChange}
                onCancel={closeModal}
              />
            )}
            {showResults && isFetching && <span>Loading...</span>}
            {showResults && !isFetching && (
              <div className="course-sign__modal-results">
                {results.correct && results.correct.length > 0 && (
                  <div className="course-sign__modal-results__container">
                    <h4 className="course-sign__modal-subtitle">
                      Disse personer ble meldt på
                    </h4>
                    {results.correct.map(({user}) => (
                      <div
                        key={`course-signon-error-${user}`}
                        className={classNames([
                          'course-sign__modal-results__item',
                          'course-sign__modal-results__item--correct',
                        ])}
                      >
                        <i className="fas fa-check" />
                        <span>{user}</span>
                      </div>
                    ))}
                  </div>
                )}
                {results.errors && results.errors.length > 0 && (
                  <div className="course-sign__modal-results__container">
                    <h4 className="course-sign__modal-subtitle">
                      Disse peronerne ble ikke meldt på
                    </h4>
                    {results.errors.map(({user}) => (
                      <div
                        key={`course-signon-error-${user}`}
                        className={classNames([
                          'course-sign__modal-results__item',
                          'course-sign__modal-results__item--error',
                        ])}
                      >
                        <i className="fas fa-times" />
                        <span>{user}</span>
                      </div>
                    ))}
                  </div>
                )}
                <div className="modal-form__buttons">
                  <ActionButton
                    type="button"
                    onClick={closeModal}
                  >
                    {i18n('globals.ok')}
                  </ActionButton>
                </div>
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

CourseSignButtonAndModal.propTypes = {
  courseEvent: PropTypes.shape({}).isRequired,
  courseSignOn: PropTypes.func.isRequired,
  courseSignOff: PropTypes.func.isRequired,
  signOff: PropTypes.bool,
  employeesData: PropTypes.shape.isRequired,
  isFetchingEmployees: PropTypes.bool,
  employeesGet: PropTypes.func.isRequired,
  signingResults: PropTypes.arrayOf(PropTypes.shape({
    correct: PropTypes.arrayOf({
      message: PropTypes.string,
      user: PropTypes.string,
    }),
    errors: PropTypes.arrayOf({
      message: PropTypes.string,
      user: PropTypes.string,
    }),
  })),
  withDropdown: PropTypes.bool,
};

CourseSignButtonAndModal.defaultProps = {
  withDropdown: false,
  signOff: false,
  isFetchingEmployees: false,
  signingResults: [],
};

const mapStateToProps = state => ({
  courseSigningOn: getSigningOnCourse(state),
  withDropdown: isManager(state),
  employeesData: getEmployees(state),
  isFetchingEmployees: getIsFetchingEmployees(state),
  signingResults: getSignOnCourseResults(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      courseSignOn,
      courseSignOff,
      employeesGet,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseSignButtonAndModal);
