import styled from '@emotion/styled';
import {jsx, css} from '@emotion/react';
import React, {useRef, useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useOnClickOutside} from '@src/hooks/hooks';

const InfoBubble = ({
  width,
  height,
  zIndex,
  margin,
  rect,
  title,
  offsetX,
  offsetY,
  alignRight,
  alignBottom,
  anchor,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorPositionY, setAnchorPositionY] = useState(null);
  const ref = useRef();
  const boxRef = useRef();

  useEffect(() => {
    if (!open) {
      setAnchorPositionY(null);
    }
  }, [open]);

  const onScroll = useCallback(() => {
    if (!boxRef.current || !ref.current) {
      return;
    }

    const {style} = boxRef.current;

    if (rect) {
      const {top, bottom, left, right} = rect;

      style.top = `${top}px`;
      style.bottom = `${bottom}px`;
      style.left = `${left}px`;
      style.right = `${right}px`;
      style.maxHeight = null;
      style.maxWidth = null;
    } else {
      const {top, left} = ref.current.getBoundingClientRect();

      const {innerWidth, innerHeight} = window;

      let offsetTop = anchorPositionY;

      if (offsetTop === null) {
        const newHeight = Math.min(height, innerHeight - margin * 2);
        const marginVertical = Math.min(
          Math.max(0, margin - (height - newHeight) / 2),
          margin,
        );

        offsetTop = Math.min(
          Math.max(top + offsetY - (alignBottom ? height : 0), marginVertical),
          Math.max(innerHeight - height - marginVertical, 0),
        );
        if (anchor) {
          setAnchorPositionY(offsetTop);
        }
      }
      const newWidth = Math.min(width, innerWidth - margin * 2);
      const marginHorizontal = Math.min(
        Math.max(0, margin - (width - newWidth) / 2),
        margin,
      );
      const offsetLeft = Math.min(
        Math.max(left + offsetX - (alignRight ? width : 0), marginHorizontal),
        Math.max(innerWidth - width - marginHorizontal, 0),
      );

      style.top = `${offsetTop}px`;
      style.bottom = null;
      style.left = `${offsetLeft}px`;
      style.right = null;
      style.maxHeight = innerHeight < height ? `${innerHeight}px` : null;
      style.maxWidth = innerWidth < width ? `${innerWidth}px` : null;
    }
  }, [width, height, margin, rect, children]);

  useEffect(() => {
    const content = document.querySelectorAll('.app-wrapper__content')[0];

    content.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    window.addEventListener('orientationchange', onScroll);
    onScroll();

    return () => {
      content.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
      window.removeEventListener('orientationchange', onScroll);
    };
  }, [onScroll]);

  useOnClickOutside([ref, boxRef], () => setOpen(false));

  return (
    <>
      <Icon
        ref={current => {
          ref.current = current;
          onScroll();
        }}
        className="fa fa-info"
        onClick={() => setOpen(toggle => !toggle)}
      />
      {open && (
        <Box
          width={width}
          height={height}
          zIndex={zIndex}
          ref={current => {
            boxRef.current = current;
            onScroll();
          }}
        >
          <CloseButton
            className="fa fa-times"
            onClick={() => setOpen(false)}
          />
          {title && <Title>{title}</Title>}
          {children}
        </Box>
      )}
    </>
  );
};

InfoBubble.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  zIndex: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  margin: PropTypes.number,
  rect: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  title: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  alignRight: PropTypes.bool,
  alignBottom: PropTypes.bool,
  anchor: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

InfoBubble.defaultProps = {
  zIndex: 1000,
  margin: 40,
  rect: null,
  title: null,
  offsetX: null,
  offsetY: null,
  alignRight: false,
  alignBottom: false,
  anchor: false,
  children: null,
};

const Title = styled.div`
  flex: 1 1 auto;
  color: #474747;
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 0.5em;
`;

const Icon = styled.i`
  position: absolute;
  top: 0;
  right: -16px;
  background-color: #949494;
  width: 1.4em;
  line-height: 1.4;
  border-radius: 50%;
  text-align: center;
  overflow: visible;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #fff;
  border: 1px solid #d5d2d2;
  padding: 20px;
  box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.1);
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  width: fit-content;
  height: fit-content;
  max-width: ${({width}) => width}px;
  max-height: ${({height}) => height}px;
  z-index: ${({zIndex}) => zIndex};
  color: unset;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  text-align: left;
`;

const CloseButton = styled.button`
  position: absolute;
  box-shadow: none;
  top: 4px;
  right: 4px;
  padding: 0;
  border: none;
  background: none;
  font-size: 16px;
  width: 1.4em;
  line-height: 1.4;
  border-radius: 50%;
  text-align: center;
  overflow: visible;
  cursor: pointer;
  color: #949494;
  background-color: transparent;

  :hover,
  :active,
  :focus {
    color: #fff;
    background-color: #949494;
  }
`;

export default InfoBubble;
