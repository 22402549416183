import React, {
  Fragment,
  forwardRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import Highlighter from 'react-highlight-words';
import SearchBox, {DropdownItem} from './search-box';
import {
  Loading,
  DropdownItemText,
  DropdownItemDescription,
  DropdownRow,
  DropdownFilters,
  DropdownItems,
  DropdownFilterCheckbox,
  DropdownItemCheckbox,
} from './styles/input-styles';
import {searchFilter} from './input-search-multiple';

export const InputSearchCheckboxes = forwardRef((
  {
    items,
    value: valueProp,
    nameKey,
    getTypeNameKey,
    getTypeIdKey,
    params,
    onChange,
    loading,
    small,
    messages: {emptyList} = {},
    ...props
  },
  ref,
) => {
  const [changed, setChanged] = useState(false);
  const [value, setValue] = useState([]);
  
  useEffect(() => {
    setValue(valueProp);
    setChanged(false);
  }, [valueProp]);

  useEffect(() => {
    if (changed) {
      if (onChange) {
        onChange(value);
      }
      setChanged(false);
    }
  }, [changed, onChange, value]);
  
  const [filters, setFilters] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    const filters = {};

    if (getTypeIdKey && getTypeNameKey) {
      items.forEach(item => {
        const id = getTypeIdKey(item);
        const text = getTypeNameKey(item);
  
        filters[id] = {
          text,
          type: id,
          enabled: false,
        };
      });
    }
    setFilters(Object.values(filters));
  }, [getTypeIdKey, getTypeNameKey, nameKey, items]);

  const [term, setTerm] = useState('');

  const noFiltersEnabled = useMemo(() => filters
    .map(({enabled}) => enabled)
    .every(enabled => enabled === false), [filters]);

  const selectedFilterTypes = useMemo(() => filters.reduce(
    (result, {type, enabled}) =>
      enabled ? [...result, type] : result,
    [],
  ), [filters]);

  const toggleFilter = key => {
    setFilters(filters => {
      const newFilters = [...filters];

      newFilters[key].enabled = !newFilters[key].enabled;

      return newFilters;
    });
  };

  const onItemSelected = item => {
    if (value && value.some(({id}) => id === item.id)) {
      setValue(values => values.filter(({id}) => id !== item.id));
    } else {
      setValue(values => [...values, item]);
    }
    setChanged(true);
  };

  useEffect(() => {
    setFilteredItems(items
      ? searchFilter(
        items.filter(item =>
          noFiltersEnabled || !getTypeIdKey || selectedFilterTypes.includes(getTypeIdKey(item))),
        term,
        [nameKey, 'id'],
      )
      : []);
  }, [getTypeIdKey, items, nameKey, noFiltersEnabled, selectedFilterTypes, term, value]);

  return (
    <SearchBox
      {...props}
      small={small}
      onSearch={term => {
        setTerm(term);
      }}
      loading={loading}
      value={term}
      onChange={onItemSelected}
      items={filteredItems}
      rowHeight={42}
      renderItemText={item => (
        <>
          <DropdownItemCheckbox
            type="checkbox"
            checked={value && value.some(({id}) => id === item.id)}
            onChange={() => {
              onItemSelected(item);
            }}
          />
          <DropdownItemText
            as={Highlighter}
            searchWords={[term]}
            autoEscape
            textToHighlight={item?.[nameKey]}
          />
          {getTypeNameKey
            ? <DropdownItemDescription>{getTypeNameKey(item)}</DropdownItemDescription>
            : null}
        </>
      )}
      renderDropdown={render => (
        <DropdownRow>
          {filteredItems
              && filters.length > 1 && (
                <DropdownFilters>
                  {filters.map(({text, type: filterType, enabled}, index) => (
                    <DropdownFilterCheckbox key={index}>
                      <input
                        type="checkbox"
                        checked={enabled}
                        onChange={() => toggleFilter(index)}
                      />
                      {text}
                    </DropdownFilterCheckbox>
                  ))}
                </DropdownFilters>
          )}
          <DropdownItems>
            {!filteredItems?.length
                && (loading && (
                  <DropdownItem small={small}>
                    <Loading />
                  </DropdownItem>
                ) || emptyList && <DropdownItem small={small}>{emptyList}</DropdownItem>)}
            {render()}
          </DropdownItems>
        </DropdownRow>
      )}
    />
  );
});
