import React from 'react';

import './loading.scss';

const Loading = () => (
  <div className="loading-bar">
    <div className="loading-bar__progress">
      <div className="loading-bar__indeterminate" />
    </div>
  </div>
);

export default Loading;
