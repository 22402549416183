import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {darken, lighten, rgba} from 'polished';
const _ = require('lodash');


export const Wrapper = styled.div`
    margin-bottom: 0.6em;
`;
export const Status = styled.div``;
export const Name = styled.div``;