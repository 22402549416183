import styled from '@emotion/styled';
import {Separator} from '@routes/auth/components/LanguageSelect/styles';
import PropTypes from 'prop-types';
import PointSeparator from '../cv-components';
import {css, jsx} from '@emotion/react';
import {NoDataDesc, LanguageCont, Header, SubCont, LeftPart, CvSectionTitle, RightPart, LanguageDesc, LanguageName, Details, EditIcon, EditBtnExperience, AddCVDataTxt, MissingCVTxt, MissingDescription, Description} from './cv-view-styles';
import moment from 'moment';
import countdown from 'countdown';
import {i18n} from '@src/i18n';


const formatDate = (fromDateStr, toDateStr) => {
  const ongoing = !toDateStr;

  if (!fromDateStr && !toDateStr) {
    return null;
  }

  const dateFrom = moment(fromDateStr);

  const dateTo = ongoing && moment()
    .minute(0)
    .hour(0) ||  moment(toDateStr);

  const getFormattedFrom = dateFrom => {
    const fromFormatted = dateFrom.format('MMM YYYY');

    return fromFormatted;
  };



  const getFormattedTo = dateTo => {
    if(ongoing) {
      return ' ' + i18n('globals.now', {lowerCase: true});
    }
    const toFormatted = dateTo.format('MMM YYYY');

    return toFormatted;
  };
  
  const getDiffString = (dateFrom, dateTo) => {
    // to make comparision correct, remove the hour and minute part
    const diff = countdown(dateFrom.hour(0).minute(0)
      .toDate(), dateTo.hour(0).minute(0)
      .toDate());

    let diffFormatted = '';

    if(diff.years > 1) {
      diffFormatted += `${diff.years} ${i18n('date.years-short', {lowerCase: true})} `;
    }

    if(diff.years === 1) {
      diffFormatted += `1 ${i18n('date.year-short', {lowerCase: true})} `;
    }

    if(diff.months > 1) {
      diffFormatted += `${diff.months} ${i18n('date.months-short', {lowerCase: true})} `;
    }

    if(diff.months === 1) {
      diffFormatted += `1 ${i18n('date.month-short', {lowerCase: true})} `;
    }
    if(diff.months === 0 && diff.years === 0) {
      if(diff.days > 1) {
        diffFormatted += `${diff.days} ${i18n('date.days-short', {lowerCase: true})} `;
      }
      if(diff.days === 1) {
        diffFormatted += `1 ${i18n('date.day-short', {lowerCase: true})} `;
      }
    }


    return diffFormatted;
  };

  const diffFormatted = getDiffString(dateFrom, dateTo);
  const fromFormatted = getFormattedFrom(dateFrom);
  const toFormatted = getFormattedTo(dateTo);

  return (
    <span>
      {fromFormatted}
      {' '}
      -
      {toFormatted}

      {diffFormatted 
      && (
        <>
          <PointSeparator />
          {diffFormatted} 
        </>
      )}
    </span>
  );
};


/* eslint-disable react/prop-types */
export const WorkElems = ({
  data,
  displayEditBtn = false,
  onEditClick,
  editMode,
}) => {
  const NoDataItem = () =>
    !editMode
      ?         <NoDataDesc>Ingen arbeidserfaringer er lagt til enda.</NoDataDesc>
      : null;
  
  return data && data.length
    ?       data.map(e => (
      <SubCont key={`${e.UID}`}>

        <LeftPart>

          <Header>{e.designation}</Header>
          <Description>{e.name}</Description>
          <Details>{formatDate(e.startdate, e.enddate)}</Details>
        </LeftPart>


        {displayEditBtn && (
          <RightPart>
            <EditExperienceButtonAndIcon
              experienceUID={e.UID}
              onEditClick={onEditClick}
            />

          </RightPart>
        )}
      </SubCont>
    ))
    :       <NoDataItem />
  ;
};
  
export const EducationItems = ({
  data,
  displayEditBtn = false,
  onEditClick,
  editMode,
}) => {
  const NoDataItem = () =>
    !editMode
      ?         <NoDataDesc>Ingen utdanninger er lagt til enda.</NoDataDesc>
      : null;
  
  return data && data.length
    ?       data.map(e => (
      <SubCont key={`${e.UID}`}>
        <LeftPart>

          <Header>{e.designation}</Header>
          <Description>{e.name}</Description>
          <Details>{formatDate(e.startdate, e.enddate, true)}</Details>
        </LeftPart>


        {displayEditBtn && (

          <RightPart>
            <EditExperienceButtonAndIcon
              experienceUID={e.UID}
              onEditClick={onEditClick}
            />

          </RightPart>
        )}

      </SubCont>
    ))
    :       <NoDataItem />
  ;
};
  
export const ProjectItems = ({
  data,
  displayEditBtn = false,
  onEditClick,
  editMode,
}) => {
  const NoDataItem = () =>
    !editMode
      ?         <NoDataDesc>Ingen prosjekter er lagt til enda.</NoDataDesc>
      : null;
  
  return data && data.length
    ?       data.map(e => (
      <SubCont key={`${e.UID}`}>
        <LeftPart>

          <Header>{e.designation}</Header>
          <Description>{e.name}</Description>
          <Details>{formatDate(e.startdate, e.enddate)}</Details>
        </LeftPart>
        {displayEditBtn && (
          <RightPart>

            <EditExperienceButtonAndIcon
              experienceUID={e.UID}
              onEditClick={onEditClick}
            />
          </RightPart>
        )}
      </SubCont>
    ))
    :       <NoDataItem />
  ;
};
  
export const LanguageItems = ({
  data,
  displayEditBtn = false,
  onEditClick,
  editMode,
}) => {
  const NoDataItem = () =>
    !editMode
      ?         <NoDataDesc>Ingen språkkunnskaper er lagt til enda.</NoDataDesc>
      : null;
  
  return data && data.length
    ?       data.map(e => (
      <LanguageCont key={`${e.UID}`}>
        <LeftPart>
          <LanguageName>{e.name}</LanguageName>
          <PointSeparator />
          <LanguageDesc>{e.designation}</LanguageDesc>
        </LeftPart>
         
        {displayEditBtn && (
          <RightPart>


            <EditExperienceButtonAndIcon
              experienceUID={e.UID}
              onEditClick={onEditClick}
            />
          </RightPart>
        )}
      </LanguageCont>
    ))
    :       <NoDataItem />
  ;
};

const EditExperienceButtonAndIcon = ({experienceUID, onEditClick}) => (
  <EditBtnExperience onClick={() => onEditClick(experienceUID)}>
    <EditIcon>+</EditIcon>
    Endre
  </EditBtnExperience>
);

EditExperienceButtonAndIcon.propTypes = {
  experienceUID: PropTypes.number.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
  
export const HonoraryPostVolunteerWorkItems = ({
  data,
  displayEditBtn = false,
  onEditClick,
  editMode,
}) => {
  const NoDataItem = () =>
    !editMode
      ? (
        <NoDataDesc>
          Ingen erfaringer fra frivillig arbeid / tillitsverv er lagt til enda.
        </NoDataDesc>
      )
      : null;
  
  return data && data.length
    ?       data.map(e => (
      <SubCont key={`${e.UID}`}>
        <LeftPart>
          <Header>{e.designation}</Header>
          <Description>{e.name}</Description>
          <Details>{formatDate(e.startdate, e.enddate)}</Details>
        </LeftPart>
        {displayEditBtn && (
          <RightPart>


            <EditExperienceButtonAndIcon
              experienceUID={e.UID}
              onEditClick={onEditClick}
            />
          </RightPart>
        )}
      </SubCont>
    ))
    :       <NoDataItem />
  ;
};

const CvSection = ({
  title,
  missingItemsTxt,
  items,
  titleStyle,
  first,
  centerTitle,
  withEditButton,
  onEditClick,
  children,
  ...props
}) =>  {
  const hasItems =  Array.isArray(items) && items.length > 0;

  return (

    <div {...props}>
      {title && hasItems && (
        <CvSectionTitle first={first}>
          {title}
        </CvSectionTitle>
      )}

      {Array.isArray(items) && items.length === 0
        && <NoDataDesc>{missingItemsTxt}</NoDataDesc> 

        ||  Array.isArray(items) && items.map(item => (
          <SubCont key={`${item.UID}`}>

            <LeftPart>
              <Header>{item.designation}</Header>
              <Description>{item.name}</Description>
              <Details>{formatDate(item.startdate, item.enddate)}</Details>
            </LeftPart>


            {withEditButton && (
              <RightPart>
                <EditExperienceButtonAndIcon
                  experienceUID={item.UID}
                  onEditClick={onEditClick}
                />

              </RightPart>
            )}
          </SubCont>
        ))}
      {children}
    </div>
  );
};


export const CVView = ({
  experiencesDividedByType,
  description,
  disableEdit,
  fullwidth,
}) => {
  const noCVData = Object.values(experiencesDividedByType).length === 0 && !description;
  
  if (noCVData) {
    return (
      <div>
        <MissingCVTxt>Ingen CV er lagt til.</MissingCVTxt>
        {!disableEdit && (
          <AddCVDataTxt>
            <p>
              Trykk på
              {'«Rediger CV»'}
              {' '}
              for å legge inn.
            </p>
          </AddCVDataTxt>
        )}
      </div>
    );
  }
  
  return (
    <>
      <CvSection
        first
        css={css`margin-bottom: 2.4rem;`}
      >
        {description && <Description>{description}</Description>
        ||           (
          <MissingDescription>
            Ingen beskrivelse er lagt inn.
          </MissingDescription>
        )}
      </CvSection>
      <CvSection
        title="Erfaring"
        items={experiencesDividedByType.work}
        missingItemsTxt="Ingen arbeidserfaringer er lagt til enda."
      />
      <CvSection
        title="Utdanning"
        items={experiencesDividedByType.education}
        missingItemsTxt="Ingen utdanninger er lagt til enda."
      />
      <CvSection
        title="Prosjekt"
        items={experiencesDividedByType.project}
        missingItemsTxt="Ingen prosjekter er lagt til enda."
      />

      <CvSection
        title="Frivillig arbeid / tillitsverv"
        items={experiencesDividedByType.elected}
        missingItemsTxt="Ingen erfaringer fra frivillig arbeid / tillitsverv er lagt til enda."
      />

      <CvSection
        title="Språk"
        items={experiencesDividedByType.language}
        missingItemsTxt="Ingen språkkunnskaper er lagt til enda."
      />
    </>
  );
};