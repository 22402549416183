import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import 'focus-visible';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment';
import {routerAppComponentDidMount} from '@actions/router.actions';
import {authLogoutRequest, authGetAuthStatusAndStartLoading, authStartLoginCheckOnInterval} from '@actions/auth.actions';
import {notificationsHide} from '@actions/notifications.actions';
import Loading from '@components/loading/loading';
import {getIsMobile} from '@selectors/global.selectors';
import {isManager, getProfile} from '@selectors/profile.selectors';
import {getShownNotifications} from '@selectors/notifications.selectors';
import {PrivateRoute, LoginContainer} from '@routes/auth';
import {coursesCourseFinished} from '@actions/courses.actions';
import {nanoLearningLoginUrl, version, buildHash} from '@config';
import {getAuthStatus, getStartUrl} from '@selectors/auth.selectors';
import {getActiveCourse} from '@selectors/courses.selectors';
import {getIsMapCompleted} from '@selectors/map.selectors';
import {getConfigObject} from '@selectors/config.selectors';
import {
  employees as employeesRoutes,
  courseCatalog as courseCatalogRoutes,
  myEducation as myEducationRoutes,
  atlas as AtlasRoutes,
  content as contentRoutes,
  atlas as atlasRoutes, employees as routes,
  logout,
} from '@routes/routes.manifest';
import {getTwoletterLangCodeFromNavigatorLanguage} from '@utils/misc.utils';
import '@styles/app.scss';
import CoursePlayer from '@routes/course-catalog/components/course-player/course-player';
import {MyEducationSwitch} from '@routes/my-education';
import CourseCatalogSwitch from '@routes/course-catalog/course-catalog-switch';
import {EmployeesContainer} from '@routes/employees';
import {AtlasContainer} from '@routes/atlas';
import CampExtra from '@routes/camp-extra/camp-extra';
import Report from '@routes/reports/report';
import ContentViewerContainer from '@routes/content/containers/contentViewer/contentViewerContainer';
import Menu from '@components/menu/menu';
import Notifications from '@components/notifications/notifications';
import '@styles/foundation.min.css';
import Cookies from 'js-cookie';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {
  fas,
  faChevronRight,
  faThumbsUp,
  faPlus,
  faUser,
  faCalendar,
  faCalendarAlt,
  faHourglass,
  faChevronUp,
  faUserCheck,
  faChevronDown,
  faFileExcel,
} from '@fortawesome/pro-solid-svg-icons';
import {fad} from '@fortawesome/pro-duotone-svg-icons';
import classNames from 'classnames';
import SignatureModal from '@components/course/signature-modal';
import styled from '@emotion/styled';
import ClearCache from '@components/clear-cache/clear-cache';
import NanoLearningPlayerContainer from '@routes/nano-learning/containers/nano-learning-player-container';
import CourseLoader from '@components/loading/courseLoader';
import {
  configGetConfig,
  configLoadConfigFromLocalStorage,
} from '@actions/config.actions';
import {configObjectShape} from '@types/config';
import {Route} from 'react-router';
import momentLocalizer from 'react-widgets-moment';
import 'moment/locale/nb';
import Localization from 'react-widgets/Localization';
import EmployeeContainer from '@routes/employees/containers/employee-container';

const localisation = new momentLocalizer(moment);

library.add(
  fal,
  far,
  fas,
  fad,
  faChevronRight,
  faThumbsUp,
  faUser,
  faPlus,
  faHourglass,
  faCalendar,
  faCalendarAlt,
  faUserCheck,
  faChevronUp,
  faChevronDown,
  faFileExcel,
);

const NoticeBanner = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  z-index: 999999;
  padding: 20px 0;
  padding-bottom: 4px;
  line-height: 1.5;
  border-top-right-radius: 10px,
  border-top-left-radius: 10px,
  font-size: 1.0rem;
  font-weight: bold;
  text-align: center;

  background-color: var(--color-header-top);
  color: var(--color-header-text) !important;

  span {
    display: inline-block;
    margin-bottom: 16px;
  }

  .btn {
    display: inline-block;
    margin: -0.25em 20px;
    margin-bottom: 16px;
    padding: 0 20px;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.75;
    border: 1px solid white;
    background: var(--color-main-dark);
  }
`;

class App extends Component {
  componentDidMount() {
    const {authStartLoginCheckOnInterval, authGetAuthStatusAndStartLoading} = this.props;

    this.setLanguage();

    if (buildHash || version) {
      // eslint-disable-next-line no-console
      console.log(`${buildHash} v. ${version}\n${window.navigator.userAgent}`);
    }

    // the process is like this:
    // 1. check if the user is logged in or not, display loading while we wait
    // 2. if not logged in: display login, else: continue to step 3
    // 3. get config, display loading while we wait
    // 4. when the config is done loading, load the rest
    authGetAuthStatusAndStartLoading();

    // check if the user is still logged in
    authStartLoginCheckOnInterval({intervalMs: 1000 * 60 * 60});
  }

  setLanguage = () => {
    const langCodeNavigator = getTwoletterLangCodeFromNavigatorLanguage();
    let locale = localStorage.getItem('language');

    if (langCodeNavigator && !locale) {
      locale = langCodeNavigator;
    }

    localStorage.setItem('language', locale);

    const momentLocale = {
      no:'nb',
      en:'en-us ',
    }[locale];

    moment.locale(momentLocale);
    moment.updateLocale(momentLocale, {
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4,  // The week that contains Jan 4th is the first week of the year.
      },
    });
  }

  onEndCourseClick = () => {
    const {coursesCourseFinished, activeCourse} = this.props;
    /*

    /my-education/role/365

    */
    let my_section = 'first-page';

    if (window.location.pathname.includes('/role')) {
      my_section = 'roles';
    }

    coursesCourseFinished({
      cid: activeCourse.cid,
      section: my_section,
    });
  }

  handleToggleParams = () => {
    const isSetMapLocation
      = window.location.pathname.includes('/set-atlas');

    const isLoadConfig
      = window.location.pathname.includes('/load-config');

    const isLoadConfigOnRefreshLocation
      = window.location.pathname.includes('/set-load-config-on-refresh');

    if (isSetMapLocation) {
      const track = window.location.pathname.split('/')[2];
      const params = new URL(document.location).searchParams;

      const langId = params.get('lang');

      localStorage.setItem('track', track);
      localStorage.setItem('language', langId || 'no');
      Object.entries(localStorage).forEach(([k, v]) => {
        if (/^track-data\/\d+$/.test(k)) {
          localStorage.removeItem(k);
        }
      });
    }

    if (isLoadConfigOnRefreshLocation) {
      const value = window.location.pathname.split('/')[2];

      if (!value || value === 'on') {
        localStorage.setItem('loadConfigOnRefresh', 'on');
      } else {
        localStorage.setItem('loadConfigOnRefresh', 'off');
      }
    }

    if (isLoadConfig) {
      const config = window.location.pathname.split('/')[2];

      localStorage.setItem('config', config);
      localStorage.setItem('track', config);
      localStorage.removeItem('learningportalConfig');
      window.location = '/';
    }
  }

  render() {
    const {
      sessionId,
      profile,
      isManager,
      alert,
      activeCourse,
      authLogoutRequest,
      notifications,
      secondLogin,
      hideNotification,
      configObject,
      isMapCompleted,
      startUrl,
      authStatus,
    } = this.props;

    this.handleToggleParams();

    const loginProps = {
      currLanguage:
        localStorage.getItem('language')
        || configObject.getProperty('params.default-lang')
        || getTwoletterLangCodeFromNavigatorLanguage()
        || 'no',
    };

    if (
      !Cookies.get('tg-visit')
      || !sessionId
      || sessionId === 'sd'
      || secondLogin
      || profile.error
      || authStatus.isLoggedIn === false
    ) {
      return (
        <Router basename={process.env.REACT_APP_MOUNT_PATH}>
          <div className="app-wrapper menu-two coop">
            {nanoLearningLoginUrl
                && (
                  <LoginContainer
                    to={nanoLearningLoginUrl}
                    {...loginProps}
                  />
                )
               || (
                 <>
                   <LoginContainer {...loginProps} />
                   <Redirect
                     from="*"
                     to="/login"
                   />
                 </>
               )}
          </div>
        </Router>
      );
    }


    const useMap = configObject.isMapActivated;

    const waitToShowApp = false;

    const getRedirectRoute = () => {
      if(useMap && !isMapCompleted) {
        return  startUrl || configObject.getProperty('params.default-route') || atlasRoutes.main.path;
      }
      if(useMap && isMapCompleted) {
        if (configObject.isModuleEnabled('coursecatalog')) {
          return courseCatalogRoutes.main.path;
        }else {
          return  startUrl || configObject.getProperty('params.default-route') || atlasRoutes.main.path;
        }
      }
      if(!useMap) {
        return startUrl || configObject.getProperty('params.default-route')  || courseCatalogRoutes.main.path;
      }

      return undefined;
    };

    return (
      <Localization
        date={localisation}
      >
        {configObject.data && authStatus.isLoggedIn && (
          <Router basename={process.env.REACT_APP_MOUNT_PATH}>
            {activeCourse && activeCourse.type === 24
            && <NanoLearningPlayerContainer courseId={activeCourse.cid} />
            || (
              <div
                className={classNames('app-wrapper', configObject.getProperty('params.menu.type') || 'menu-two')}
              >
                <Route
                  path={routes.employeePreview}
                  render={({match: {url, params: {userName}}}) => (
                    <EmployeeContainer
                      userName={userName}
                      url={url}
                      className="employees-page__section employees-page__section--columns employees-page__section--preview fullwidth"
                    />
                  )}
                />
                <div className="app-wrapper__menu">
                  <Menu
                    isManager={isManager}
                    onLogoutClick={() => {
                      authLogoutRequest();
                    }}
                    profileData={profile.data}
                  />
                </div>
                <div className="app-wrapper__content">
                  {activeCourse && activeCourse.status !== 0 && (
                    <CoursePlayer
                      reloading={alert.type === 'alert-reloading'}
                      onEnd={this.onEndCourseClick}
                      id={activeCourse.cid}
                      cid={activeCourse.cid}
                      type={activeCourse.type}
                      url={activeCourse.url}
                      opened={activeCourse.opened}
                      timestamp={activeCourse.timestamp}
                    />
                  )}
                  {waitToShowApp && <CourseLoader />}
                  {!waitToShowApp && (
                    <Switch>
                      {!Cookies.get('tg-visit')
                     || profile.error && <LoginContainer {...loginProps} />}
                      <Route
                        path={logout}
                        render={() => {
                          authLogoutRequest();
                        }}
                      />
                      <PrivateRoute
                        path={courseCatalogRoutes.main.path}
                        component={CourseCatalogSwitch}
                      />
                      {/* Things like articles, videos etc */}
                      <PrivateRoute
                        path={contentRoutes.main.path}
                        component={ContentViewerContainer}
                      />
                      <PrivateRoute
                        path={myEducationRoutes.main.path}
                        component={MyEducationSwitch}
                      />
                      <PrivateRoute
                        path={employeesRoutes.campExtra}
                        component={CampExtra}
                      />
                      <PrivateRoute
                        path={[employeesRoutes.report, employeesRoutes.reportNew]}
                        component={Report}
                      />
                      <PrivateRoute
                        path={Object.values(employeesRoutes)}
                        component={EmployeesContainer}
                      />
                      <PrivateRoute
                        path={AtlasRoutes.main.path}
                        component={AtlasContainer}
                      />
                      <Redirect
                        to={
                       getRedirectRoute()
                     }
                      />
                    </Switch>
                  )}
                  <SignatureModal />
                  <Notifications
                    notifications={notifications}
                    onHideNotification={hideNotification}
                  />
                  {(version || buildHash) && (
                    <div
                      style={{
                        left: '10px',
                        bottom: '10px',
                        position: 'fixed',
                        opacity: '0.3',
                        padding: '10px',
                        fontSize: '0.8rem',
                      }}
                    >
                      {`${buildHash} v. ${version}`}
                    </div>
                  )}
                </div>
                {alert.type === 'alert-reloading'
              && (configObject.getProperty('params.reloading-indicator-variant') === 'fullscreen'
                ? alert.message === 'course-done'
                 && <CourseLoader />
                 || <CourseLoader />
                : <Loading />
              )}
                <ClearCache>
                  {({message, isLatestVersion, emptyCacheStorage}) =>
                    !isLatestVersion && (
                      <NoticeBanner>
                        <span>
                          {message
                         || 'Ny versjon er lansert. Klikk refresh for ny versjon.'}
                        </span>
                        <button
                          type="button"
                          className="btn"
                          zIndex="0"
                          onClick={() => {
                            localStorage.removeItem('learningportalConfig');
                            emptyCacheStorage();
                          }}
                        >
                          REFRESH
                        </button>
                      </NoticeBanner>
                    )}
                </ClearCache>
              </div>
            )}
          </Router>
        ) || (
          <CourseLoader
            appload
          />
        )}
      </Localization>
    );
  }
}

App.propTypes = {
  sessionId: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  profile: PropTypes.shape({}).isRequired,
  activeCourse: PropTypes.shape({}),
  isManager: PropTypes.bool.isRequired,
  secondLogin: PropTypes.bool.isRequired,
  coursesCourseFinished: PropTypes.func.isRequired,
  alert: PropTypes.shape({}).isRequired,
  notifications: PropTypes.shape({}).isRequired,
  routerAppComponentDidMount: PropTypes.func.isRequired,
  authLogoutRequest: PropTypes.func.isRequired,
  hideNotification: PropTypes.func.isRequired,
  configObject: configObjectShape,
  isMapCompleted: PropTypes.any,
  configLoadConfigFromLocalStorage: PropTypes.func.isRequired,
  configGetConfig: PropTypes.func.isRequired,
  authStatus: PropTypes.shape({}).isRequired,
  startUrl: PropTypes.string.isRequired,
  authGetAuthStatusAndStartLoading: PropTypes.func.isRequired,
  authStartLoginCheckOnInterval: PropTypes.func.isRequired,
};

App.defaultProps = {
  sessionId: undefined,
  activeCourse: null,
  isMapCompleted: null,
};

const mapStateToProps = state => ({
  sessionId: state.auth.sessionId,
  secondLogin: state.auth.secondLogin,
  alert: state.alert,
  activeCourse: getActiveCourse(state),
  profile: getProfile(state),
  notifications: getShownNotifications(state),
  isManager: isManager(state),
  isMobile: getIsMobile(state),
  configObject: getConfigObject(state),
  isMapCompleted: getIsMapCompleted(state),
  startUrl: getStartUrl(state),
  authStatus: getAuthStatus(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerAppComponentDidMount,
      authLogoutRequest,
      coursesCourseFinished,
      hideNotification: notificationsHide,
      configGetConfig,
      configLoadConfigFromLocalStorage,
      authGetAuthStatusAndStartLoading,
      authStartLoginCheckOnInterval,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
