import Cookies from 'js-cookie';
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_SUCCESS_CHOICE,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_UNAUTHORIZED,
  AUTH_LOGIN_GOTO_ON,
  AUTH_LOGIN_ATTEMTPS_REQUEST,
  AUTH_LOGIN_NEW_PASSWORD_REQUEST,
  AUTH_LOGIN_NEW_PASSWORD_SUCCESS,
  AUTH_LOGIN_NEW_PASSWORD_FAILURE,
  AUTH_CHECK_LOGIN_METHOD_REQUEST,
  AUTH_CHECK_LOGIN_METHOD_SUCCESS,
  AUTH_CHECK_LOGIN_METHOD_FAILURE,
  AUTH_CLOSE_RESET_PASSWORD_FORM,
  AUTH_USER_IS_CONFIRMED_LOGGED_IN,
  AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN,
} from '@actions/auth.actions';

const initialState = {
  authProfile: undefined,
  newPwd: undefined,
  stage: 'username',
  loginAttempts: 0,
  secondLogin: false,
  secondData: undefined,
  sessionId: Cookies.get('tg-visit') || 'sd',
  authStatus: {},
};

const auth = (state = initialState, action) => {
  switch (action.type) {
  case AUTH_LOGIN_REQUEST:
  case AUTH_LOGIN_FAILURE:
  case AUTH_LOGOUT_SUCCESS:
  case AUTH_UNAUTHORIZED:
    return {
      ...state,
      authProfile: undefined,
      stage: 'username',
      sessionId: undefined,
    };
  case AUTH_CHECK_LOGIN_METHOD_REQUEST:
    return {
      ...state,
      stage: 'username',
    };
  case AUTH_LOGIN_ATTEMTPS_REQUEST:
    return {
      ...state,
      loginAttempts: state.loginAttempts + 1,
    };
  case AUTH_CHECK_LOGIN_METHOD_SUCCESS:
    return {
      ...state,
      stage: action.payload.message,
    };
  case AUTH_CHECK_LOGIN_METHOD_FAILURE:
    return {
      ...state,
      stage: 'username',
    };
  case AUTH_LOGIN_GOTO_ON:
    return {
      ...state,
      secondLogin: false,
      secondData: undefined,
    };
  case AUTH_LOGIN_SUCCESS:
    return {
      ...state,
      authProfile: action.payload.authProfile,
      sessionId: action.payload.authProfile.session_id,
      secondLogin: false,
      startUrl: action.payload.start_url,
    };
  case AUTH_LOGIN_SUCCESS_CHOICE:
    return {
      ...state,
      authProfile: action.payload.authProfile,
      sessionId: action.payload.authProfile.session_id,
      secondLogin: true,
      secondData: {
        username: action.payload.username,
        password: action.payload.password,
      },
    };
  case AUTH_LOGIN_NEW_PASSWORD_REQUEST:
    return {
      ...state,
      newPwdStatus: -1,
      newPwd: null,
    };
  case AUTH_LOGIN_NEW_PASSWORD_SUCCESS:
    return {
      ...state,
      newPwdStatus: 2,
      newPwd: action.payload.message,
    };
  case AUTH_LOGIN_NEW_PASSWORD_FAILURE:
    return {
      ...state,
      newPwdStatus: 1,
      newPwd: action.payload.message,
    };
  case AUTH_CLOSE_RESET_PASSWORD_FORM:
    return {
      ...state,
      newPwdStatus: -1,
    };
  case AUTH_USER_IS_CONFIRMED_LOGGED_IN:
    return {
      ...state,
      authStatus:{
        ...state.authStatus,
        isLoggedIn: true,
        lastCheck: new Date(),
      },
    };
  case AUTH_USER_IS_CONFIRMED_NOT_LOGGED_IN:
    return {
      ...state,
      authStatus:{
        isLoggedIn: false,
        lastCheck: new Date(),
      },
    };

  default:
    return state;
  }
};

export default auth;
