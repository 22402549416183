import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from 'react-widgets/DatePicker';
import { StyledLabel,InputWrapper} from '@components/form-input/form-input-styles';

const FormDatePicker = ({className, input, label, meta, ...props}) => (
  <InputWrapper>
    {label && (
      <StyledLabel
        htmlFor={input.name}
      >
        {label}
      </StyledLabel>
    )}

    <DatePicker
      {...props}
      {...input}
      dateForm="DD.MM.YYYY"
      id={input.name}
      time={false}
      value={input.value ? new Date(input.value) : null}
      className={classnames('', className, {'form-input__input--error': meta.touched && meta.error})}
    />
    {meta.touched && meta.error 
      && <div className="form-input__error">{meta.error}</div>}
  </InputWrapper>
);

FormDatePicker.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormDatePicker.defaultProps = {
  className: '',
  label: '',
};

export default FormDatePicker;

// input.value ? null : new Date(input.value)
