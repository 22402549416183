import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {mq, or} from '@styles/facepaint';
import {highlightOnKeyboardFocusMixinExtra} from '@styles/mixins';
import {motion} from 'framer-motion';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {darken} from 'polished';

const secondaryImagePositionOffset = '1em';
const iconPositionOffset = '0.75em';

export const wrapperStyle = css`
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: ${props => props.background || 'unset'};
  flex-direction: column;
  width: 100%;
`;

export const MainImage = styled.div`
  background-color: #f3f3f3;
  background-image: url('${props => props.src}');
  background-size: cover;
  display: flex;
  align-self: stretch;
  width: 100%;
  height: 100%;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  flex-grow: 1;
  width: 100%;
`;

export const MainText = styled.h3`
  margin: 0;
  color: black;
  font-weight: normal;
  font-size: 1.1em;
  hyphens: auto;
  word-wrap: anywhere;
`;

export const SecondaryTextWrapper = styled.div`
  margin: 0;
  padding-top: 0.5em;
  color: #5c5c5c;
`;
export const SecondaryText = styled.span`
  display: inline;
  padding-right: 0.3em;
  p {
    margin: 0;
    display: inline;
  }
`;

export const TextAndButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.2em;
  padding-top: 0.9em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  background: #fafafa;
  flex-grow: 1;
  align-items: center;
`;

export const IconsWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  visibility: hidden;
  ${props =>
  ({
    'top-left': css`
        left: ${iconPositionOffset};
        top: ${iconPositionOffset};
      `,
    'bottom-left': css`
        left: ${iconPositionOffset};
        bottom: ${iconPositionOffset};
      `,
    'top-right': css`
        right: ${iconPositionOffset};
        top: ${iconPositionOffset};
      `,
    'bottom-right': css`
        right: ${iconPositionOffset};
        bottom: ${iconPositionOffset};
      `,
  }[props.placement])};

  display: flex;
  ${props =>
    props.displayAsCircle
      ? css`
          padding: 0.18em 0.45em 0.25em 0.7em;
          background: white; 
          border-radius: 50%;
        `
      : ''};
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${props => props.color || 'black'};
  font-size: ${props => props.fontSize || '1.4em'};
`;

export const Icon2 = styled.div`
  &::before{
    font-family: fontawesome-5-pro-solid;
    display: contents;
    font-style: normal;
    font-variant: normal;
    font-size: 0.7em;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: black;
    position: relative;
    left: -0.07em;
  }
`;

export const ImageContentWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  /*Draw the border inside the image*/
  &::before {
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
`;

export const MetaIcon = styled(Icon)`
  position: static;
  margin-right: 0.5em;
  font-size: 1em;
  color: #5c5c5c;
`;

export const MetaText = styled(SecondaryTextWrapper)`
  padding: 0;
`;

export const MetaInformationLine = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.1em;
  padding-top: 0.05em;
`;

export const MetaInformationWrapper = styled.div`
  ${props =>
  props.location === 'image-part'
    && css`
      position: absolute;
      width: 100%;
      background: rgba(0, 0, 0, 0.35);
      bottom: 0;
      padding: 0.3em 1.1em 0.3em 0.5em;

      ${MetaInformationLine} {
        justify-content: space-between;
      }

      ${MetaIcon} {
        font-size: 1.1em;
      }

      ${MetaIcon},${MetaText} {
        color: white;
      }
    `}
  ${props =>
      props.location === 'text-part'
    && css`
      ${MetaInformationLine} {
        justify-content: flex-start;
      }
      ${MetaIcon},${MetaText} {
        color: #5c5c5c;
      }
    `}
`;

export const SecondaryImage = styled.div`
  position: absolute;

  ${props =>
  ({
    'top-left': css`
        left: ${secondaryImagePositionOffset};
        top: ${secondaryImagePositionOffset};
      `,
    'bottom-left': css`
        left: ${secondaryImagePositionOffset};
        bottom: ${secondaryImagePositionOffset};
      `,
    'top-right': css`
        right: ${secondaryImagePositionOffset};
        top: ${secondaryImagePositionOffset};
      `,
    'bottom-right': css`
        right: ${secondaryImagePositionOffset};
        bottom: ${secondaryImagePositionOffset};
      `,
  }[props.placement])};
  width: 2.9em;
  height: 2.9em;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-position: center;
  border: 2px solid white;
  background-color: #8080808a;
  background-image: url('${props => props.src}');
`;

export const ReadMore = styled(SecondaryTextWrapper)`
  display: inline;
  border-bottom: 1px dotted #707070;
  white-space: nowrap;

  :hover {
    color: #5683b3;
  }
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
  margin-bottom: auto;
`;

export const ActionButton = styled.button`
  color: white;
  background: #007aff;
  padding: 0.72em 0.8em;
  border-radius: 6px;

  ${props => props.dontShow ? css`display: none;` : ''}

  :hover {
    background: ${darken(0.1, '#007AFF')};
    cursor: pointer;
  }
`;