import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CompetenceCourse from '@components/competences/competence-course';
import CompetenceEcourse from '@components/competences/competence-ecourse';
import CompetenceNano from '@components/competences/competence-nano';
import CompetenceEquivalents from '@components/competences/competence-equals';
import CompetenceChecklist from '@components/competences/competence-checklist';
import CompetenceChecklistItem from '@components/competences/competence-checklist-item';
import CompetenceComplex from '@components/competences/competence-complex';
import CompetenceSignature from '@components/competences/competence-signature';
import CompetenceVerification from '@components/competences/competence-verification';

class CompetenceList extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const {
      courses,
      root,
      isSubFetching,
      size,
      onToggleCompetence,
    } = this.props;

    return (
      <div>
        {root && root !== 'employee-activites' && (
          <div>
            {courses.missing.map(competence => (
              <div key={`competence-list${competence.competence_id}`}>
                {competence.competence_type.competence_type === 'ecourse' && (
                  <CompetenceEcourse
                    roleName={courses.roleId[competence.competence_id]}
                    type="ecourse"
                    size={size}
                    course={competence}
                  />
                )
                  || competence.competence_type.competence_type === 'course' && (
                    <CompetenceCourse
                      type="course"
                      size={size}
                      course={competence}
                    />
                  )
                || competence.competence_type.competence_type === 'nano' && (
                  <CompetenceNano
                    type="nano"
                    size={size}
                    course={competence}
                  />
                )
                  || competence.competence_type.competence_type
                    === '"verification"' && (
                      <CompetenceVerification
                        type="verification"
                        size={size}
                        course={competence}
                      />
                  )
                  || competence.competence_type.competence_type
                    === 'digital_signature' && (
                      <CompetenceSignature
                        type="signature"
                        size={size}
                        course={competence}
                      />
                  )
                  || competence.competence_type.competence_type === 'complex' && (
                    <CompetenceComplex
                      isFetching={isSubFetching}
                      type="complex"
                      size={size}
                      course={competence}
                    />
                  )
                  || competence.competence_type.competence_type
                    === 'equivalents' && (
                      <CompetenceEquivalents
                        isFetching={isSubFetching}
                        roleName={courses.roleId[competence.competence_id]}
                        type="equivalents"
                        size={size}
                        course={competence}
                      />
                  )
                  || competence.competence_type.competence_type
                    === 'checklist' && (
                      <CompetenceChecklist
                        isFetching={isSubFetching}
                        type="checklist"
                        size={size}
                        course={competence}
                      />
                  )
                  || competence.competence_type.competence_type
                    === 'checklist_item' && (
                      <CompetenceChecklistItem
                        isFetching={isSubFetching}
                        type="checklist-item"
                        size={size}
                        course={competence}
                        onClick={() => onToggleCompetence()}
                      />
                  )}
              </div>
            ))}
          </div>
        ) || (
          <div>
            {courses
              && courses.map(competence => (
                <div key={`competence-list-sub-${competence.competence_id}`}>
                  {competence.competence_type.competence_type
                    === 'ecourse' && (
                      <CompetenceEcourse
                        type="ecourse"
                        size={size}
                        course={competence}
                      />
                  )
                    || competence.competence_type.competence_type
                      === 'course' && (
                        <CompetenceCourse
                          type="course"
                          size={size}
                          course={competence}
                        />
                    )
                    || competence.competence_type.competence_type
                      === 'digitalsignature' && (
                        <CompetenceEcourse
                          type="signature"
                          size={size}
                          course={competence}
                        />
                    )
                    || competence.competence_type.competence_type
                      === 'complex' && (
                        <CompetenceComplex
                          isFetching={isSubFetching}
                          type="complex"
                          root={root}
                          size={size}
                          course={competence}
                        />
                    )
                  || competence.competence_type.competence_type
                    === 'digital_signature' && (
                      <CompetenceSignature
                        isFetching={isSubFetching}
                        type="signature"
                        root={root}
                        size={size}
                        course={competence}
                      />
                  )
                    || competence.competence_type.competence_type
                      === 'equivalents' && (
                        <CompetenceEquivalents
                          isFetching={isSubFetching}
                          type="equivalents"
                          root={root}
                          size={size}
                          course={competence}
                        />
                    )
                    || competence.competence_type.competence_type
                      === 'checklist' && (
                        <CompetenceChecklist
                          isFetching={isSubFetching}
                          type="checklist"
                          size={size}
                          root={root}
                          course={competence}
                        />
                    )
                    || competence.competence_type.competence_type
                      === 'checklist_item' && (
                        <CompetenceChecklistItem
                          isFetching={isSubFetching}
                          type="checklist-item"
                          size={size}
                          course={competence}
                          onClick={() => onToggleCompetence}
                        />
                    )}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

CompetenceList.defaultProps = {
  size: 'compact',
  onToggleCompetence: () => {},
};

CompetenceList.propTypes = {
  size: PropTypes.string,
  courses: PropTypes.shape({}).isRequired,
  root: PropTypes.bool.isRequired,
  onToggleCompetence: PropTypes.func,
  isSubFetching: PropTypes.bool.isRequired,
};

export default CompetenceList;
