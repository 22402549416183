import {number, string, shape, any} from 'prop-types';

export const RoleShape = () =>
  shape({
    description: string,
    grace_period: any,
    id: number,
    mandatory: any,
    organisation_id: any,
    organisation_ids: any,
    organisations: any,
    owner_organisation: any,
    owner_person: any,
    role_id: number,
    role_type_id: number,
    rolemetatype: string,
    roletype_name: string,
    title: string,
  });

export const OrganisationShape = () =>
  shape({
    country: string,
    organisation_id: number,
    extern_organisation_id: any,
    id: number,
    title: string,
  });
