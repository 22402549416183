import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {darken, rgba, border} from 'polished';

export const Checkbox = styled('input')({});

const disabledContent = css({
  '&:disabled': {
    cursor: 'not-allowed',
    color: 'black',
    backgroundColor: '#DCDCDF',

    '> *': {pointerEvents: 'none'},
  },
});

export const focus = css({outline: `4px solid ${rgba('#007AFF', 0.5)}`});

export const baseLabelStyle = css({
  padding: 12,
  span: {marginLeft: 10},
});

export const Button = styled('button')(
  {
    padding: '1em',
    color: 'white',
    borderRadius: 8,
    backgroundColor: '#1b9b6f',
    transition: 'background-color 300ms',

    '&:hover': {
      backgroundColor: darken(0.1, '#1b9b6f'),
      cursor: 'pointer',
    },
  },
  disabledContent,
);

export const ApprovedSticker = styled('img')({});

export const VerifyConfirmDescription = styled('span')({'p:first-child': {display:'inline'}});
