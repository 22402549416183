import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {isValidDate} from '@utils/misc.utils';
import CVEditExperienceType from './edit-experience-type/edit-experience-type';

import {
  WorkElems,
  EducationItems,
  ProjectItems,
  LanguageItems,
  HonoraryPostVolunteerWorkItems,
} from '../view/cv-view';

import UnsavedChangesDisplay from './unsaved-changes-display';
import {profileEditCv} from '@actions/profile.actions';

import {Header, Desc, DescriptionEdit} from './cv-edit-styles';
import { EducationFormElements, HonoraryOrVolunteerElements, LanguageFormElements, ProjectFormElements, WorkExperiencesFormElements } from './form-elements';



export const CVEdit = ({
  initialExperienceData,
  initialDescription,
  cvID,
  onSave,
  onSaved,
}) => {
  const [experienceData, setExperienceData] = useState(initialExperienceData);

  const [description, setDescription] = useState(initialDescription);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const dispatch = useDispatch();

  const saveData = () => {
    onSave();
    const padWithZeroIfLessThan10 = val => val < 10 ? `0${val}` : val;
    const createDateString = date => {
      if (!date) {
        return '';
      }

      let dateToFormat = date;

      if (!isValidDate(date)) {
        dateToFormat = new Date(date);
      }
      if (/^\d{4}$/.test(date)) {
        const year = new Date();

        year.setDate(0);
        year.setMonth(0);
        year.setHours(0);
        year.setMinutes(0);
        year.setMilliseconds(0);
        year.setFullYear(Number.parseInt(date, 10));
        dateToFormat = year;
      }

      return `${dateToFormat.getFullYear()}-${padWithZeroIfLessThan10(dateToFormat.getMonth() + 1, // Since dates are 0-based in js but stored as 1-based
      )}-${padWithZeroIfLessThan10(dateToFormat.getDate())}`;
    };

    const flattedExperiences = [];

    Object.getOwnPropertyNames(experienceData).forEach(key => {
      experienceData[key].forEach(e => {
        const curr = e;

        curr.startdate = createDateString(curr.startdate);
        curr.enddate = createDateString(curr.enddate);
        curr.experience_type = key;
        delete curr.UID;
        flattedExperiences.push(curr);
      });
    });

    const dataRequest = {
      id: cvID,
      description,
      experiences: flattedExperiences,
    };

    dispatch(profileEditCv({
      data: dataRequest,
      onSuccessCallback: () => {
        onSaved();
      },
    }));
    setHasUnsavedChanges(false);
  };

  const updateData = (kind, changedData) => {
    const copyOfStoredExperienceData = experienceData;

    copyOfStoredExperienceData[kind] = changedData;
    setExperienceData(copyOfStoredExperienceData);
    setHasUnsavedChanges(true);
  };


  const formSchemaWork = Yup.object().shape({
    designation: Yup.string().required('Stilling må fylles ut'),
    name: Yup.string(),
    startdate: Yup.string().nullable(),
    enddate: Yup.string().nullable(),
  });

  const formSchemaHonoraryVolunteer = Yup.object().shape({
    designation: Yup.string().required('Stilling/oppgave må fylles ut'),
    name: Yup.string(),
    startdate: Yup.string().nullable(),
    enddate: Yup.string().nullable(),
  });

  const formSchemaEducation = Yup.object().shape({
    designation: Yup.string().required('Sted må fylles ut'),
    name: Yup.string().required('Navn på utdanning må fylles ut'),
    startdate: Yup.string()
      .matches(/(\d{4})?/, 'År må være på dette formatet ÅÅÅÅ')
      .nullable(),
    enddate: Yup.string()
      .matches(/(\d{4})?/, 'År må være på dette formatet ÅÅÅÅ')
      .nullable(),
  });

  const formSchemaProject = Yup.object().shape({
    designation: Yup.string().required('Navn må fylles ut'),
    name: Yup.string(),
    startdate: Yup.string().nullable(),
    enddate: Yup.string().nullable(),
  });

  const formSchemalanguage = Yup.object().shape({
    name: Yup.string().required('Språk må fylles ut'),
    designation: Yup.string().required('Fedighet i språket må fylles ut'),
  });

  return (
    <>
      {hasUnsavedChanges && <UnsavedChangesDisplay onSave={saveData} />}
      <Header>Om</Header>
      <Desc>Generell beskrivelse av din kompetanse</Desc>

      {/* Do not wrap this element (or use in styled component). It will cause problems related to focus */}
      <DescriptionEdit
        key="ierioreioreioreio"
        rows="8"
        cols="80"
        value={description}
        onChange={evt => {
          setDescription(evt.target.value);
          setHasUnsavedChanges(true);
        }}
      />
      <CVEditExperienceType
        title="Arbeidserfaring"
        experienceKind="work"
        description="Registrer alle dine relevante jobberfaringer. Det er opp til deg selv å vurdere hva som er relevant eller ikke. Har du mange år i arbeidslivet, ta med det viktigste og det som er relevant ift. jobben du søker. Husk også å unngå “hull i CV-en” (perioder uten noen oppføringer i CV-en). Har du det, har du kanskje utelukket noe som bør være med."
        addNewBtnTxt="Legg til ny arbeidserfaring"
        experienceListComponent={WorkElems}
        onAlteredData={updateData}
        formSchema={formSchemaWork}
        manageExperienceFormElementsComponent={
          WorkExperiencesFormElements
        }
        initialExperienceData={experienceData.work || []}
      />

      <CVEditExperienceType
        title="Utdanning"
        description="Liste over din formelle utdanning."
        experienceKind="education"
        addNewBtnTxt="Legg til ny utdanning"
        onAlteredData={updateData}
        experienceListComponent={EducationItems}
        formSchema={formSchemaEducation}
        manageExperienceFormElementsComponent={EducationFormElements}
        initialExperienceData={experienceData.education || []}
      />

      <CVEditExperienceType
        title="Prosjekt"
        experienceKind="project"
        description="Relevante prosjekter du ønker å fremheve."
        addNewBtnTxt="Legg til nytt prosjekt"
        onAlteredData={updateData}
        experienceListComponent={ProjectItems}
        formSchema={formSchemaProject}
        manageExperienceFormElementsComponent={ProjectFormElements}
        initialExperienceData={experienceData.project || []}
      />

      <CVEditExperienceType
        title="Frivillig arbeid / tillitsverv"
        experienceKind="elected"
        description="Frivillig arbeid og tillitsverv du har htt."
        addNewBtnTxt="Legg til ny arbeidserfaring for frivillig arbeid / tillitsverv"
        onAlteredData={updateData}
        experienceListComponent={HonoraryPostVolunteerWorkItems}
        formSchema={formSchemaHonoraryVolunteer}
        manageExperienceFormElementsComponent={
          HonoraryOrVolunteerElements
        }
        initialExperienceData={experienceData.elected || []}
      />

      <CVEditExperienceType
        title="Språk"
        description="Dine skriftlige og muntlige språkferdigheter."
        addNewBtnTxt="Legg til ny språkferdighet"
        experienceKind="language"
        onAlteredData={updateData}
        experienceListComponent={LanguageItems}
        formSchema={formSchemalanguage}
        manageExperienceFormElementsComponent={LanguageFormElements}
        initialExperienceData={experienceData.language || []}
      />
    </>
  );
};

CVEdit.propTypes = {
  initialExperienceData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialDescription: PropTypes.string.isRequired,
  profileEditCV: PropTypes.func.isRequired,
  cvID: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

CVEdit.defaultProps = {};


export default CVEdit;
