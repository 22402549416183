import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import reports from '@routes/reports.manifest';

import {getRouteWithParams} from '@utils/routes.utils';

import '../styles/mobile-report.scss';

const MobileReport = ({routes, statistics, showCampExtra}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="mobile-report">
      <div className="mobile-report__main-wrapper">
        <button
          type="button"
          onClick={() => setOpen(!isOpen)}
          className="mobile-report__select"
        >
          <div className="employees-link-card mobile-report__button-wrapper">
            <div className="employees-link-card__label mobile-report__select-button mobile-report__select-button--main">
              <span>Rapporter</span>
              <i className={`fas fa-caret-${isOpen ? 'up' : 'down'}`} />
            </div>
          </div>
        </button>
      </div>
      {isOpen && (
        <div className="mobile-report__options">
          <div className="employees-page__users-overview-wrapper mobile-report__option">
            <div className="employees-link-card mobile-report__option-title">
              Rapporter - søk
            </div>
          </div>
          <Link
            className="employees-page__users-overview-wrapper mobile-report__option"
            to={routes.reportBrandschool}
          >
            <div className="employees-link-card mobile-report__option-content">
              <div className="employees-link-card__label">
                Obligatorisk kompetanse
              </div>
            </div>
          </Link>
          {/* <Link
            className="employees-page__users-overview-wrapper mobile-report__option"
            to={routes.reportSearch}>
            <div className="employees-link-card mobile-report__option-content">
              <div className="employees-link-card__label">Søk kompetanse</div>
            </div>
          </Link> */}
          {showCampExtra && (
            <Link
              className="employees-page__users-overview-wrapper mobile-report__option"
              to={routes.campExtra}
            >
              <div className="employees-link-card mobile-report__option-content">
                <div className="employees-link-card__label">Camp Extra</div>
              </div>
            </Link>
          )}
          <div className="employees-page__users-overview-wrapper mobile-report__option">
            <div className="employees-link-card mobile-report__option-title">
              Oversikts matriser
            </div>
          </div>
          <Link
            className="employees-page__users-overview-wrapper mobile-report__option"
            to={getRouteWithParams(routes.reportPreview, {reportId: Object.keys(reports)[0]})}
          >
            <div className="employees-link-card mobile-report__option-content">
              <div className="employees-link-card__label">All kompetanse</div>
            </div>
          </Link>
          {statistics.data
            && statistics.data.functions.map(statistic => (
              <Link
                className="employees-page__users-overview-wrapper mobile-report__option"
                to={getRouteWithParams(routes.reportPreview, {reportId: statistic.role_id})}
              >
                <div className="employees-link-card mobile-report__option-content">
                  <div className="employees-link-card__label">
                    {statistic.title}
                  </div>
                </div>
              </Link>
            ))}
        </div>
      )}
    </div>
  );
};

MobileReport.propTypes = {
  mainOrganisation: PropTypes.shape({}).isRequired,
  routes: PropTypes.shape({}).isRequired,
  statistics: PropTypes.shape({}).isRequired,
  showCampExtra: PropTypes.bool.isRequired,
};

export default MobileReport;
