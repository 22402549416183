const prefix = 'config';

export const CONFIG_GET_CONFIG = `${prefix} get learning portal config`;
export const CONFIG_GET_CONFIG_REQUEST = `${prefix} get learning portal config request`;
export const CONFIG_GET_CONFIG_SUCCESS = `${prefix} get learning portal config success`;
export const CONFIG_GET_CONFIG_FAILURE = `${prefix} get learning portal config failure`;

export const CONFIG_LOAD_FROM_LOCALSTORAGE = `${prefix} get learning portal config from localstorage`;

export const configGetConfig = payload => ({
  type: CONFIG_GET_CONFIG,
  payload,
});

export const configGetConfigRequest = () => ({type: CONFIG_GET_CONFIG_REQUEST});

export const configGetConfigSuccess = payload => ({
  type: CONFIG_GET_CONFIG_SUCCESS,
  payload,
});

export const configGetConfigFailure = payload => ({
  type: CONFIG_GET_CONFIG_FAILURE,
  payload,
});

export const configLoadConfigFromLocalStorage = payload => ({
  type: CONFIG_LOAD_FROM_LOCALSTORAGE,
  payload,
});
