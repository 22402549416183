import React from 'react';
import PropTypes from 'prop-types';

const Role = ({competences}) => (
  <div className="role-path">
    hoppla
    {competences}
  </div>
);

Role.propTypes = {competences: PropTypes.arrayOf(PropTypes.shape({})).isRequired};

export default Role;
