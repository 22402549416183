import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './form-select.scss';

class FormSelect extends Component {
  constructor(props) {
    super(props);

    const {options, defaultValue} = props;

    if (defaultValue !== null) {
      this.state = {value: defaultValue};
    } else if (defaultValue === null) {
      this.state = {value: null};
    } else if (options.length !== 0 && defaultValue !== null) {
      this.state = {value: options[0].id};
    } else {
      this.state = {value: 0};
    }

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect({target: {value}}) {
    this.setState({value});
  }

  render() {
    const {value} = this.state;

    const {
      className,
      label,
      input,
      options,
      defaultValue,
      optionProps,
      values,
      meta,
      ...rest
    } = this.props;

    const select_options = options.map(o => o.title);
    const select_values = options.map(o => o.id);

    return (
      <div className="form-input form-select">
        {label && (
          <label
            htmlFor={input.name}
            className="form-input__label"
          >
            {label}
          </label>
        )}
        <select
          {...input}
          id={input.name}
          onChange={this.handleSelect}
          value={value}
          className={classNames([
            'form-select__select',
            'form-input__input',
            className,
            {'form-input__input--error': meta.touched && meta.error},
          ])}
        >
          {defaultValue === null ? (
            /* Remember to change value in validateRole in employee-add.jsx if you change the text in option disabled selected */
            <option
              disabled
              selected
            >
              Velg fra listen
            </option>
          ) : null}
          {select_options.map((option, id) => (
            <option
              key={`option-${select_values[id]}`}
              {...optionProps}
              value={select_values[id]}
              {...rest}
            >
              {option}
            </option>
          ))}
        </select>
        {meta.touched && meta.error 
          && <div className="form-input__error">{meta.error}</div>}
      </div>
    );
  }
}

FormSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  optionProps: PropTypes.shape({}),
  values: PropTypes.arrayOf(PropTypes.string),
  input: PropTypes.shape({}),
};

FormSelect.defaultProps = {
  className: null,
  label: null,
  optionProps: {},
  values: null,
  input: {},
};

export default FormSelect;
