import PropTypes, {shape, bool} from 'prop-types';

export const courseCatalogFeaturesShape = PropTypes.shape({
  searchbar: bool,
  titleOfCategory: bool,
  courseTypeFilter: bool,
});

export const configObjectShape = PropTypes.shape({
  getProperty: PropTypes.func,
  isModuleEnabled: PropTypes.func, 
  isMapActivated: PropTypes.bool,
  isFetching: PropTypes.bool,
  data: PropTypes.shape({}),
  error: PropTypes.any, 
});
