export default {
  'color-main-dark': '#ce8200',
  'color-main-dark-2': '#ce8200',
  'color-main-light': '#c7994a',
  'color-main': '#fccc1d',
  'color-header-top': '#fccc1d',
  'color-header-bottom': '#fff',
  'color-header-text': '#191919',
  'color-header-link-underline': '#ef5921',
  'color-link': '#df9000',
  'color-input-text': '#eee',
  'color-action': '#ce8200',
};