import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import LoadingIndicator from '../loading-indicator';

export const Loading = styled(LoadingIndicator)`
  margin-top: 260px;
`;

export const DropdownItemCheckbox = styled.input`
  margin-right: 8px;
`;

export const DropdownItemText = styled.div`
  flex: 1 1 auto;

  mark {
    font-weight: 600;
    background: none;
    color: inherit;
  }
`;
export const DropdownItemDescription = styled.div`
  flex: 0 1 auto;
  color: #9d9d9d;
`;

export const DropdownRow = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  
`;
export const DropdownFilters = styled.div`
  display: flex;
  flex: 1 0 0;
  padding: 10px 0;
  padding-right: 20px;
  flex-direction: column;
  border-right: 1px solid #efeeea;
`;
export const DropdownItems = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
`;
export const DropdownFilterCheckbox = styled.label`
  flex: 0 1 auto;
  margin: 0;
  padding: 4px 30px;
  white-space: nowrap;
  input {
    margin-right: 1em;
  }
`;

export const ItemRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 0.9em;
`;
export const ItemLabel = styled.div(
  css`
    position: relative;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    background-color: #618da7;
    color: #fff;
    height: 2em;
    padding: 0 8px;
    margin: 0 8px;
    margin-bottom: 16px;
    align-items: center;
  `,
  ({required}) => required && css`
    background-color: #aa4a4a;
  `,
);
export const ItemLabelIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5em;
`;
export const ItemPlus = styled.div`
  flex: 0 0 auto;
  color: #6a6868;
  padding: 2px 0;
  margin: 0 8px;
`;
export const ItemLabelButton = styled.div`
  flex: 0 0 auto;
  margin-left: 6px;
  font-size: 0.6em;
  height: 100%;
  margin-right: -8px;
  border-radius: 8px;
  width: 28px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.115s ease-out;
  font-size: 0.8em;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    line-height: 0;
  }
`;