import {put, takeLatest, select} from 'redux-saga/effects';
import axios from 'axios';
import {backendUrl} from '@config';
import {
  getSelectedParentPage,
  getFeaturedContent,
} from '@selectors/cms.selectors';
import * as cmsActions from '@actions/cms.actions';
import retry from '@utils/sagas.utils';

function* getChildren(action) {
  let id = null;

  if (action.payload) {
    id = action.payload.cmsId;
  }

  if (id === null) {
    id = yield select(getSelectedParentPage) || null;
  }

  yield put(cmsActions.cmsGetchildrenRequest());
  try {
    const children = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/cms/${id}/pages`,
          withCredentials: true,
        })
        .then(response => response.data));

    yield put(cmsActions.cmsGetchildrenSuccess({children}));
    if (action && action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(children.pages);
    }
  } catch (error) {
    console.error(error);
    yield put(cmsActions.cmsGetchildrenFailure({error}));
  }
}

function* getContent(action) {
  const {contentId} = action.payload;

  yield put(cmsActions.cmsgGetContentRequest());
  try {
    const content = yield retry(() =>
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/cms/${contentId}?fields=body,teaser,title,image,video,imageCaption,imageAltText`,
          withCredentials: true,
        })
        .then(response => response.data));

    yield put(cmsActions.cmsGetContentSuccess({
      content,
      contentId,
    }));
  } catch (error) {
    yield put(cmsActions.cmsGetContentFailure({error}));
  }
}

export default [
  takeLatest(cmsActions.CMS_GET_CONTENT, getContent),
  takeLatest(cmsActions.CMS_GET_CHILDREN, getChildren),
];
