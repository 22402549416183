import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {fontSize} from '@src/common/styles/device';
import {i18n} from '@src/i18n/index';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Progress from '@components/progress/progress';
import {faMinus, faPlus} from '@fortawesome/pro-solid-svg-icons';
import {useState} from 'react';
import {LoadingIndicator} from '@snapper/core';
import {colors} from '@src/colors';
import {AnimatePresence, motion} from 'framer-motion';

const verticalLine = css`
  content: '';
  position: absolute;
  top: 0;
  height: 18px;
  height: var(--height, 18px);
  box-sizing: border-box;
`;

const ChildrenContainer = styled(motion.ul)`
  display: flex;
  padding-inline-start: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
  ::before {
    ${verticalLine};
    left: 50%;
    width: 0;
    border-left: 1px solid #ccc;
  }
`;

const node = css`
  flex: auto;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 30px 0 0 10px;
`;

const nodeLines = css`
  ::before,
  ::after {
    ${verticalLine};
    right: 50%;
    width: 50%;
    border-top: 1px solid #ccc;
  }
  ::after {
    left: 50%;
    border-left: 1px solid #ccc;
  }
  :only-of-type {
    padding: 0;
    ::after,
    :before {
      display: none;
    }
  }
  :first-of-type {
    ::before {
      border: 0 none;
    }
    ::after {
      border-radius: 5px 0 0 0;
    }
  }
  :last-of-type {
    ::before {
      border-right: 1px solid #ccc;
      border-radius: 0 5px 0 0;
    }
    ::after {
      border: 0 none;
    }
  }
`;

const Company = styled.div`
  font-size: ${fontSize.s};
  max-width: 200px;
  margin: 0 auto;
  margin-top: 10px;
`;

const Content = styled.div`
  background-color: white;
  border: 1px solid #efefef;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: 400;
  min-width: 160px;
  padding: 15px;
  font-size: ${fontSize.ss};
`;

const Title = styled.div`
  font-size: ${fontSize.s};
  font-weight: 400;
  cursor: pointer;
  padding-bottom: 6px;
  :hover {
   text-decoration: underline;
  }
`;

const Section = styled.div`
  min-width: 100px;
  float: left;
  text-align: left;
`;

const Plus = styled.div`
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  border-radius: 50%;
  background-color: #256EF5;
  color: white;
  font-size: 0.6em;
  margin: 0 auto;
  margin-top: 5px;
  padding-left: 0px;
  cursor: pointer;

  :hover{
    background-color: #868E96;
  }
`;

const LoadingWrapper = styled.div`
  margin-top: 14px;
  flex: 1 1 auto;
`;

const TreeNode = ({
  children,
  data,
  className,
  fetchMore,
  openNode,
}) => {
  const [expanded, setExpanded] = useState(false);

  return data && (
    <li css={css(node, nodeLines, className)}>
      <Company>
        <Title
          onClick={() => openNode(data.organisation_id)}
        >
          {data.title}
        </Title>
        <Content>
          {data.haschildren && (
            <>
              <Section>
                {i18n('employees.nb-units')}
                :
              </Section>
              {' '}
              {data.children_count - 1}
              <br />
            </>
          )}
          {data.employees_in_this !== data.employees && (
            <>
              <Section>
                {i18n('employees.nb-employees')}
                :
              </Section>
              {' '}
              {data.employees_in_this}
              <br />
              <Section>
                {i18n('employees.total-employees')}
                :
              </Section>
              {' '}
              {data.employees}
              <br />
            </>
          ) || (
            <>
              <Section>
                {i18n('employees.nb-employees')}
                :
              </Section>
              {data.employees_in_this}
              <br />
            </>
          )}
          <div style={{marginTop:20}}>
            {data.progress && data.progress.length !== 0
            && (
              <Progress
                bigBar
                progress={data.progress[0].fulfilled}
              />
            )
             ||               (
               <Progress
                 bigBar
                 progress={0}
               />
             )}
          </div>
        </Content>
        {data.haschildren && (!expanded && (
          <Plus
            onClick={() => {
              fetchMore(data.organisation_id);
              setExpanded(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Plus>
        ) || (
          <Plus
            onClick={() => {
              setExpanded(false);
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </Plus>
        )) || null}
      </Company>
      <AnimatePresence>
        {data.haschildren && expanded && (
          <ChildrenContainer
            initial={{
              '--height': '0px',
              paddingTop: 0,
              height: 0,
            }}
            animate={{
              '--height': '18px',
              paddingTop: 18,
              height: data.children?.length ? 'auto' : 140,
            }}
            exit={{
              '--height': '0px',
              paddingTop: 0,
              height: 0,
            }}
            transition={{
              ease: 'easeInOut',
              duration: 0.8,
            }}
          >
            {data.children?.length && data.children.map(sub_child => (
              <TreeNode
                key={sub_child.organisation_id}
                openNode={openNode}
                data={sub_child}
                fetchMore={fetchMore}
              />
            ))
            || (
              <LoadingWrapper>
                <LoadingIndicator
                  color={colors.blue}
                />
              </LoadingWrapper>
            )}
          </ChildrenContainer>
        ) || null}
      </AnimatePresence>
    </li>
  ) || null;
};

export default TreeNode;
