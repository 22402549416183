import React, {useState, useEffect, useRef, useCallback} from 'react';
import axios, {CancelToken} from 'axios';
import moment from 'moment';
import {backendUrl} from '@config';
import {css} from '@emotion/react';
import classNames from 'classnames';
import ExtraChart from '@routes/camp-extra/extra-chart';
import PersonAvatar from '@components/person-avatar/person-avatar';

import {connect} from 'react-redux';
import {notificationsAdd} from '@actions/notifications.actions';
import {withRouter, Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';

import {InputSearchMultiple} from './input-search-multiple';

import BadgeCard from './components/badge-card/badge-card';

import './competence-report.scss';
import ProgressCard from './progress-card';
import ReportDatepicker from './report-datepicker';
import DropdownSelect from '../../common/components/dropdown-select/dropdown-select';
import LoadingIndicator from './loading-indicator';
import Modal from '../../common/components/modal/modal';
import {employees as routes} from '../routes.manifest';
import {
  getOrganisationId,
  getHelptexts,
  getProfileId,
} from '../../store/selectors/profile.selectors';
import {stringifyUrlParams} from '../../common/utils/requests.utils';
import DonutChart from '../../common/components/chart/chart';
import InfoBubble from './info-bubble';
import {
  OrgCardWrapper,
  OrgsBadges,
  SortRow,
  SortToggleButton,
  SortSelect,
  CourseCards,
  ClickableText,
  BrandschoolTable,
  ReportDescription,
  SavedReportsList,
  SavedReportsEntry,
  GarbagoButton,
  Row,
  ModalBackground,
  ModalTitle,
  Label,
  ReportTextInput,
  ReportTextInputLabel,
  CompletionChart,
  CompletionBar,
  CompletionDot,
  CompletionText,
  NarrowColumn,
  PersonsColumn,
  EmployeeRow,
  EmployeeAvatar,
  EmployeeRowCard,
  PersonsNameColumn,
  PersonsName,
  PersonsPosition,
  BrandschoolCompletion,
  PersonsCompletion,
  PersonsDetails,
  FlexRow,
  FlexColumn,
  LinkButton,
  Button,
  TopSectionBg,
  TopSection,
  ReportBody,
  ReportSection,
  ReportSectionNarrowInner,
  TopSectionToggleButton,
  InfoBoxRow,
  ReportHeader,
  ReportSubHeader,
  ReportSectionHeader,
  ChartWrapper,
  ChartStatsMain,
  ChartStatsSidebar,
  ChartStats,
  BrandschoolStatsCell,
  BrandschoolRow,
  ClickyBadge,
  BadgeRow,
  TopFilterRow,
} from './styles/report-styles';
import {ExpandableInput} from './expandable-input';

const Report = ({
  organisationId,
  helptexts,
  profileId,
  dispatch,
  match: {params},
  location: {state},
  history: {push},
}) => {
  const [hasError, setHasError] = useState(false);
  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [showLoadReportModal, setShowLoadReportModal] = useState(false);
  const [savedReports, setSavedReports] = useState({
    loading: false,
    data: [],
  });
  const [shouldFetchReport, setShouldFetchReport] = useState(false);
  const [topExpanded, setTopExpanded] = useState(false);
  const topSectionRef = useRef();

  const [searchedCompetenceGroup, setSearchedCompetenceGroup] = useState(null);
  const [selectedCompetenceGroup, setSelectedCompetenceGroup] = useState(null);
  const [searchedCourses, setSearchedCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [searchedOrgs, setSearchedOrgs] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [showTab, setTab] = useState('org');
  const [searchedDate, setSearchedDate] = useState(moment());
  const [date, setDate] = useState(moment());

  const [competenceGroups] = useState([
    {
      name: 'Onboarding',
      cids: [59454,59456,59458,59462,59614],
      id: 1,
    },
  ]);

  const [confirmDelete, setConfirmDelete] = useState(null);
  const [loading, setLoading] = useState(null);
  const [data, setData] = useState(null);
  const [reportTitle, setReportTitle] = useState('');
  const [saveReportLoading, setSaveReportLoading] = useState(false);

  const [expandedInputs, setExpandedInputs] = useState([false, false, false, false]);
  const anyInputsExpanded = expandedInputs.some(expanded => expanded);

  const [courseCardsHover, setCourseCardsHover] = useState(false);
  const [orgSort, setOrgSort] = useState({
    key: 'completion_grade',
    text: 'Gjennomføringsgrad',
    reverse: false,
  });
  const [personsort, setPersonsort] = useState({
    key: 'completion_grade',
    text: 'Gjennomføringsgrad',
    type: 'number',
    reverse: false,
  });

  const setExpandedInputAtIndex = index =>
    setExpandedInputs(list => {
      const [...expanded] = list;

      expanded[index] = true;

      return expanded;
    });

  const [expandedOrgs, setExpandedOrgs] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [authorId, setAuthorId] = useState(null);

  const [requestedOrgIds, setRequestedOrgIds] = useState(null);
  const [requestedCourseIds, setRequestedCourseIds] = useState(null);

  const getRequestParams = useCallback(() => {
    const distriktIds = [];
    const orgIds = [];
    const samlIds = [];
    const brandIds = [];

    selectedUnits.map(({type, id}) => {
      if (type === 'district') {
        // return distriktIds.push(id);
        return orgIds.push(id);
      }
      if (type === 'brand') {
        return brandIds.push(id);
      }
      if (type === 'samvirkelag') {
        return samlIds.push(id);
      }

      return orgIds.push(id);
    });
    const courseIds = [...selectedCompetenceGroup ? selectedCompetenceGroup.cids : [], ...selectedCourses.map(({id}) => id)];
    console.log('courseIds', courseIds)
    const requiredCourseIds = courseIds.filter(({required}) => required);

    let params = new URLSearchParams();
    if (distriktIds) params.append('distrikt_ids', distriktIds.join(','));
    if (brandIds.length) params.append('brand_ids', brandIds.join(','));
    if (samlIds.length) params.append('brand_ids', samlIds.join(','));
    if (courseIds.length) courseIds.forEach(cid => params.append('cids', cid));
    if (orgIds.length !== 0) orgIds.forEach(oid => params.append('oids', oid));

    params.append('week', (date ? date.isoWeek() : moment().isoWeek()));
    params.append('year', (date ? date.year() : moment().year()));
    params.append('return_persons', true);

    /*
    let params = {
      distrikt_ids: distriktIds.join(','),
      brand_ids: brandIds.length ? brandIds.join(',') : undefined,
      slag_ids: samlIds.join(','),
      cids: courseIds.length ? courseIds.join(',') : undefined,
      roles: selectedRoles.map(({id}) => id).join(','),
      week: date ? date.isoWeek() : moment().isoWeek(),
      year: date ? date.year() : moment().year(),
      brandschool:
        selectedCompetenceGroup && selectedCompetenceGroup.id === 1
          ? '1'
          : null,
      return_persons: true,
    }
        */




    return params;
  }, [selectedCourses, selectedUnits, selectedRoles, date, selectedCompetenceGroup]);

  const [init, setInit] = useState(false);

  const resetReport = () => {
    setInit(false);
    setTopExpanded(true);
    setShowDashboard(true);
    setLoading(false);
    setSelectedCompetenceGroup(null);
    setSelectedUnits([]);
    setSelectedCourses([]);
    setDate(moment());
    setData(null);
    setReportTitle('');
    setExpandedInputs([false, false, false, false]);
    setHasError(false);
  };

  const fetchDataToken = useRef(null);

  const fetchData = useCallback(() => {
    const params = getRequestParams();

    setData(null);
    setLoading(true);
    setHasError(null);

    const cancel = 'Operation canceled by the user.';

    if (fetchDataToken.current) {
      fetchDataToken.current.cancel(cancel);
    }

    fetchDataToken.current = CancelToken.source();

    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/apiv2/dashboard/search`,
        params,
        withCredentials: true,
        cancelToken: fetchDataToken.current.token,
      })
      .then(({data}) => {
        if (!data.week) {
          throw new Error('Unexpected response', data);
        } else {
          setData(data);
          setSearchedCompetenceGroup(selectedCompetenceGroup);
          setSearchedCourses([...selectedCourses]);
          setSearchedOrgs([...selectedUnits]);
          setSearchedDate(date);
          setLoading(false);
          setHasError(null);
        }
      })
      .catch(error => {
        if (error.message !== cancel) {
          console.error(error);
          setData(null);
          setSearchedCompetenceGroup(selectedCompetenceGroup);
          setSearchedCourses([...selectedCourses]);
          setSearchedOrgs([...selectedUnits]);
          setSearchedDate(date);
          setLoading(false);
          setHasError(error);
        }
      });
  }, [
    getRequestParams,
    setData,
    setSearchedCompetenceGroup,
    setSearchedCourses,
    selectedCompetenceGroup,
    selectedCourses,
    setSearchedOrgs,
    selectedUnits,
    setSearchedDate,
    date,
    setLoading,
  ]);

  let orgs = data
    ? data.organisations
      .sort((org1, org2) =>
        orgSort.reverse
          ? org1[orgSort.key] - org2[orgSort.key]
          : org2[orgSort.key] - org1[orgSort.key])
      .map(store => ({
        ...store,
        typeName: 'Butikk',
        type: 'store',
      }))
    : [];

  const showExpandOrgs = !expandedOrgs && orgs.length > 10;

  if (!expandedOrgs) {
    orgs = !expandedOrgs && orgs.slice(0, 10) || orgs;
  }

  const onScroll = useCallback(() => {
    if (topSectionRef.current) {
      const {style} = topSectionRef.current;

      style.maxHeight = `${topSectionRef.current.scrollHeight}px`;
    }
  }, []);

  const saveReport = () => {
    setSaveReportLoading(true);
    axios
      .request({
        method: 'POST',
        url: `${backendUrl}/search/save/dashboard`,
        params: {
          criteria: JSON.stringify({
            competenceGroup:
              selectedCompetenceGroup && selectedCompetenceGroup.cids || null,
            orgs: selectedUnits,
            competences: selectedCourses,
            date: expandedInputs[2] && date && date.toJSON() || null,
            name: reportTitle,
            authorId: profileId,
            version: 1,
          }),
          access_level: 'self',
          name: reportTitle,
        },
        withCredentials: true,
      })
      .then(() => {
        setSaveReportLoading(false);
        setShowSaveReportModal(false);
        dispatch(notificationsAdd({
          notification: {
            color: 'green',
            text: 'Rapporten er lagret',
          },
        }));
      })
      .catch(() => {
        setSaveReportLoading(false);
        dispatch(notificationsAdd({
          notification: {
            color: 'red',
            text: 'Det oppstod en feil ved lagring av rapporten',
          },
        }));
      });
  };

  const deleteSavedReport = reportId => {
    axios
      .request({
        method: 'POST',
        url: `${backendUrl}/search/deletesavedsearch/${reportId}`,
        withCredentials: true,
      })
      .catch(() => {
        dispatch(notificationsAdd({
          notification: {
            color: 'red',
            text: 'Det oppstod en feil ved sletting av rapporten',
          },
        }));
        setSavedReports(savedReports);
      });
    setSavedReports(({data, ...reports}) => ({
      ...reports,
      data: data.filter(({id}) => id !== reportId),
    }));
  };

  const loadReport = useCallback(({
    competenceGroup,
    orgs,
    competences,
    date,
    name,
    authorId,
  }) => {
    setSelectedCompetenceGroup(competenceGroup
        && competenceGroup.length
        && competenceGroups.find(({cids}) => JSON.stringify(cids) === JSON.stringify(competenceGroup))
        || null);
    setDate(date && moment(date));
    setReportTitle(name || '');
    setExpandedInputs([!!competences, !!orgs, !!date]);
    setSelectedUnits(orgs || []);
    setRequestedOrgIds(orgs && orgs.map(({id}) => id) || null);
    setSelectedCourses(competences || []);
    setRequestedCourseIds(competences && competences.map(({id}) => id) || null);
    setAuthorId(authorId);
    setShouldFetchReport(true);
  }, [competenceGroups]);

  const anyInputsEdited
    = JSON.stringify(searchedCompetenceGroup)
      !== JSON.stringify(selectedCompetenceGroup)
    || JSON.stringify(searchedCourses) !== JSON.stringify(selectedCourses)
    || JSON.stringify(searchedOrgs) !== JSON.stringify(selectedUnits)
    || JSON.stringify(searchedDate) !== JSON.stringify(date);

  const shouldAutoFetch
    = !anyInputsExpanded && showDashboard && anyInputsEdited;

  const isEmptyReport = !params.reportId || params.reportId === 'totalt';

  useEffect(() => {
    if (
      !init && isEmptyReport
      || init && shouldAutoFetch && params.reportId !== 'search'
    ) {
      setShouldFetchReport(true);
      setTopExpanded(true);
      setShowDashboard(true);
    }
  }, [params, getRequestParams, shouldAutoFetch, init, isEmptyReport]);

  onScroll();

  useEffect(() => {
    document
      .querySelector('.app-wrapper__content')
      .addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    window.addEventListener('orientationchange', onScroll);

    return () => {
      document
        .querySelector('.app-wrapper__content')
        .removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onScroll);
      window.removeEventListener('orientationchange', onScroll);
    };
  }, [onScroll]);

  const onOrgClick = org => () => {
    setExpandedInputAtIndex(1);
    setSelectedUnits([org]);
    setRequestedOrgIds([org.id]);
    setShouldFetchReport(true);
  };

  useEffect(() => {
    if (shouldFetchReport) {
      setShouldFetchReport(false);
      setInit(true);
      fetchData();
    }
  }, [fetchData, shouldFetchReport]);

  const fetchSavedReports = () => {
    setSavedReports(({...saved}) => ({
      ...saved,
      loading: true,
    }));
    axios
      .request({
        method: 'GET',
        url: `${backendUrl}/search/savedsearches/dashboard?json_out=1`,
        withCredentials: true,
      })
      .then(({data: {self}}) => {
        setSavedReports(() => ({
          loading: false,
          data: self,
        }));
      });
  };

  useEffect(() => {
    if (params.reportId === 'search') {
      setExpandedInputs([true, false, false, false]);
    } else if (params.reportId === 'totalt') {
      setSelectedCompetenceGroup(competenceGroups[0]);
    } else if (params.reportId) {
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/search/getsavedsearch/${params.reportId}`,
          withCredentials: true,
        })
        .then(({data}) => {
          loadReport(data);
        })
        .catch(() => {
          setData(null);
          setHasError('loadReport');
          dispatch(notificationsAdd({
            notification: {
              color: 'red',
              text: `Fant ikke rapport med ID ${params.reportId}`,
            },
          }));
        });
    } else if (state && state.roleId) {
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/organisationalfunctions`,
          params: {
            organisation_id: organisationId,
            fields: 'title,required_competences(competence_id)',
          },
          withCredentials: true,
        })
        .then(({data: {roles}}) => {
          const id_data = roles.find(r => r.id === Number.parseInt(state.roleId, 10));

          if (id_data) {
            resetReport();
            const cti = id_data.required_competences.map(({id}) => id);

            setSelectedCompetenceGroup(competenceGroups.find(({cids}) => JSON.stringify(cids) === JSON.stringify(cti)));
          }
        });
    }
  }, [competenceGroups, dispatch, loadReport, organisationId, params.reportId, state]);

  useEffect(() => {
    if (authorId && profileId && authorId === profileId) {
      setShowDashboard(true);
    }
  }, [authorId, profileId]);

  useEffect(() => {
    if (showLoadReportModal) {
      fetchSavedReports();
    }
  }, [showLoadReportModal]);

  useEffect(() => {
    if (selectedCourses.length) {
      setSelectedCompetenceGroup(null);
    }
  }, [selectedCourses]);

  useEffect(() => {
    if (selectedCompetenceGroup) {
      setRequestedCourseIds([]);
    }
  }, [selectedCompetenceGroup]);

  useEffect(() => {
    if (confirmDelete !== null) {
      const timeout = setTimeout(() => setConfirmDelete(null), 2000);

      return () => {
        clearTimeout(timeout);
      };
    }

    return undefined;
  }, [confirmDelete]);

  const courseCardsHoverRef = useCallback(
    current => {
      if (!current) {
        return;
      }

      const onMouseEnter = () => {
        setCourseCardsHover(true);
      };
      const onMouseLeave = () => {
        setCourseCardsHover(false);
      };

      current.addEventListener('mouseenter', onMouseEnter);
      current.addEventListener('mouseleave', onMouseLeave);
    },
    [setCourseCardsHover],
  );

  const coursesOnChange = useCallback(value => setSelectedCourses(value), []);
  const coursesGetResponseDataKey = useCallback(data => data, []);
  const coursesGetTypeNameKey = useCallback(({course_type: {name}}) => name, []);
  const coursesGetTypeIdKey = useCallback(({course_type: {id}}) => id, []);

  const unitsOnChange = useCallback(value => setSelectedUnits(value), []);
  const unitsGetResponseDataKey = useCallback(({units}) => units, []);
  const unitsGetTypeNameKey = useCallback(({organisation_type_name}) => organisation_type_name, []);
  const unitsGetTypeIdKey = useCallback(({organisation_type_id}) => organisation_type_id, []);

  const rolesOnChange = useCallback(value => setSelectedRoles(value), []);
  const rolesGetResponseDataKey = useCallback(data => data, []);

  return (
    <>
      <TopSectionBg
        ref={topSectionRef}
        collapsed={!topExpanded}
        loading={loading || requestedCourseIds || requestedOrgIds}
      >
        <TopSection>
          <ReportSectionNarrowInner collapsed={!topExpanded}>
            {!showDashboard
              && <ReportSectionHeader light>{reportTitle}</ReportSectionHeader>
            || (
              <>
                <ReportSectionHeader light>
                  {reportTitle || 'RAPPORT'}
                </ReportSectionHeader>

                <ReportDescription
                  hasDescription={
                    helptexts?.report?.toptext
                  }
                >
                  {helptexts?.report?.toptext}
                </ReportDescription>
                {params.reportId !== 'search' && (
                  <TopFilterRow className="filter-row expanded">
                    <ReportSectionHeader
                      light
                      css={css`margin-bottom: 0.5em;`}
                    >
                      Kompetansegrupper
                    </ReportSectionHeader>
                    <BadgeRow>
                      {competenceGroups.map(competencegroup => (
                        <ClickyBadge
                          onClick={() => {
                            if (selectedCompetenceGroup === competencegroup) {
                              setSelectedCompetenceGroup(null);
                            } else {
                              setSelectedCompetenceGroup(competencegroup);
                            }
                          }}
                          active={
                            selectedCompetenceGroup
                            && selectedCompetenceGroup.id === competencegroup.id
                          }
                        >
                          {competencegroup.name}
                        </ClickyBadge>
                      ))}
                    </BadgeRow>
                  </TopFilterRow>
                )}
                <ExpandableInput
                  title="Velg kompetanser"
                  actionTitle="velg kompetanser"
                  expanded={expandedInputs[0]}
                  onExpand={() => setExpandedInputAtIndex(0)}
                >
                  <InputSearchMultiple
                    ref={current => {
                      if (current && requestedCourseIds) {
                        current.setSelectedIds(requestedCourseIds);
                        setRequestedCourseIds(null);
                      }
                    }}
                    onChange={coursesOnChange}
                    getResponseDataKey={coursesGetResponseDataKey}
                    getTypeNameKey={coursesGetTypeNameKey}
                    getTypeIdKey={coursesGetTypeIdKey}
                    url={`${backendUrl}/apiv2/dashboard/courses?limit=10000`}
                    nameKey="title"
                    showItemId
                    showTypeNameInBadge
                    selectRequired
                    placeholder="Finn kompetanse"
                    closeOnSelect
                  />
                </ExpandableInput>
                <ExpandableInput
                  title="Enheter"
                  actionTitle="filtrer på enheter"
                  expanded={expandedInputs[1]}
                  onExpand={() => setExpandedInputAtIndex(1)}
                >
                  <InputSearchMultiple
                    ref={current => {
                      if (current && requestedOrgIds) {
                        current.setSelectedIds(requestedOrgIds);
                        setRequestedOrgIds(null);
                      }
                    }}
                    onChange={unitsOnChange}
                    getResponseDataKey={unitsGetResponseDataKey}
                    getTypeNameKey={unitsGetTypeNameKey}
                    getTypeIdKey={unitsGetTypeIdKey}
                    url={`${backendUrl}/apiv2/dashboard/units?limit=10000`}
                    nameKey="name"
                    placeholder="Enheter"
                    closeOnSelect
                  />
                </ExpandableInput>
                <ExpandableInput
                  title="Roller"
                  actionTitle="filtrer på roller"
                  expanded={expandedInputs[2]}
                  onExpand={() => setExpandedInputAtIndex(2)}
                >
                  <InputSearchMultiple
                    onChange={rolesOnChange}
                    getResponseDataKey={rolesGetResponseDataKey}
                    url={`${backendUrl}/apiv2/roles/`}
                    nameKey="name"
                    placeholder="Roller"
                    closeOnSelect
                  />
                </ExpandableInput>
                <ExpandableInput
                  title="Historiske tall"
                  actionTitle="velg uke"
                  expanded={expandedInputs[3]}
                  onExpand={() => setExpandedInputAtIndex(3)}
                >
                  <ReportDatepicker
                    value={date}
                    onChange={date => setDate(date)}
                  />
                </ExpandableInput>
                {anyInputsExpanded && (
                  <Button
                    disabled={loading || !anyInputsEdited}
                    onClick={() => setShouldFetchReport(true)}
                    css={css`
                    ${expandedInputs[3] && 'margin-top: -40px;'
                      || 'margin-top: 40px;'}
                  `}
                  >
                    {loading && (
                      <i
                        className="fa fa-spin fa-spinner"
                        css={css`margin-right: 1em;`}
                      />
                    )}
                    Lag rapport
                  </Button>
                )}
              </>
            )}
          </ReportSectionNarrowInner>
        </TopSection>
      </TopSectionBg>
      {(params.reportId !== 'search' || data || loading || hasError) && (
        <ReportBody>
          {showDashboard && (
            <TopSectionToggleButton
              onClick={() => setTopExpanded(toggle => !toggle)}
              onKeyPress={e =>
                e.key === 'Enter' && setTopExpanded(toggle => !toggle)}
              role="button"
              tabIndex="0"
            >
              <i
                className={classNames('fa', {
                  'fa-angle-double-up': topExpanded,
                  'fa-angle-double-down': !topExpanded,
                })}
              />
            </TopSectionToggleButton>
          )}
          {data
              && (
                <ReportSection>
                  {(!data.total_count && (
                    <FlexRow>
                      <FlexColumn>
                        <ReportHeader>Fant ingen data</ReportHeader>
                      </FlexColumn>
                    </FlexRow>
                  ) || (
                    <FlexRow>
                      <FlexColumn>
                        <ReportHeader>
                          Fant
                          {' '}
                          {data.total_count}
                          {' '}
                          person
                          {data.total_count !== 1 && 'er'}
                          {' '}
                          i
                          {' '}
                          {data.organisations.length}
                          {' '}
                          enhet
                          {data.organisations.length !== 1 && 'er'}
                        </ReportHeader>
                        {(searchedCompetenceGroup
                        || !!searchedCourses.length) && (
                          <ReportSubHeader>
                            som har
                            {' '}
                            {[
                              ...searchedCompetenceGroup
                                ? [searchedCompetenceGroup.name]
                                : [],
                              ...searchedCourses.map(({name}) => name),
                            ].map((name, index) => (
                              <>
                                {!!index && <>&nbsp;+&nbsp;</>}
                                <b key={index}>{name}</b>
                              </>
                            ))}
                            {' '}
                            som krav
                          </ReportSubHeader>
                        )}
                      </FlexColumn>
                      <FlexRow
                        shrink
                        css={css`margin-left: auto;`}
                      >
                        <DropdownSelect
                          css={css`margin-top: 20px; :hover, :focus, :active { background-color: #52788e !important; }`}
                          color="#fff"
                          backgroundColor="#618da7"
                          label={(
                            <i
                              className="fa fa-cog"
                              css={css`font-size: 1.2em; margin-left: -0.3em;`}
                            />
                        )}
                          options={[
                            {
                              index: 0,
                              text: (
                                <>
                                  <i className="far fa-file-excel" />
                                &nbsp; Last ned rapport for Person
                                </>
                              ),
                            },
                            {
                              index: 1,
                              text: (
                                <>
                                  <i className="far fa-file-excel" />
                                &nbsp; Last ned rapport for Organisasjon
                                </>
                              ),
                            },
                            {
                              index: 2,
                              text: (
                                <>
                                  <i className="far fa-save" />
                                &nbsp; Lagre rapport
                                </>
                              ),
                            },
                            {
                              index: 3,
                              text: (
                                <>
                                  <i className="far fa-folder-open" />
                                &nbsp; Lagrede rapporter
                                </>
                              ),
                            },
                          ]}
                          renderItem={(option, {text}, props) =>
                            <div {...props}>{text}</div>}
                          selected={() => false}
                          onSelect={({index}) => {
                            if (index === 0) {
                              window.location.href = `${backendUrl}/plugin/dashboard_report_excel/persons?${stringifyUrlParams(getRequestParams())}`;
                            } else if (index === 1) {
                              window.location.href = `${backendUrl}/plugin/dashboard_report_excel/organisations?${stringifyUrlParams(getRequestParams())}`;
                            } else if (index === 2) {
                              setShowSaveReportModal(true);
                            } else if (index === 3) {
                              setShowLoadReportModal(true);
                            }
                          }}
                          dropdownAlignRight
                        />
                      </FlexRow>
                    </FlexRow>
                  ))}
                </ReportSection>
              )}
          {data && data.summary && !!data.total_count && (
            <>
              <ReportBody css={css`background-color: #f7f6f4; min-height: auto; margin-bottom: 80px; z-index: 90;`}>
                <ReportSection>
                  {searchedCompetenceGroup
                    && searchedCompetenceGroup.id === 1 && (
                      <BrandschoolRow>
                        {data.summary
                          ? (
                            <BrandschoolStatsCell>
                              <ReportSectionHeader center>
                                Obligatorisk kompetanse
                                {helptexts?.report?.helptextBrandschool && (
                                  <InfoBubble
                                    title="Krav"
                                    width={400}
                                    height={300}
                                    alignRight
                                    anchor
                                    offsetX={100}
                                    offsetY={-10}
                                  >
                                    {helptexts.report.helptextBrandschool}
                                  </InfoBubble>
                                )}
                              </ReportSectionHeader>
                              <ChartWrapper>
                                <ExtraChart
                                  data={{
                                    labels: [
                                      'Har fullført alle krav',
                                      'Har ikke fullført alle krav',
                                    ],
                                    data: [
                                      data.summary.persons_completed,
                                      data.summary.persons_missing,
                                    ],
                                    // progress: `${Math.round(
                                    //   data.summary.completion
                                    // )}%`,
                                    colors: ['#4ab7ed', '#e84a39'],
                                  }}
                                  holeScale={0.76}
                                />
                              </ChartWrapper>
                              <InfoBoxRow>
                                <ProgressCard
                                  mainText={`${Math.round(data.summary.completion)}%`}
                                  subText="Har fullført alle krav"
                                />
                                <ProgressCard
                                  mainText={`${Math.round(data.summary.completion_grade)}%`}
                                  subText="Gjennomføringsgrad"
                                />
                              </InfoBoxRow>
                            </BrandschoolStatsCell>
                          )
                          : null}
                        {data.other
                          ? (
                            <BrandschoolStatsCell>
                              <ReportSectionHeader center>
                                Kjedeskole og annen obl. kompetanse
                              </ReportSectionHeader>
                              <ChartWrapper>
                                <ExtraChart
                                  data={{
                                    labels: [
                                      'Har fullført alle krav',
                                      'Har ikke fullført alle krav',
                                    ],
                                    data: [
                                      data.other.persons_completed,
                                      data.other.persons_missing,
                                    ],
                                    // progress: `${Math.round(
                                    //   data.other.completion
                                    // )}%`,
                                    colors: ['#4ab7ed', '#e84a39'],
                                  }}
                                  holeScale={0.76}
                                />
                              </ChartWrapper>
                              <InfoBoxRow>
                                <ProgressCard
                                  mainText={`${Math.round(data.other.completion)}%`}
                                  subText="Har fullført alle krav"
                                />
                                <ProgressCard
                                  mainText={`${Math.round(data.other.completion_grade)}%`}
                                  subText="Gjennomføringsgrad"
                                />
                              </InfoBoxRow>
                            </BrandschoolStatsCell>
                          )
                          : null}
                      </BrandschoolRow>
                  ) || (
                    <ChartStats>
                      <ChartStatsMain>
                        <ChartWrapper>
                          <ExtraChart
                            data={{
                              labels: [
                                'Har fullført alle krav',
                                'Har ikke fullført alle krav',
                              ],
                              data: [
                                data.summary.persons_completed,
                                data.summary.persons_missing,
                              ],
                              // progress: `${Math.round(
                              //   data.summary.completion
                              // )}%`,
                              colors: ['#4ab7ed', '#e84a39'],
                            }}
                            holeScale={0.76}
                          />
                        </ChartWrapper>
                      </ChartStatsMain>
                      <ChartStatsSidebar>
                        <ProgressCard
                          mainText={`${Math.round(data.summary.completion_grade)}%`}
                          subText="Gjennomføringsgrad"
                        />
                        <ProgressCard
                          ref={courseCardsHoverRef}
                          mainText={data.summary.persons_count}
                          subText={`Person${
                            data.summary.persons_count !== 1 ? 'er' : ''
                          } som har krav${
                            data.courses.length === 1 ? 'et' : 'ene'
                          }`}
                        />
                        <CourseCards>
                          {courseCardsHover
                              && data.courses.length > 1
                              && data.courses.map(({persons_count, name}, key) => (
                                <ProgressCard
                                  key={key}
                                  mainText={persons_count}
                                  subText={name}
                                  courseCard
                                />
                              ))}
                        </CourseCards>
                      </ChartStatsSidebar>
                    </ChartStats>
                  )}
                </ReportSection>
              </ReportBody>
              {searchedCompetenceGroup
                && (searchedCompetenceGroup.id === 1
                  && data.courses
                  && !!data.courses.length && (
                    <ReportSection>
                      <ReportSectionHeader css={css`margin-bottom: 1em;`}>
                        Kjedeskole krav
                      </ReportSectionHeader>
                      <BrandschoolTable>
                        <thead>
                          <tr>
                            <th width="35%">Krav</th>
                            <th width="25%">Gjennomføringsgrad</th>
                            <th width="20%">Personer som har kravet</th>
                            <th width="20%">Personer som har fullført</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.courses.map(({
                            completion,
                            persons_completed,
                            name,
                            persons_count,
                            id,
                          }) => (
                            <tr key={id}>
                              <td>{name}</td>
                              <td>
                                <BrandschoolCompletion>
                                  <CompletionChart stretch>
                                    <CompletionBar completion={completion}>
                                      <CompletionDot small>
                                        <CompletionText>
                                          {Math.round(completion)}
                                          %
                                        </CompletionText>
                                      </CompletionDot>
                                    </CompletionBar>
                                  </CompletionChart>
                                </BrandschoolCompletion>
                              </td>
                              <td>{persons_count}</td>
                              <td>{persons_completed}</td>
                            </tr>
                          ))}
                        </tbody>
                      </BrandschoolTable>
                    </ReportSection>
                )
                  || data.courses.length > 1 && (
                    <ReportSection>
                      <NarrowColumn>
                        {data.courses.map(({
                          completion,
                          name,
                          persons_completed,
                          persons_count,
                          persons_missing,
                          id,
                        }) => (
                          <FlexColumn
                            key={id}
                            css={css`margin-bottom: 50px;`}
                          >
                            <FlexRow css={css`color: #888988; margin-left: 130px; margin-bottom: 1em;`}>
                              <span>
                                <span css={css`font-weight: bold;`}>{name}</span>
                                  &nbsp;- totalt
                                {' '}
                                {persons_count}
                                {' '}
                                person
                                {persons_count.length !== 1 && 'er'}
                              </span>
                            </FlexRow>
                            <FlexRow>
                              <FlexColumn
                                shrink
                                css={css`justify-content: center; align-items: center; margin-right: 40px;`}
                              >
                                <DonutChart
                                  width={80}
                                  height={80}
                                  data={[
                                    {
                                      value: persons_completed,
                                      color: '#4ab7ed',
                                    },
                                    {
                                      value: persons_missing,
                                      color: '#e84a39',
                                    },
                                  ]}
                                  padding={0}
                                  holeScale={0.4}
                                />
                              </FlexColumn>
                              <FlexColumn>
                                <ProgressCard
                                  columns={[
                                    {
                                      mainText: `${Math.round(completion)}%`,
                                      subText: 'Gjennomføringsgrad',
                                    },
                                    {
                                      mainText: persons_completed,
                                      subText: 'Har fullført alle krav',
                                      small: true,
                                    },
                                    {
                                      mainText: persons_missing,
                                      subText: 'Har ikke fullført alle krav',
                                      small: true,
                                    },
                                  ]}
                                />
                              </FlexColumn>
                            </FlexRow>
                          </FlexColumn>
                        ))}
                      </NarrowColumn>
                    </ReportSection>
                  ))}
            </>
          )}
          {data && !!orgs?.length && showTab === 'org' && (
            <ReportSection>
              <Row>
                <ReportSectionHeader css={css`margin-bottom: 1em;`}>
                  <div style={{float:'left', borderBottom: '2px solid orange', marginRight: 40}}>
                    Enhets oversikt
                  </div>
                  <div
                    style={{float:'left', cursor: 'pointer'}}
                    role="button"
                    onClick={() => setTab('person')}
                  >
                   Person oversikt
                  </div>
                </ReportSectionHeader>
                <SortRow>
                  sorter på&nbsp;
                  <SortToggleButton
                    onClick={() =>
                      setOrgSort(sort => ({
                        ...sort,
                        reverse: !sort.reverse,
                      }))}
                  >
                    {orgSort.reverse
                      ? (
                        <>
                          stigende &nbsp;
                          <i className="fa fa-sort-amount-up" />
                        </>
                      )
                      : (
                        <>
                          synkende &nbsp;
                          <i className="fa fa-sort-amount-down" />
                        </>
                      )}
                  </SortToggleButton>
                  <SortSelect
                    label={orgSort.text.toLowerCase()}
                    options={[
                      {
                        key: 'completion',
                        text: 'Gjennomføringsgrad',
                      },
                      {
                        key: 'persons_count',
                        text: 'Antall ansatte',
                      },
                    ]}
                    renderItem={(option, {text}, props) =>
                      <div {...props}>{text}</div>}
                    selected={val => val && val.key === orgSort.key}
                    onSelect={({key, text}) =>
                      setOrgSort(sort => ({
                        ...sort,
                        key,
                        text,
                      }))}
                    dropdownAlignRight
                  />
                </SortRow>
              </Row>
              <OrgsBadges>
                {orgs.map(org => (
                  <OrgCardWrapper>
                    <BadgeCard
                      title={org.name}
                      onClick={onOrgClick({
                        type: 'store',
                        id: org.id,
                      })}
                      text={(
                        <>
                          <ClickableText
                            onClick={onOrgClick({
                              type: 'samvirkelag',
                              id: org.samvirkelag_id,
                            })}
                          >
                            {org.samvirkelag}
                          </ClickableText>
                          {' / '}
                          <ClickableText
                            onClick={onOrgClick({
                              type: 'brand',
                              id: org.brand_id,
                            })}
                          >
                            {org.brand_name}
                          </ClickableText>
                          <br />
                          <span css={css`font-style: italic;`}>
                            {org.persons_count}
                            {' '}
                            ansatt
                            {org.persons_count !== 1 && 'e'}
                          </span>
                        </>
                        )}
                      percentage={`${Math.round(org.completion_grade)}%`}
                    />
                  </OrgCardWrapper>
                ))}
              </OrgsBadges>
              {showExpandOrgs && (
                <div
                  className="expand-button"
                  css={css`margin-bottom: 80px;`}
                  role="button"
                  onClick={() => setExpandedOrgs(true)}
                  onKeyPress={() => setExpandedOrgs(true)}
                  tabIndex="0"
                  style={{marginTop: '-40px'}}
                >
                  &#8226;&#8226;&#8226;
                </div>
              )}
            </ReportSection>
          )}
          {data && !!data.persons.length && showTab === 'person' && (
            <ReportSection>
                <Row style={{marginBottom: 40}}>
                <ReportSectionHeader css={css`margin-bottom: 1em;`}>
                  <div
                    style={{float:'left', marginRight: 40}}
                    role="button"
                    onClick={() => setTab('org')}
                  >
                    Enhets oversikt
                  </div>
                  <div
                    style={{float:'left', cursor: 'pointer', borderBottom: '2px solid orange',}}
                    role="button"
                  >
                    Person oversikt
                  </div>
                </ReportSectionHeader>
                <SortRow css={css`margin-bottom: 20px;`}>
                  sorter på&nbsp;
                  <SortToggleButton
                    onClick={() =>
                      setPersonsort(sort => ({
                        ...sort,
                        reverse: !sort.reverse,
                      }))}
                  >
                    {personsort.type === 'string'
                      && (personsort.reverse && (
                        <>
                          Å til A &nbsp;
                          <i className="fa fa-sort-alpha-up" />
                        </>
                      ) || (
                        <>
                          A til Å &nbsp;
                          <i className="fa fa-sort-alpha-down" />
                        </>
                      ))
                      || personsort.reverse && (
                        <>
                          stigende &nbsp;
                          <i className="fa fa-sort-amount-up" />
                        </>
                      ) || (
                        <>
                          synkende &nbsp;
                          <i className="fa fa-sort-amount-down" />
                        </>
                    )}
                  </SortToggleButton>
                  <SortSelect
                    label={personsort.text.toLowerCase()}
                    options={[
                      {
                        key: 'completion_grade',
                        text: 'Gjennomføringsgrad',
                        type: 'number',
                      },
                      {
                        key: 'has',
                        text: 'Gjennomførte krav',
                        type: 'number',
                      },
                      {
                        key: 'firstname',
                        text: 'Fornavn',
                        type: 'string',
                      },
                      {
                        key: 'lastname',
                        text: 'Etternavn',
                        type: 'string',
                      },
                    ]}
                    renderItem={(option, {text}, props) =>
                      <div {...props}>{text}</div>}
                    selected={val => val && val.key === personsort.key}
                    onSelect={({key, text, type}) =>
                      setPersonsort(sort => ({
                        ...sort,
                        key,
                        text,
                        type,
                      }))}
                    dropdownAlignRight
                  />
                </SortRow>
                </Row>
              <PersonsColumn>
                {data.persons
                  .sort((employee1, employee2) =>
                    personsort.reverse
                      ? employee1[personsort.key]
                          - employee2[personsort.key]
                        || typeof employee2[personsort.key] === 'string'
                          && employee2[personsort.key].localeCompare(employee1[personsort.key])
                      : employee2[personsort.key]
                          - employee1[personsort.key]
                        || typeof employee1[personsort.key] === 'string'
                          && employee1[personsort.key].localeCompare(employee2[personsort.key]))
                  .map((
                    {
                      name,
                      firstname,
                      lastname,
                      completion_grade,
                      has,
                      should_have,
                      position,
                      profile_image_link,
                    },
                    key,
                  ) => (
                    <EmployeeRow key={key}>
                      <EmployeeAvatar>
                        <PersonAvatar
                          size="50px"
                          person={{
                            firstname,
                            lastname,
                          }}
                          imageUrl={profile_image_link}
                        />
                      </EmployeeAvatar>
                      <EmployeeRowCard>
                        <PersonsNameColumn>
                          <PersonsName>{name}</PersonsName>
                          <PersonsPosition>
                            {position || <>&nbsp;</>}
                          </PersonsPosition>
                        </PersonsNameColumn>
                        <PersonsCompletion>
                          <CompletionChart>
                            <CompletionBar completion={completion_grade}>
                              <CompletionDot>
                                <CompletionText>
                                  {Math.round(completion_grade)}
                                  %
                                </CompletionText>
                              </CompletionDot>
                            </CompletionBar>
                          </CompletionChart>
                        </PersonsCompletion>
                        <PersonsDetails>
                          {has}
                          {' '}
                          av
                          {should_have}
                          {' '}
                          krav
                        </PersonsDetails>
                      </EmployeeRowCard>
                    </EmployeeRow>
                  ))}
              </PersonsColumn>
            </ReportSection>
          )}
          {loading && (
            <ReportSection>
              <LoadingIndicator />
            </ReportSection>
          )
            || hasError && (
              <ReportSection>
                <FlexColumn css={css`text-align: center; margin: auto; justify-content: center;`}>
                  <ReportHeader css={css`margin: 0 auto;`}>
                    <i className="fas fa-exclamation-triangle" />
                    <div css={css`font-size: 0.9rem; margin-bottom: 20px;`}>
                      Kunne ikke laste inn rapport
                    </div>
                  </ReportHeader>
                  {hasError === 'loadReport' && (
                    <LinkButton
                      css={css`margin: 0 auto;`}
                      onClick={() => {
                        push(routes.reportNew);
                      }}
                    >
                      Ny rapport
                    </LinkButton>
                  ) || (
                    <LinkButton
                      css={css`margin: 0 auto;`}
                      onClick={() => setShouldFetchReport(true)}
                    >
                      Prøv igjen
                    </LinkButton>
                  )}
                </FlexColumn>
              </ReportSection>
            )}
        </ReportBody>
      )}
      {showSaveReportModal && (
        <Modal onBackdropClick={() => setShowSaveReportModal(false)}>
          <ModalBackground>
            <ModalTitle>Lagre rapport</ModalTitle>
            <p>
              {helptexts && helptexts.report && helptexts.report.saveReport}
            </p>
            <Label>
              <ReportTextInputLabel>Tittel</ReportTextInputLabel>
              <ReportTextInput
                type="text"
                autoComplete="off"
                value={reportTitle}
                onChange={e => setReportTitle(e.target.value)}
                onKeyUp={e =>
                  e.key === 'Enter'
                  && reportTitle.length
                  && !saveReportLoading
                  && saveReport()}
              />
            </Label>
            <Row>
              <Button
                secondary
                css={css`margin-left: auto;`}
                onClick={() => setShowSaveReportModal(false)}
              >
                Avbryt
              </Button>
              <Button
                css={css`margin-left: 12px;`}
                disabled={!reportTitle.length || saveReportLoading}
                onClick={() => saveReport()}
              >
                {saveReportLoading && (
                  <i
                    className="fa fa-spin fa-spinner"
                    css={css`margin-right: 1em;`}
                  />
                )}
                Lagre
              </Button>
            </Row>
          </ModalBackground>
        </Modal>
      )}
      {showLoadReportModal && (
        <Modal onBackdropClick={() => setShowLoadReportModal(false)}>
          <ModalBackground>
            <ModalTitle>Lagrede rapporter</ModalTitle>
            <p>
              {helptexts && helptexts.report && helptexts.report.loadReport}
            </p>
            <SavedReportsList css={savedReports.loading && css`display: flex;`}>
              {savedReports.loading && <LoadingIndicator />
                || savedReports.data.map(({name, id}) => (
                  <SavedReportsEntry
                    as={Link}
                    to={{pathname: getRouteWithParams(routes.report, {reportId: id})}}
                    from="*"
                    onClick={() => setShowLoadReportModal(false)}
                    key={id}
                  >
                    <span>{name}</span>
                    <GarbagoButton
                      confirm={confirmDelete === id}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();

                        if (confirmDelete === id) {
                          deleteSavedReport(id);
                        } else {
                          setConfirmDelete(id);
                        }
                      }}
                    >
                      <i className="fa fa-trash-alt" />
                      {confirmDelete === id && <>&nbsp;Slett?</>}
                    </GarbagoButton>
                  </SavedReportsEntry>
                ))}
            </SavedReportsList>
            <Row>
              <Button
                secondary
                css={css`margin-left: auto;`}
                onClick={() => setShowLoadReportModal(false)}
              >
                Avbryt
              </Button>
            </Row>
          </ModalBackground>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  organisationId: getOrganisationId(state),
  helptexts: getHelptexts(state),
  profileId: getProfileId(state),
});

export default connect(mapStateToProps)(withRouter(Report));
