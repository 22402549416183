import React from 'react';
import styled from '@emotion/styled';

import {motion} from 'framer-motion';
import {css, jsx} from '@emotion/react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

export const Wrapper = styled(motion.div)`
  display: inline-block;
  margin-right: 0.2em;
  margin-bottom: auto;
  position: absolute;
`;
const AnimatedDropdownArrow = ({
  state,
  theme = 'dark',
  hideIcon,
  ...props
}) => (
  <Wrapper
    {...props}
    initial="collapsed"
    animate={state}
    variants={{
      expanded: {
        rotate: 0,
        color: theme === 'dark' ? '#797979' : 'white',
      },
      collapsed: {
        rotate: 90,
        color: theme === 'dark' ? '#C1C1C1' : 'white',
      },
    }}
  >
    <FontAwesomeIcon
      icon={['far', 'chevron-right']}
      css={css`
        visibility: ${hideIcon ? 'hidden' : 'visible'};
      `}
    />
  </Wrapper>
);

AnimatedDropdownArrow.propTypes = {
  state: PropTypes.oneOf(['collapsed', 'expanded']).isRequired,
  hideIcon: PropTypes.bool,
};

AnimatedDropdownArrow.defaultProps = {hideIcon: false};

export default AnimatedDropdownArrow;
