
import React from 'react';
import {lastDateNextYear} from '@utils/misc.utils';

import {Field} from 'redux-form';
import FormDatePicker from '@components/form-datepicker';
import FormInput from '@components/form-input';

import {maskDate, validateDate} from '../util';



export const ProjectFormElements = () => (
  <>
    <Field
      component={FormInput}
      autoFocus
      label="Navn"
      name="designation"
      isRequired
    />

    <Field
      component={FormInput}
      label="Beskrivelse"
      name="name"
    />

    <Field
      component={FormDatePicker}
      name="startdate"
      label="Fra"
      type="text"
      format={maskDate}
      validate={validateDate}
    />

    <Field
      component={FormDatePicker}
      name="enddate"
      label="Til (blank hvis du ikke er ferdig)"
      type="text"
      format={maskDate}
      validate={validateDate}
    />

  </>
); 

export const LanguageFormElements = () => (
  <>
    <Field
      component={FormInput}
      label="Språk"
      name="name"
    />
    <Field
      component={FormInput}
      label="Ferdighet i språket"
      name="designation"
    />
  </>
); 


export const WorkExperiencesFormElements = () => (
  <>
    <Field
      component={FormInput}
      label="Stilling"
      name="designation"
      isRequired
    />
    <Field
      component={FormInput}
      label="Selskap"
      name="name"
    />
    <Field
      component={FormDatePicker}
      label="Fra"
      name="startdate"
      maxDate={lastDateNextYear()}
    />
    <Field
      component={FormDatePicker}
      maxDate={lastDateNextYear()}
      label="Til (tom hvis du ikke har sluttet)"
      name="enddate"
    />
  </>
);

export const HonoraryOrVolunteerElements = () => (
  <>
    <Field
      component={FormInput}
      label="Stilling / rolle"
      name="designation"
      isRequired
    />
    <Field
      component={FormInput}
      label="Selskap / Bedrift"
      name="name"
    />
    <Field
      component={FormDatePicker}
      label="Fra"
      name="startdate"
      maxDate={lastDateNextYear()}
    />
    <Field
      component={FormDatePicker}
      maxDate={lastDateNextYear()}
      label="Til (tom hvis du ikke er ferdig / har sluttet)"
      name="enddate"
    />
  </>
);

export const EducationFormElements = () => (
  <>
    <Field
      component={FormInput}
      label="Sted"
      name="designation"
    />
    <Field
      component={FormInput}
      label="Navn på utdanning"
      name="name"
    />
    <Field
      component={FormInput}
      type="number"
      min="1900"
      max="2099"
      label="Start (år)"
      step="1"
      name="startdate"
    />
    <Field
      component={FormInput}
      type="number"
      lmin="1900"
      max="2099"
      label="Slutt (år)"
      step="1"
      name="enddate"
    />
  </>
);