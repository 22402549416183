
import {css, jsx} from '@emotion/react';

import styled from '@emotion/styled';

export const Description = styled.p`
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.05rem;
    `;
  
export const MissingDescription = styled(Description)`
      font-style: italic;
    `;
export const MissingCVTxt = styled.p`
          font-size: 1.5em;
        `;
  
export const AddCVDataTxt = styled.p`
          font-size: 14px;
        `;
export const EditBtnExperience = styled.button`
    padding: 8px 12px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #1573e6;
    color: #1473e6;

    &:hover{
      color: #fff;
      background-color: #1573e6;
    }
`;
      
export const EditIcon = styled.span`
        margin-right: 10px;
      `;
      
export const NoDataDesc = styled.p`
        font-style: italic;
        margin-top: 7px;
      `;

export const Details = styled.p`
margin-top: 0;
margin-bottom: 0;
color: #494949;

${props =>
  props.last
    ? css`
        margin-bottom: 10px;
      `
    : ''}
`;

export const LanguageName = styled.p`
display: inline-block;
font-weight: bold;
margin-bottom: 25px;
margin-top: 0;
`;

export const LanguageDesc = styled.p`
display: inline-block;
margin-bottom: 0;
margin-top: 0;
`;

export const SubCont = styled.div`
display:flex;
margin-bottom: 10px;
min-height: 72px;
align-items: center;
`;

export const Header = styled.h3`
margin-top: 0;
margin-bottom: 0;
font-weight: 500;
font-size: 1em;
`;
export const LanguageCont = styled.div``;

export const LeftPart = styled.div``;
export const RightPart = styled.div`
  margin-left: auto;
`;

export const CvSectionTitle = styled.h2`
color: #0B5CD7;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;

      ${({first}) => !first && css`
      	margin-top: 3em;
       `};
`;