const prefix = 'map';

export const SET_MAP_COURSES_DATA = `${prefix} SET MAP COURSES DATA`;

export const setMapCoursesData = payload => ({
  type: SET_MAP_COURSES_DATA,
  payload,
});

export const FETCH_MAP_COURSES = `${prefix} FETCH MAP COURSES`;
export const FETCH_MAP_COURSES_REQUEST = `${prefix} FETCH MAP COURSES REQUEST`;
export const FETCH_MAP_COURSES_SUCCEEDED = `${prefix} FETCH MAP COURSES REQUEST SUCCEEDED`;
export const FETCH_MAP_COURSES_FAILED = `${prefix} FETCH MAP COURSES REQUEST FAILED`;

export const fetchMapCourses = payload => ({
  type: FETCH_MAP_COURSES,
  payload,
});

export const fetchMapCoursesRequest = () => ({type: FETCH_MAP_COURSES_REQUEST});

export const fetchMapCoursesSucceeded = payload => ({
  type: FETCH_MAP_COURSES_SUCCEEDED,
  payload,
});

export const fetchMapCoursesFailed = payload => ({
  type: FETCH_MAP_COURSES_FAILED,
  payload,
});


export const FETCH_MAP_VERIFICATION_COMPETENCE = `${prefix} FETCH MAP VERIFICATION_COMPETENCE`;
export const FETCH_MAP_VERIFICATION_COMPETENCE_REQUEST = `${prefix} FETCH MAP VERIFICATION_COMPETENCE REQUEST`;
export const FETCH_MAP_VERIFICATION_COMPETENCE_SUCCEEDED = `${prefix} FETCH MAP VERIFICATION_COMPETENCE REQUEST SUCCEEDED`;
export const FETCH_MAP_VERIFICATION_COMPETENCE_FAILED = `${prefix} FETCH MAP VERIFICATION_COMPETENCE REQUEST FAILED`;

export const fetchMapVerificationCompetence = payload => ({
  type: FETCH_MAP_VERIFICATION_COMPETENCE,
  payload,
});

export const fetchMapVerificationCompetenceRequest = () => ({type: FETCH_MAP_VERIFICATION_COMPETENCE_REQUEST});

export const fetchMapVerificationCompetenceSucceeded = payload => ({
  type: FETCH_MAP_VERIFICATION_COMPETENCE_SUCCEEDED,
  payload,
});

export const fetchMapVerificationCompetenceFailed = payload => ({
  type: FETCH_MAP_VERIFICATION_COMPETENCE_FAILED,
  payload,
});

export const SELECT_MAP_COURSE = `${prefix} SELECT MAP COURSE`;

export const selectMapCourse = payload => ({
  type: SELECT_MAP_COURSE,
  payload,
});

export const SET_MAP_IS_VERIFIED = `${prefix} SET MAP IS VERIFIED`;

export const setMapIsVerified = payload => ({
  type: SET_MAP_IS_VERIFIED,
  payload,
});

export const SET_MAP_IS_NOT_VERIFIED = `${prefix} SET MAP IS NOT VERIFIED`;

export const setMapIsNotVerified = payload => ({
  type: SET_MAP_IS_NOT_VERIFIED,
  payload,
});

export const SET_OUTRO_IS_COMPLETED = `${prefix} SET OUTRO IS COMPLETED`;

export const setOutroIsCompleted = payload => ({
  type: SET_OUTRO_IS_COMPLETED,
  payload,
});

export const SET_OUTRO_IS_NOT_COMPLETED = `${prefix} SET OUTRO IS NOT COMPLETED`;

export const setOutroIsNotCompleted = payload => ({
  type: SET_OUTRO_IS_NOT_COMPLETED,
  payload,
});