import React from 'react';
import PropTypes from 'prop-types';
import AspectRatio from '@components/common/aspect-ratio';
import ContentLoader from 'react-content-loader';
import {css, jsx} from '@emotion/react';
import {highlightOnKeyboardFocusMixin} from '@styles/mixins';

export const AutoStartCourseWrapper = ({children, ...props}) => (
  <div
    role="button"
    tabIndex="0"
    onKeyDown={evt => {
      if (evt.key === 'Enter' && props.onClick) {
        props.onClick();
      }
    }}
    css={css`
      &:hover {
        cursor: pointer;
      }
      ${highlightOnKeyboardFocusMixin};
    `}
    {...props}
  >
    {children}
  </div>
);

export const CourseCardLoader = ({
  customCssImagePart,
  customCssTextPart,
  customCssWrapper,
  customCssImagePartContent,
  ascpectRatioImagePart,
}) => (
  <div
    className="course-card course-card-loading"
    css={css`
      ${customCssWrapper}
    `}
  >
    <div
      className="course-card__cover"
      css={css`
        ${customCssImagePart}
      `}
    >
      <AspectRatio
        ratio={ascpectRatioImagePart}
        style={customCssImagePartContent}
      >
        <div
          className="course-card__cover--image"
          style={{backgroundColor: '#f3f3f3'}}
        />
      </AspectRatio>
    </div>
    <div
      className="course-card__content"
      css={css`
        ${customCssTextPart}
      `}
    >
      <ContentLoader
        height="150"
        width="250"
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect
          x="4"
          y="20"
          rx="4"
          ry="4"
          width="225"
          height="25"
        />
        <rect
          x="4"
          y="65"
          rx="4"
          ry="4"
          width="180"
          height="18"
        />
      </ContentLoader>
    </div>
  </div>
);

CourseCardLoader.propTypes = {
  customCssImagePart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  customCssWrapper: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  customCssTextPart: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  ascpectRatioImagePart: PropTypes.number,
  customCssImagePartContent: PropTypes.shape({}),
};

CourseCardLoader.defaultProps = {
  customCssTextPart: '',
  customCssImagePart: '',
  customCssWrapper: '',
  ascpectRatioImagePart: 1.6,
  customCssImagePartContent: {},
};
